import React from 'react';
import { List, ListSubheader, ListItem, ListItemText } from '@mui/material';
import { withStyles } from '@mui/styles';
import moment from 'moment';
import config from 'common/oldJavascripts/config';

const styles = theme => ({
  text: {
    flexBasis: '33%',
  },
  subheader: {
    fontSize: theme.typography.pxToRem(14),
    color: 'black',
  },
});

class OfferFullHistoryItem extends React.Component {
  constructor(props) {
    super(props);
    this.process = this.process.bind(this);
  }

  process() {
    const { history } = this.props;
    let items = [];

    if (
      ['draft', 'draft_review'].indexOf(history.status) === -1 &&
      (history?.sent_at || history?.sentAt)
    ) {
      items.push({
        datetime: history?.sent_at || history?.sentAt,
        label: 'Sent to employee',
      });
    }

    if (history.approved_at) {
      items.push({
        datetime: history?.approved_at || history?.approvedAt,
        label: 'Approved',
      });
    }

    if (history.rejected_at || history.rejectedAt) {
      let rejectStatus =
        history.status === 'rejected' ? 'Rejected' : 'Employee Rejected';
      rejectStatus +=
        ' : ' + history?.rejection_comments || history?.rejectionComments;
      items.push({
        datetime: history?.rejected_at || history?.rejectedAt,
        label: rejectStatus,
      });
    }

    if (history?.rescinded_or_archived_at || history?.rescindedOrArchivedAt) {
      const comment =
        history?.rescindedOrArchivedComments ||
        history?.rescinded_or_archived_comments;
      const rescinded_or_archived_status =
        history?.status === 'archived' ? 'Archived' : 'Rescinded';
      items.push({
        datetime:
          history?.rescinded_or_archived_at || history?.rescindedOrArchivedAt,
        label: rescinded_or_archived_status + ': ' + comment,
      });
    }
    return items;
  }

  render() {
    const { classes, history } = this.props;
    const items = this.process(history);

    return (
      <List data-test-id={`OfferHistory-list-${history.id}-${history.version}`}>
        <ListSubheader className={classes.subheader}>
          Offer Version: {history.version}
        </ListSubheader>
        {items.map((item, index) => {
          return (
            <ListItem
              key={`offerHistory-item-${history.id}-${history.version}-${index}`}
            >
              <ListItemText
                secondary={`${moment(
                  item.datetime,
                  config.DATE_FORMAT.DB,
                ).format('MM/DD/YYYY')} - ${item.label}`}
              />
            </ListItem>
          );
        })}
      </List>
    );
  }
}

export default withStyles(styles)(OfferFullHistoryItem);

import React from 'react';

// HoC
import Feature from 'common/components/Feature';
import withRouteHelpers from 'common/hoc/withApi';
import { compose } from 'redux';

// HELPERS
import FetchMoreProjects from './helpers/FetchMoreProjects';
import countryFlag from 'common/utilities/renderCountryFlag.js';
import T from 'common/oldJavascripts/utils/i18n';

// LAYOUT
import Table from 'common/oldJavascripts/components/Base/Table';
import LayoutContent from 'common/oldJavascripts/components/Base/Layout/LayoutContent';
import Box from 'common/oldJavascripts/components/Base/Box';
import Header from 'common/oldJavascripts/components/Base/Header';
import Blankslate from 'common/oldJavascripts/components/Base/Blankslate';
import Loader from 'common/oldJavascripts/components/Base/Loader';
import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';
import Search from 'common/oldJavascripts/components/Shared/Search';
import BaseLayout from 'common/oldJavascripts/components/Pages/Layouts/Base';

// STYLES
import { withStyles } from '@mui/styles';

// HOOKS
import useFeatureFlags from 'common/hooks/useFeatureFlags';
import useProjects from 'studio/hooks/useProjects';
import useMe from 'common/hooks/useMe';

const Row = Table.Row;
const Cell = Table.Cell;

const styles = () => ({
  countryFont: {
    fontSize: '12px',
    fontWeight: '700',
    verticalAlign: 'super',
    marginLeft: '5px',
  },
  countryFlagImageSize: {
    width: '24px',
    height: '24px',
    marginLeft: '10px',
  },
  payrollRegionTableHeader: {
    width: '134px !important',
    color: '#979ca5',
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  recordsText: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.16px',
    color: '#8D8D8D',
  },
});

const PRIVILEGES_TO_MAP = [
  {
    privilege: 'can_create_offers',
    route: id => `/projects/${id}/offers`,
  },
  {
    privilege: 'can_review_offers',
    route: id => `/projects/${id}/reviews`,
  },
  {
    privilege: 'can_review_offers',
    route: id => `/projects/${id}/reviews`,
  },
  {
    privilege: 'can_approve_offers',
    route: id => `/projects/${id}/approvals`,
  },
  {
    privilege: 'can_i9_verify_offers',
    route: id => `/projects/${id}/i9-verifications`,
  },
  {
    privilege: 'can_be_i9_verifier',
    route: id => `/projects/${id}/i9-verifications`,
  },
  {
    privilege: 'can_view_documents',
    route: id => `/projects/${id}/offer-documents`,
  },
  {
    privilege: 'can_access_archives',
    route: id => `/projects/${id}/archives`,
  },
];

const ProjectsList = props => {
  const {
    classes,
    routerQuery: { q: searchTerm, sort, direction },
  } = props;

  const { loading: isMeLoading, data: meData = {} } = useMe();
  const {
    data: projects = {},
    loading: projectsLoading,
    fetchMore: fetchMoreQuery,
    networkStatus,
  } =
    useProjects({
      keyword: searchTerm || '',
      after: null,
      sort: sort,
      direction: direction,
    }) || {};

  const { isAdmin, isSupportAdmin } = meData;
  const {
    nodes: projectsList = [],
    pageInfo: projectsPageInfo = {},
  } = projects;

  // CHECKS
  // networkstatus 3 = refetch query was called
  const isLoading = (projectsLoading && networkStatus !== 3) || isMeLoading;
  const hasProjects = projectsList.length > 0;
  const hasNextPage = projectsPageInfo.hasNextPage;

  const renderLoading = () => (
    <Row>
      <Cell noBorder={true} />
      <Cell noBorder={true}>
        <Loader />
      </Cell>
      <Cell noBorder={true} />
    </Row>
  );

  const renderProjectRow = (project = {}) => {
    const { privileges, canContinue: projectIsAccessible } = project || {};
    // Render the correct country flag depending on country code
    const renderCountryFlag = countryFlag(project?.country?.code);
    // Multiple users get access to the project list
    // Depending on their role and permissions they go to different locations when they click
    let to;
    if (projectIsAccessible === false) {
      // This project is for 1.0 and so the user should go to the change app screen
      to = `/projects/${project.id}/change-app`;
    } else if (isAdmin) {
      // This user is an admin and will go to the project setup
      to = `/projects/${project.id}/imported-settings`;
    } else if (isSupportAdmin) {
      // This user probably doesnt exist because we dont use support admin
      // But they go to manage crew members
      to = `/projects/${project.id}/crew`;
    } else if (privileges) {
      // This user is a studio admin so we figure out where they should go
      // based of the privileges in the projects query and the Privileges to map array
      const firstMatchedPrivilege = PRIVILEGES_TO_MAP.find(({ privilege }) =>
        privileges.includes(privilege),
      );
      to = firstMatchedPrivilege?.route(project.id);
    } else {
      // If a user arrived here then there is a problem with this users permission configurations
      // Or they have been revoked access
      to = '/projects';
    }
    return (
      <Row
        to={to}
        params={{ projectId: project.id }}
        key={project.id}
        data-test-id={`Projects-row-${project.id}`}
      >
        <Feature name="CanadianProjectSupport">
          <Cell doubleBorderBottom={true}>
            <img
              src={renderCountryFlag}
              alt="region flag"
              className={classes.countryFlagImageSize}
            />
            <span
              data-test-id={`Projects-region-${project.id}`}
              className={classes.countryFont}
            >
              {renderCountryFlag.includes('us-flag') ? 'USA' : 'CAN'}
            </span>
          </Cell>
        </Feature>
        <Cell rowHeader={true} doubleBorderBottom={true}>
          <span data-test-id={`Projects-name-${project.id}`}>
            {project.name}
          </span>
        </Cell>
        <Cell doubleBorderBottom={true}>
          <span data-test-id={`Projects-productionCompany-${project.id}`}>
            {project.productionCompany.name}
          </span>
        </Cell>
        <Cell doubleBorderBottom={true}>
          <span data-test-id={`Projects-producer-${project.id}`}>
            {project.productionCompany.producer.name}
          </span>
        </Cell>
      </Row>
    );
  };

  const flags = useFeatureFlags();
  const globalNavFlag = flags.includes('GlobalNavbar');

  return (
    <BaseLayout nonEmployee={true}>
      <LayoutContent globalNavFlag={globalNavFlag}>
        <Box>
          <BoxItem>
            <Header>
              <Header.Title>
                <span data-test-id="Projects-title">{T('projects.title')}</span>
              </Header.Title>

              <Header.Nav>
                <Search />
              </Header.Nav>
            </Header>
          </BoxItem>
          <Table>
            <Table.Header>
              <Feature name="CanadianProjectSupport">
                <Cell
                  className={classes.payrollRegionTableHeader}
                  header={true}
                  sort="region"
                >
                  {T('Payroll Region')}
                </Cell>
              </Feature>
              <Cell header={true} className={classes.cellTextStyle} sort="name">
                {T('projects.columns.name')}
              </Cell>
              <Cell
                header={true}
                className={classes.cellTextStyle}
                sort="production_company"
              >
                {T('projects.columns.production_company')}
              </Cell>
              <Cell
                header={true}
                className={classes.cellTextStyle}
                sort="producer"
              >
                {T('projects.columns.producer')}
              </Cell>
            </Table.Header>
            {isLoading && renderLoading()}
            {!isLoading && hasProjects && projectsList?.map(renderProjectRow)}
            {!isLoading && !hasProjects && (
              <Blankslate>{T('projects.not_found')}</Blankslate>
            )}
          </Table>
        </Box>
        {!isLoading && (
          <span className={classes.recordsText}>
            showing {projectsList.length || 0} of {projectsPageInfo?.totalCount}{' '}
            projects
          </span>
        )}

        {hasNextPage && !isLoading && (
          <FetchMoreProjects
            networkStatus={networkStatus}
            loadMoreProjects={fetchMoreQuery}
            searchQuery={searchTerm}
            projectsPageInfo={projectsPageInfo}
          />
        )}
      </LayoutContent>
    </BaseLayout>
  );
};

export default compose(withStyles(styles), withRouteHelpers)(ProjectsList);

import React, { useState, useCallback } from 'react';
import Box from 'common/oldJavascripts/components/Base/Box';
import Header from 'common/oldJavascripts/components/Base/Header';
import Blankslate from 'common/oldJavascripts/components/Base/Blankslate';
import T from 'common/oldJavascripts/utils/i18n';

import OffersTableHeader from './OffersTableHeader';
import OffersTableRow from './OffersTableRow';
import OfferPacketsTable from 'studio/oldJavascripts/components/Shared/OfferPacketsTable';
import RateScaleRow from 'common/oldJavascripts/components/Shared/RateScaleRow';
import AllowancesRow from 'common/oldJavascripts/components/Shared/AllowancesRow';
import OfferFullHistory from '../Offers/OfferFullHistory';

import Loader from 'common/components/Loader';
import QueryRefresher from 'common/components/QueryRefresher';
import { compose } from 'redux';
import { withStyles } from '@mui/styles';

import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';
import ExpandableTable from 'common/oldJavascripts/components/Base/ExpandableTable';
import ExpandableTableRow from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRow';
import ExpandableTableRowInfo from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRowInfo';
import ExpandableTableCell from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableCell';
import OfferActionMenu from 'studio/components/OfferActionMenu';
import useArchivedOffers from './hooks/useArchivedOffers';
import FetchMoreArchivedOffers from './FetchMoreArchivedOffer/FetchMoreArchivedOffers';
import withApi from 'common/hoc/withApi';

const styles = () => ({
  table: {
    width: '100%',
    tableLayout: 'fixed',
  },
  loader: {
    backgroundColor: 'white',
    width: '100%',
    height: '200px',
  },
});

const ArchivedOffersTable = props => {
  const { classes = {}, projectId, routerQuery, projectQuery } = props;
  const archiveOffersVariables = {
    id: projectId,
    keyword: routerQuery?.q,
    after: null,
    sort: routerQuery?.sort || '',

    direction: routerQuery?.direction || '',
  };

  const {
    data: {
      projectArchives: { nodes: projectArchives = [] } = {},
      projectArchives: { pageInfo: archiveOffersPageInfo } = {},
      privileges = [],
    } = {},
    loading: isLoading,
    refetch: reloadQuery,
    fetchMore,
    networkStatus,
  } = useArchivedOffers(archiveOffersVariables);

  const archivesLoading = isLoading && networkStatus !== 3;
  const [expandedOffersDetails, setExpandedOffersDetails] = useState([]);
  const [expandedOffersStatus, setExpandedOffersStatus] = useState([]);
  const [expandedWorkflow, setExpandedWorkflow] = useState({});
  const [contextMenuAnchor, setContextMenuAnchor] = useState(null);
  const [contextualMenuOffer, setContextualMenuOffer] = useState(null);

  const handleDocumentSelect = (offerId, documentId) => {
    if (documentId) {
      setExpandedWorkflow({ [offerId]: documentId });
    } else {
      setExpandedWorkflow({});
    }
  };

  const _toggleOfferDetails = useCallback(
    id => {
      setExpandedOffersDetails(prevDetails => {
        const index = prevDetails.indexOf(id);
        if (index !== -1) {
          return prevDetails.filter(offerId => offerId !== id);
        } else {
          return [...prevDetails, id];
        }
      });
    },
    [setExpandedOffersDetails],
  );

  const _toggleOfferStatus = useCallback(
    id => {
      setExpandedOffersStatus(prevStatus => {
        const index = prevStatus.indexOf(id);
        if (index !== -1) {
          return prevStatus.filter(offerId => offerId !== id);
        } else {
          return [...prevStatus, id];
        }
      });
    },
    [setExpandedOffersStatus],
  );

  const openActionMenu = (ev, offer) => {
    setContextMenuAnchor(ev.currentTarget);
    setContextualMenuOffer(offer);
  };

  const closeActionMenu = () => {
    setContextMenuAnchor(null);
  };

  const { data: { allow_end_date: endDateAllowed } = {} } = projectQuery || {};
  const isDetailsExpanded = id => expandedOffersDetails.includes(id);
  const isStatusExpanded = id => expandedOffersStatus.includes(id);
  const hasOffers = projectArchives.length > 0;
  const renderOffersRows = offer => (
    <React.Fragment key={offer.id}>
      <OffersTableRow
        key={offer.id}
        endDateAllowed={endDateAllowed}
        offer={offer}
        params={{ offer_id: offer.id }}
        detailsExpanded={isDetailsExpanded(offer.id)}
        statusExpanded={isStatusExpanded(offer.id)}
        onToggleDetails={_toggleOfferDetails}
        onToggleStatus={_toggleOfferStatus}
        openActionMenu={openActionMenu}
        privileges={privileges}
      />
      {isDetailsExpanded(offer.id) && (
        <RateScaleRow
          key={`rate-${offer.id}`}
          rowExpanded={true}
          offer={offer}
          colSpan={9}
        />
      )}
      {isDetailsExpanded(offer.id) && (
        <AllowancesRow
          key={`allowance-${offer.id}`}
          rowExpanded={true}
          colSpan={9}
          offer={offer}
        />
      )}
      {isDetailsExpanded(offer.id) && (
        <ExpandableTableRow
          key={`packet-documents-${offer.id}`}
          rowExpanded={true}
        >
          <ExpandableTableCell colSpan={9} expanded={true}>
            <ExpandableTableRowInfo>
              <OfferPacketsTable
                hidePermanent={true}
                showHeader={false}
                rowExpanded={true}
                colSpan={9}
                width="100%"
                params={{
                  scope: `/v2/offers/${offer.id}`,
                  offerId: offer.id,
                  hasPackets: offer.has_packets,
                }}
                expandedWorkflow={expandedWorkflow}
                actionClickHandler={handleDocumentSelect}
                offerStatus={offer.status}
              />
            </ExpandableTableRowInfo>
          </ExpandableTableCell>
        </ExpandableTableRow>
      )}
      {isStatusExpanded(offer.id) && (
        <ExpandableTableCell colSpan={9} expanded>
          <Header>
            <Header.Title secondary>Offer Full History</Header.Title>
          </Header>
          <OfferFullHistory offer={offer} />
        </ExpandableTableCell>
      )}
    </React.Fragment>
  );

  const renderContent = () => (
    <React.Fragment>
      {!hasOffers && <Blankslate>No Offers</Blankslate>}
      {hasOffers && (
        <ExpandableTable className={classes.table}>
          <OffersTableHeader
            privileges={privileges}
            endDateAllowed={endDateAllowed}
          />
          {projectArchives.map(renderOffersRows)}
        </ExpandableTable>
      )}
    </React.Fragment>
  );

  return (
    <div data-test-id="OffersTable-root">
      <Box>
        <BoxItem>
          <Header>
            <Header.Title>{T('archives.title')}</Header.Title>
            <QueryRefresher reloadQuery={reloadQuery} />
          </Header>
        </BoxItem>
        {archivesLoading && <Loader className={classes.loader} />}
        {!archivesLoading && renderContent()}
      </Box>
      <OfferActionMenu
        projectId={projectId}
        offer={contextualMenuOffer}
        anchorEl={contextMenuAnchor}
        closeMenu={closeActionMenu}
        privileges={privileges}
      />
      {archiveOffersPageInfo?.hasNextPage && !archivesLoading && (
        <FetchMoreArchivedOffers
          loadMoreOffers={fetchMore}
          archiveOffersPageInfo={archiveOffersPageInfo}
          searchQuery={''}
          networkStatus={networkStatus}
        />
      )}
    </div>
  );
};

export default compose(withApi, withStyles(styles))(ArchivedOffersTable);

import React from 'react';
import Field from 'common/oldJavascripts/components/Base/Field';

import { withStyles } from '@mui/styles';
import classNames from 'classnames';
import SpecifiedCountryOnly from 'common/components/SpecifiedCountryOnly';
import withApi from 'common/hoc/withApi';
import { compose } from 'redux';
import useFeatureFlags from 'common/hooks/useFeatureFlags';
import useProjectCountry from 'common/components/SpecifiedCountryOnly/useProjectCountry';

const styles = theme => ({
  nested: {
    marginLeft: '20px',
  },
});

const PRIVILEGES = {
  can_create_offers: {
    label: 'Can create offers (Hirer)',
    subPrivileges: {
      can_hirer_view_all: 'View all employees and offers',
    },
  },
  can_be_reviewer:
    'Can review offers before they are sent to employees (Reviewer)',
  can_be_i9_verifier: 'Can verify I-9 documents (I-9 Verifier)',
  can_approve_offers:
    'Can approve completed offers submitted by employees (Approver)',
  can_view_offer_account_codes: {
    label: 'Can view offer account code fields',
    subPrivileges: {
      can_edit_offer_account_codes: 'Can edit offer account code fields',
    },
  },
  can_access_archives: 'Access to archives',
  can_view_documents: 'Can view documents',
};

const PrivilegesPicker = ({
  projectId,
  privileges = {},
  updatePrivileges,
  classes,
}) => {
  const flags = useFeatureFlags();
  const { data: countryData } = useProjectCountry({ projectId });
  const isCANProject = countryData?.code === 'CA';
  if (isCANProject) {
    PRIVILEGES.can_create_offers.subPrivileges.can_hirer_view_sin_or_ssn =
      'Can view SIN/SSN';
  } else {
    // remove the privilege if it exists
    delete PRIVILEGES.can_create_offers.subPrivileges.can_hirer_view_sin_or_ssn;
  }

  const featureFlagOn = flags.includes('SendAdditionalDocuments');
  const removeDocumentFlagOn = flags.includes('RemoveDocument');
  if (featureFlagOn && removeDocumentFlagOn) {
    PRIVILEGES.can_create_offers.subPrivileges.can_hirer_send_documents =
      'Can send/remove documents to offers';
  } else if (featureFlagOn) {
    PRIVILEGES.can_create_offers.subPrivileges.can_hirer_send_documents =
      'Can send documents to offers';
  }

  const _renderPrivilegesMap = (
    mapItems,
    privileges,
    nested = false,
    options = [],
  ) => {
    Object.keys(mapItems).forEach(key => {
      const label =
        typeof mapItems[key] === 'string' ? mapItems[key] : mapItems[key].label;
      options.push(_renderPrivilege(label, key, privileges, nested));
      if (privileges[key] && typeof mapItems[key] !== 'string') {
        _renderPrivilegesMap(
          mapItems[key].subPrivileges,
          privileges,
          true,
          options,
        );
      }
    });
    return options;
  };

  const _renderPrivilege = (
    label,
    privilegeKey,
    privileges,
    nested = false,
  ) => {
    const fieldClassName = classNames({
      [classes.nested]: nested,
    });
    const countryCode = privilegeKey === 'can_be_i9_verifier' ? 'US' : null;
    return (
      <SpecifiedCountryOnly countryCode={countryCode} key={privilegeKey}>
        <span data-test-id={`PrivilegesPicker-checkbox-${privilegeKey}`}>
          <Field
            label={label || '<< No Label >>'}
            type="checkbox"
            name={privilegeKey}
            id={privilegeKey}
            value={privileges[privilegeKey]}
            className={fieldClassName}
            onChange={_updatePrivilege(privilegeKey)}
          />
        </span>
      </SpecifiedCountryOnly>
    );
  };

  const _findChildPrivileges = (mapItems, privilegeKey, keys = []) => {
    Object.keys(mapItems).forEach(key => {
      if (key === privilegeKey || !privilegeKey) {
        if (!privilegeKey) {
          keys.push(key);
        }
        if (typeof mapItems[key] !== 'string') {
          _findChildPrivileges(mapItems[key].subPrivileges, null, keys);
        }
      } else if (mapItems[key].subPrivileges) {
        _findChildPrivileges(mapItems[key].subPrivileges, privilegeKey, keys);
      }
    });
    return keys;
  };

  const _updatePrivilege = privilegeKey => {
    return value => {
      updatePrivileges(privilegeKey, value);
      if (!value) {
        _findChildPrivileges(PRIVILEGES, privilegeKey).forEach(key => {
          updatePrivileges(key, value);
        });
      }
    };
  };

  return <div>{_renderPrivilegesMap(PRIVILEGES, privileges)}</div>;
};

export default compose(withApi, withStyles(styles))(PrivilegesPicker);

import withApi from 'common/hoc/withApi';
import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { withStyles } from '@mui/styles';
import { styled } from '@mui/system';
import { Typography } from '@mui/material';
import DesktopDocumentSigner from './DesktopDocumentSigner';
import MobileDocumentSigner from './MobileDocumentSigner';
import Loader from 'common/components/Loader';
import FailureAlert from 'common/oldJavascripts/components/Shared/FailureAlert';
import ajax from 'common/utilities/ajax.js';
import useIsMobile from 'common/hooks/useIsMobile';
import history from 'common/constants/config/history';
import useFeatureFlags from 'common/hooks/useFeatureFlags';

const styles = {
  loader: {
    gridArea: 'content',
  },
};

const StickyFooter = styled('div')({
  position: 'fixed',
  bottom: 0,
  height: 54,
  background: '#fff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  zIndex: 9,
  borderTop: '1px solid rgba(209, 209, 209, 1)',
  marginTop: 10,
  fontSize: '1.2rem',
  '& b': {
    fontWeight: 700,
  },
});

const DocumentSignerContainer = props => {
  const {
    classes = {},
    documents: documentsQuery = {},
    handleOfferAccept: upstreamHandleOfferAccept,
    handleOfferReject,
    handleSave,
    me,
    offer: { project_id: projectId } = {},
    offer = {},
    params: { offerId: paramsOfferId } = {},
    profile = {},
    routerParams: { offerId: routerOfferId } = {},
  } = props;

  const flags = useFeatureFlags();
  const globalNavFlag = flags.includes('GlobalNavbar');

  const [imagesFailed, setImagesFailed] = useState(false);
  const [templatesAcknowledgeTitle, setTemplatesAcknowledgeTitle] = useState(
    '',
  );
  const [templatesAcknowledgeNotes, setTemplatesAcknowledgeNotes] = useState(
    '',
  );
  const isMobile = useIsMobile();

  useEffect(() => {
    if (!projectId) return;
    ajax
      .get(`/projects/${projectId}`)
      .then(({ templates_acknowledge_notes, templates_acknowledge_title }) => {
        setTemplatesAcknowledgeNotes(templates_acknowledge_notes);
        setTemplatesAcknowledgeTitle(templates_acknowledge_title);
      });
  }, [projectId]);

  const handleOfferAccept = docs => {
    const { data = {} } = documentsQuery;
    const { items: documents = [] } = data;
    const newDocs = {};
    docs
      .filter(doc => doc.ready && !doc.signed)
      .forEach(doc => (newDocs[doc.id] = doc));
    return upstreamHandleOfferAccept(newDocs, routerOfferId, documents);
  };

  const getDocumentImageUrls = ({ documentId, page }) => {
    // Crew Member: offerId exists in routerParams
    // Approver, I9 Verifier: offerId exists in params
    const offerId = routerOfferId || paramsOfferId;

    const ajaxQuery = page ? `?page=${page}` : '';
    const ajaxUrl = `/v2/offers/${offerId}/documents/${documentId}/images${ajaxQuery}`;
    return ajax
      .get(ajaxUrl)
      .then(({ items }) => {
        return items.map(({ url }) => url);
      })
      .catch(throwFatalError);
  };

  const throwFatalError = () => setImagesFailed(true);

  if (
    documentsQuery.status === 'loading' ||
    profile.status === 'loading' ||
    me.status === 'loading'
  ) {
    return <Loader className={classes.loader} />;
  }

  if (imagesFailed || documentsQuery.status === 'failed')
    return <FailureAlert queryName="the Offer Document Details" />;

  const { items: documentData = [] } = documentsQuery.data;
  const { first_name, last_name, middle_name } = profile.data;

  if (documentData.length === 0) return <div />;

  const documentNeedsI9Correction = offer?.badges?.includes(
    'i9_correction_requested',
  );

  // We have to update the document data because of discrepencies
  // between what the documentation outlined and what the backend sends
  const updatedDocs = rejectI9 => {
    const docs = documentData.map(doc => ({
      ...doc,
      // source: images[doc.id],
      fieldMappings: doc.fields,
      name: doc.templateName,
      rank: 1,
    }));
    if (rejectI9) {
      return docs.filter(doc => doc?.start_plus_status === 'not_signed');
    } else {
      return docs;
    }
  };

  const name = [first_name, middle_name, last_name].filter(s => s).join(' ');
  const initials = [first_name, middle_name, last_name]
    .filter(s => s)
    .map(s => String(s)[0])
    .join('');
  const actor = { name, initials };

  const getLastRejectNoteFromI9Dcos = () => {
    // If the offer has a badge of i9_correction_requested, then we need to
    // check if the last reject note is from I9DCOs
    const i9Docs = documentData.filter(doc => doc.i9_document === true);
    const lastRejectNote = i9Docs
      .map(doc => doc.reject_notes)
      .filter(note => note)
      .pop();
    return lastRejectNote;
  };

  const alertMessage = getLastRejectNoteFromI9Dcos();

  const handleReject = () => {
    if (documentNeedsI9Correction) history.push(`/onboarding/offers`);
    return handleOfferReject();
  };

  const actions = {
    submit: {
      text: 'Accept Offer',
      action: handleOfferAccept,
    },
    cancel: {
      text: 'Reject Offer',
      action: handleReject,
    },
  };
  if (handleSave) {
    actions.save = {
      text: 'Save Offer',
      action: handleSave,
    };
  }

  if (documentNeedsI9Correction) {
    actions.cancel.text = 'Cancel';
  }

  return isMobile ? (
    <MobileDocumentSigner
      documents={documentNeedsI9Correction ? updatedDocs(true) : updatedDocs()}
      actor={actor}
      getDocumentImageUrls={getDocumentImageUrls}
      throwFatalError={throwFatalError}
      actions={actions}
      templatesAcknowledgeNotes={templatesAcknowledgeNotes}
      templatesAcknowledgeTitle={templatesAcknowledgeTitle}
      me={me}
      alertMessage={documentNeedsI9Correction ? alertMessage : ''}
    />
  ) : (
    <>
      <DesktopDocumentSigner
        documents={
          documentNeedsI9Correction ? updatedDocs(true) : updatedDocs()
        }
        actor={actor}
        getDocumentImageUrls={getDocumentImageUrls}
        throwFatalError={throwFatalError}
        actions={actions}
        offer={offer}
        templatesAcknowledgeNotes={templatesAcknowledgeNotes}
        templatesAcknowledgeTitle={templatesAcknowledgeTitle}
        me={me}
        alertMessage={documentNeedsI9Correction ? alertMessage : ''}
      />
      {globalNavFlag && (
        <StickyFooter>
          <Typography variant="p">
            <b>This page requests personal information.</b> If you have
            questions, please review the{' '}
            <a
              href="https://www.castandcrew.com/privacy-policy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              CA Notice at Collection.
            </a>
          </Typography>
        </StickyFooter>
      )}
    </>
  );
};

DocumentSignerContainer.queries = {
  documents: {
    info: function(params, related) {
      const { offerId: routerOfferId } = related['/router/params'];
      const { offerId: paramsOfferId } = params;
      const offerId = routerOfferId || paramsOfferId;
      return {
        id: `/v2/offers/${offerId}/documents-details?reload`,
        expires: true,
      };
    },
  },
  profile: {
    info: function() {
      return {
        id: `/account/profile`,
      };
    },
  },
  me: {
    info() {
      return { id: '/me' };
    },
  },
};

export default compose(withApi, withStyles(styles))(DocumentSignerContainer);

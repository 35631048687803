import React from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { createOfferCurrency as currency } from 'common/oldJavascripts/utils/format';

const unionEmployeeInfoStyles = makeStyles(theme => ({
  title: {
    color: '#999999',
    fontSize: '12px !important',
    fontWeight: '400 !important',
  },
  sectionTitle: {
    color: '#646464',
    fontSize: 16,
    paddingTop: 10,
    lineHeight: '16px',
  },
  name: {
    fontSize: 24,
    fontWeight: 400,
    color: '#646464',
  },
  date: {
    fontSize: 14,
    color: '#646464',
    fontWeight: 400,
  },
  email: {
    fontSize: 10,
    color: '#0000FF',
    fontWeight: 400,
    marginTop: '3px',
    letterSpacing: '0.3px',
  },
  spacing: {
    marginTop: '20px',
    marginBottom: '10px',
  },
  amount: {
    color: '#0000FF',
    fontSize: '24px !important',
    fontWeight: '400 !important',
    marginRight: '10px !important',
    wordWrap: 'break',
  },
  description: {
    wordBreak: 'break-word',
  },
  flag: {
    display: 'block',
    margin: 'auto',
    width: 24,
    height: 24,
  },
  caption: {
    color: '#aaaaaa',
    fontSize: 10,
    fontWeight: 400,
    padding: 0,
    textAlign: 'center',
  },
  scaleCaption: {
    fontSize: 10,
    color: '#AAAAAA',
    position: 'relative',
    top: -14,
  },
  topSpacing: {
    marginTop: 20,
  },
  scaleCopy: {
    color: '#8D8D8D',
    fontSize: 10,
  },
  divider: {
    position: 'absolute',
    bottom: 162,
    width: '10%',
    marginLeft: 41,
  },
}));

const UnionDailyOnCallTerms = ({ offer }) => {
  const classes = unionEmployeeInfoStyles();
  const { termsOfEmployment = {} } = offer;

  const {
    locationRatePerDay: dailyRateDistant,
    studioRatePerDay: dailyRate,
    studioGuaranteedHours: guaranteedHours,
    locationGuaranteedHours: guaranteedHoursDistant,
    payIdleDaysDistant,
    payAtScale,
    payAtScaleDistant,
    idleAtScaleDistant,
    payIdleDaysDistantAt12th,
    payIdleDaysDistantAtScale,
  } = termsOfEmployment;

  const pay10th12thScaleValue = payIdleDaysDistantAtScale
    ? 'Scale Rate'
    : 'Negotiated Rate';
  const payAt10thOr12th = payIdleDaysDistantAt12th
    ? `1/12th of ${pay10th12thScaleValue}`
    : `1/10th of ${pay10th12thScaleValue}`;

  return (
    <>
      <Typography
        className={[classes.topSpacing, classes.sectionTitle].join(' ')}
        data-test-id={`UnionDailyOnCallTermsstudioTitle-${offer?.id}`}
      >
        Studio
      </Typography>
      <Grid container className={classes.spacing}>
        <Grid item xs={4}>
          <Typography
            variant="h6"
            className={classes.title}
            data-test-id={`UnionDailyOnCallTerms-hourlyRateTitle-${offer?.id}`}
          >
            Daily Rate
          </Typography>
          <Typography
            className={classes.amount}
            data-test-id={`UnionDailyOnCallTerms-hourlyRate-${offer?.id}`}
          >
            {currency(dailyRate)} <br />{' '}
            {payAtScale && (
              <span className={classes.scaleCaption}>*Scale Rate</span>
            )}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography
            variant="h6"
            className={classes.title}
            data-test-id={`UnionDailyOnCallTerms-hoursPerDayTitle-${offer?.id}`}
          >
            Pension Hours Per Day
          </Typography>
          <Typography
            className={classes.amount}
            data-test-id={`UnionDailyOnCallTerms-hoursPerDay-${offer?.id}`}
          >
            {guaranteedHours}
          </Typography>
        </Grid>
      </Grid>
      <Typography
        className={[classes.topSpacing, classes.sectionTitle].join(' ')}
        data-test-id={`UnionDailyOnCallTerms-DistantTitle-${offer?.id}`}
      >
        Distant
      </Typography>
      <Grid container className={classes.spacing}>
        <Grid item xs={4}>
          <Typography
            variant="h6"
            className={classes.title}
            data-test-id={`UnionDailyOnCallTerms-hourlyRateDistantTitle-${offer?.id}`}
          >
            Daily Rate
          </Typography>
          <Typography
            className={classes.amount}
            data-test-id={`UnionDailyOnCallTerms-hourlyRateDistant-${offer?.id}`}
          >
            {currency(dailyRateDistant)} <br />{' '}
            {payAtScaleDistant && (
              <span className={classes.scaleCaption}>*Scale Rate</span>
            )}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography
            variant="h6"
            className={classes.title}
            data-test-id={`UnionDailyOnCallTerms-hourlyPerDayDistantTitle-${offer?.id}`}
          >
            Pension Hours Per Day
          </Typography>
          <Typography
            className={classes.amount}
            data-test-id={`UnionDailyOnCallTerms-guaranteedHoursDistant-${offer?.id}`}
          >
            {guaranteedHoursDistant}
          </Typography>
        </Grid>
        {payIdleDaysDistant && (
          <Grid item xs={4}>
            <Typography
              variant="h6"
              className={classes.title}
              data-test-id={`UnionDailyOnCallTerms-payIdleDaysDistantTitle-${offer?.id}`}
            >
              Idle Days Pay
            </Typography>
            <Typography
              className={classes.amount}
              data-test-id={`UnionDailyOnCallTerms-idleDaysDistant-${offer?.id}`}
            >
              {payAt10thOr12th}
              <br />{' '}
              {idleAtScaleDistant && (
                <span className={classes.scaleCaption}>*Scale Rate</span>
              )}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Typography className={classes.scaleCopy}>
        *Scale rate shown is as of offer creation date. Scale rate is subject to
        change upon union negotiated increases.
      </Typography>
    </>
  );
};
export default UnionDailyOnCallTerms;

import React, { Fragment } from 'react';
import TOOLTIP_ICON from 'common/images/tooltip.svg';
import Tooltip from '@mui/material/Tooltip';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import UsaOnly from 'common/components/UsaOnly';
import { createOfferCurrency as currency } from 'common/oldJavascripts/utils/format';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';

const useStyles = makeStyles(theme => ({
  terms: {
    marginTop: '40px',
  },
  title: {
    color: '#646464',
    fontSize: '24px',
    fontWeight: 400,
    marginBottom: 20,
  },
  amount: {
    color: '#0000FF !important',
    fontSize: '30px !important',
    fontWeight: '400 !important',
    padding: '18px !important',
    textAlign: 'center !important',
    border: '0.1px solid #d3f0ee',
    [theme.breakpoints.up('md')]: {
      padding: 15,
    },
  },
  grey: {
    color: '#939393',
  },
  scaleTip: {
    fontSize: 10,
    color: '#939393',
  },
  tooltip: {
    marginLeft: '5px',
  },
  mobileTermsRateHeader: {
    display: 'grid',
    gridTemplateColumns: '30% 70%',
    gridTemplateRows: '30% 70%',
    gridAutoFlow: 'row',
  },
  greenBox: {
    backgroundColor: '#E2F1FF',
    fontSize: 18,
    fontWeight: 400,
    paddingLeft: 10,
    paddingTop: 10,
    paddingBottom: 10,
  },
  regularBox: {
    backgroundColor: '#E2F1FF',
    color: '#5b5b5b !important',
    fontSize: '14px !important',
    fontWeight: '700 !important',
    textAlign: 'center !important',
    padding: '15px !important',
    border: 'none',
  },
  distant: {
    color: '#5b5b5b',
    fontSize: '16 !important',
    fontWeight: '700 !important',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  distantContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
}));

const formatAmount = amount => {
  return !Number.isInteger(amount) ? currency(amount) : `$${amount}`;
};

const UnionDailyEmployeeTermsMobile = ({ terms = {} }) => {
  const classes = useStyles();
  const {
    guaranteed_hours_studio: guaranteedHours,
    guaranteed_hours_location: guaranteedHoursDistant,
    pay_at_scale: payAtScale,
    pay_at_scale_distant: payAtScaleDistant,
    rate_per_day_studio: dailyRate,
    rate_per_day_location: dailyRateDistant,
    pay_idle_days_distant_at_12th: payIdleDaysDistantAt12th,
    pay_idle_days_distant_at_scale: payIdleDaysDistantAtScale,
  } = terms;

  const isStudio = dailyRate || guaranteedHours;
  const isDistant = dailyRateDistant || guaranteedHoursDistant;

  const pay10th12thScaleValue = payIdleDaysDistantAtScale
    ? 'Scale Rate'
    : 'Negotiated Rate';
  const payAt10thOr12th = payIdleDaysDistantAt12th
    ? `1/12th of ${pay10th12thScaleValue}`
    : `1/10th of ${pay10th12thScaleValue}`;

  return (
    <div className={classes.terms}>
      <Typography
        className={classes.title}
        data-test-id="UnionDaillyOnCallMobile-title"
      >
        Terms of Employment
      </Typography>
      {isStudio && (
        <Fragment>
          <div>
            <div className={classes.greenBox}>
              <Typography
                className={classes.distant}
                data-test-id="UnionDaillyOnCallMobile-studioSection-title"
              >
                STUDIO{' '}
                <Tooltip
                  disableInteractive
                  title="Studio refers to a local distance within the studio zone"
                  placement="top-start"
                  className={classes.tooltip}
                >
                  <img src={TOOLTIP_ICON} alt="schedule tooltip" />
                </Tooltip>
              </Typography>
            </div>
          </div>
          <Table className={classes.distantContainer}>
            <TableHead>
              <TableRow>
                {dailyRate && (
                  <TableCell
                    className={classes.regularBox}
                    data-test-id="UnionDaillyOnCallMobile-dailyStudioRate-title"
                  >
                    Daily Rate
                  </TableCell>
                )}
                {guaranteedHours && (
                  <TableCell
                    className={classes.regularBox}
                    data-test-id="UnionDailyOnCallMobile-hourDays-title"
                  >
                    Pension Hours Per Day
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {dailyRate && (
                  <TableCell
                    className={classes.amount}
                    data-test-id="UnionDailyOnCallMobile-dailyRate-amount"
                  >
                    {formatAmount(dailyRate)}
                    {payAtScale && (
                      <Typography className={classes.scaleTip}>
                        *Scale Rate
                      </Typography>
                    )}
                  </TableCell>
                )}
                {guaranteedHours && (
                  <TableCell
                    className={classes.amount}
                    data-test-id="UnionDailyOnCallMobile-guaranteedHours-amount"
                  >
                    {guaranteedHours}
                    {payAtScale && (
                      <Typography className={classes.scaleTip}>
                        *Scale Rate
                      </Typography>
                    )}
                  </TableCell>
                )}
              </TableRow>
            </TableBody>
          </Table>
        </Fragment>
      )}
      {isDistant && (
        <>
          <div>
            <div className={classes.greenBox}>
              <Typography
                className={classes.distant}
                data-test-id="UnionDailyOnCallMobile-distantSection-title"
              >
                DISTANT{' '}
                <Tooltip
                  disableInteractive
                  title="Distant refers to the location outside the studio zone."
                  placement="top-start"
                  className={classes.tooltip}
                >
                  <img src={TOOLTIP_ICON} alt="schedule tooltip" />
                </Tooltip>
              </Typography>
            </div>
          </div>
          <Table className={classes.distantContainer}>
            <TableHead>
              <TableRow>
                <TableCell
                  className={classes.regularBox}
                  data-test-id="UnionDailyOnCallMobile-hourlyRate-title"
                >
                  Daily Rate
                </TableCell>
                <TableCell
                  className={classes.regularBox}
                  data-test-id="UnionDailyOnCallMobile-guaranteedHoursDistant-title"
                >
                  Pension Hours Per Day
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {dailyRateDistant && (
                  <TableCell
                    className={classes.amount}
                    data-test-id="UnionDailyOnCallMobile-rateDistant-amount"
                  >
                    {formatAmount(dailyRateDistant)}
                    {payAtScaleDistant && (
                      <Typography className={classes.scaleTip}>
                        *Scale Rate
                      </Typography>
                    )}
                  </TableCell>
                )}
                {guaranteedHoursDistant && (
                  <TableCell
                    className={classes.amount}
                    data-test-id="UnionDailyOnCallMobile-guaranteedHoursDistant-amount"
                  >
                    {guaranteedHoursDistant}
                    {payAtScaleDistant && (
                      <Typography className={classes.scaleTip}>
                        *Scale Rate
                      </Typography>
                    )}
                  </TableCell>
                )}
              </TableRow>
            </TableBody>
          </Table>
        </>
      )}
      <UsaOnly>
        <Table className={classes.distantContainer}>
          <TableHead>
            <TableRow>
              <TableCell
                className={classes.regularBox}
                data-test-id="UnionDailyOnCallMobile-idleRate-title"
              >
                Idle Days Pay
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                className={classes.amount}
                data-test-id="UnionDailyOnCallMobile-idleRateDistant-amount"
              >
                {payAt10thOr12th}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </UsaOnly>
      <Typography className={classes.scaleTip}>
        *Scale rate shown is as of offer creation date. Scale rate is subject to
        change upon union negotiated increases.
      </Typography>
    </div>
  );
};

export default UnionDailyEmployeeTermsMobile;

import React, { useState } from 'react';
import { Grid, Typography, Collapse } from '@mui/material';
import {
  ChevronRight as MoreIcon,
  KeyboardArrowDown as LessIcon,
} from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import MixedEmployeeTerms from 'mobile/components/Projects/MixedEmployeeTerms';
import UnionEmployeeTerms from 'mobile/components/Projects/UnionEmployeeTerms';
import CanadaOnly from 'common/components/CanadaOnly';
import CurrencyIndicator from 'onboarding/components/CurrencyIndicator';
import useFeatureFlags from 'common/hooks/useFeatureFlags';
import isUnionWeeklyOnCallSchedule from '../../../common/utilities/isUnionWeeklyOnCallSchedule';
import UnionWeeklyOnCallTerms from 'mobile/components/Projects/UnionWeeklyOnCallTerms';
import UnionDailyOnCallTerms from './UnionDailyOnCallTerms';
import UsaOnly from 'common/components/UsaOnly';

import moment from 'moment';

const employeeInfoStyles = makeStyles(theme => ({
  title: {
    color: 'rgb(67, 79, 89)',
    fontSize: '12px !important',
    fontWeight: '600 !important',
  },
  name: {
    fontSize: '24px !important',
    fontWeight: '400 !important',
    color: '#646464',
  },
  date: {
    fontSize: 14,
    color: '#646464',
    fontWeight: 400,
  },
  email: {
    fontSize: '10px !important',
    color: '#0000FF',
    fontWeight: '400 !important',
    marginTop: '3px !important',
    letterSpacing: '0.3px !important',
  },
  spacing: {
    marginTop: '16px',
    marginBottom: '10px',
  },
  amount: {
    color: '#0000FF',
    fontSize: 24,
    fontWeight: 400,
    marginRight: '10px',
  },
  description: {
    wordBreak: 'break-word',
    color: 'rgb(67, 79, 89)',
    fontSize: 12,
  },
  flag: {
    display: 'block',
    margin: 'auto',
    width: 24,
    height: 24,
  },
  caption: {
    color: '#aaaaaa',
    fontSize: 10,
    fontWeight: 400,
    padding: 0,
    textAlign: 'center',
  },
  currencyIndicator: {
    left: 50,
    position: 'relative',
  },
  noDeptText: {
    fontStyle: 'italic',
  },
  blue: { color: '#0000ff' },
  moreData: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const EmployeeInfo = ({ offer, countryCode }) => {
  const classes = employeeInfoStyles();
  const {
    id: offerId = null,
    customOccupationDescription = null,
    projectUser: { profile: { email, firstName, lastName } = {} } = {},
    termsOfHire: {
      season,
      hireState,
      workState,
      hireCity,
      workCity,
      hiringStatus,
      employmentClassification,
      union,
      occupation,
      workSchedule,
      department,
      subDepartment,
      currency,
      fullyOffsiteWorker,
      trackOffsiteWorkers,
    } = {},
  } = offer;
  const workScheduleCode = workSchedule?.code;
  const flags = useFeatureFlags();

  const isUnionScheduleAFlagActive = flags.includes('UnionScheduleA');
  const isUnionWeeklyOnCallFlagActive = flags.includes('UnionWeeklyOnCall');

  const [open, setOpen] = useState(false);

  const isUnionScheduleA =
    workScheduleCode === 'A' &&
    !union?.isNonUnion &&
    isUnionScheduleAFlagActive;

  const isUnionWeeklyOnCall =
    isUnionWeeklyOnCallFlagActive &&
    !union?.isNonUnion &&
    isUnionWeeklyOnCallSchedule(workScheduleCode);
  const isUnionDailyOnCall =
    !union?.isNonUnion &&
    offer?.termsOfEmploymentV2?.__typename === 'TermsOfEmploymentUnionDaily' &&
    countryCode === 'US';
  const hireStateName = hireState?.name;
  const hireCityName = hireCity?.name;
  const workStateName = workState?.name;
  const workCityName = workCity?.name;
  const unionName = union?.description;
  const occupationName = occupation?.description;
  const departmentName = department?.name;
  const workScheduleDescription = workSchedule?.description;
  const formatEmploymentClassification =
    employmentClassification && employmentClassification === 'loanOut'
      ? 'Loan-out'
      : employmentClassification;

  const formatEmploymentClassificationCAN =
    employmentClassification && employmentClassification === 'loanOut'
      ? 'Loan-out'
      : 'Individual';
  const isFullyOffsiteWorker = fullyOffsiteWorker ? 'Yes' : '';

  return (
    <div>
      <Grid container>
        <Grid item xs={6}>
          <Typography
            variant="h6"
            className={classes.title}
            data-test-id={`EmployeeInfo-title-${offerId}`}
            marginBottom={2}
          >
            Employee
          </Typography>
          <Typography
            variant="h2"
            className={classes.name}
            data-test-id={`EmployeeInfo-fullName-${offerId}`}
          >
            {`${firstName}  ${lastName}`}
          </Typography>
          <Typography
            variant="h6"
            className={classes.email}
            data-test-id={`EmployeeInfo-email-${offerId}`}
          >
            {email}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <CanadaOnly>
            <CurrencyIndicator
              className={classes.currencyIndicator}
              countryCode={countryCode}
              currencyClasses={{ flag: classes.flag, caption: classes.caption }}
            />
          </CanadaOnly>
        </Grid>
      </Grid>
      <Grid container className={classes.spacing}>
        <Grid item xs={7}>
          <Typography
            variant="h6"
            className={classes.title}
            data-test-id={`EmployeeInfo-position-${offerId}`}
          >
            Position/Title (Occupation Code)
          </Typography>
          <Typography
            className={classes.description}
            data-test-id={`EmployeeInfo-occupationDescription-${offerId}`}
          >
            {occupationName}
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography
            variant="h6"
            className={classes.title}
            data-test-id={`EmployeeInfo-offerStartDateTitle-${offerId}`}
          >
            Start Date
          </Typography>
          <Typography
            className={classes.description}
            data-test-id={`EmployeeInfo-offerStartDate-${offerId}`}
          >
            {moment(offer?.startDate).format('MM/DD/YYYY')}
          </Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.spacing}>
        <Grid item xs={7}>
          <Typography variant="h6" className={classes.title}>
            Union
          </Typography>
          <Typography
            className={classes.description}
            data-test-id={`EmployeeInfo-unionDescription-${offerId}`}
          >
            {unionName}
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography variant="h6" className={classes.title}>
            Department
          </Typography>
          <Typography
            className={classes.description}
            data-test-id={`EmployeeInfo-departmentName-${offerId}`}
          >
            {departmentName}
          </Typography>
        </Grid>
      </Grid>
      <p className={classes.blue} onClick={() => setOpen(!open)}>
        {open ? (
          <div className={classes.moreData}>
            <LessIcon />
            <p>See Less Details</p>
          </div>
        ) : (
          <div className={classes.moreData}>
            <MoreIcon fontSize="small" />
            <p>See More Details</p>
          </div>
        )}
      </p>
      <Collapse in={open} timeout="auto">
        <UsaOnly>
          <Grid container className={classes.spacing}>
            <Grid item xs={6}>
              {!(season === null) && (
                <>
                  <Typography variant="h6" className={classes.title}>
                    Season
                  </Typography>
                  <Typography className={classes.description}>
                    {season}
                  </Typography>
                </>
              )}
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6" className={classes.title}>
                Work Schedule
              </Typography>
              <Typography className={classes.description}>
                {workScheduleDescription}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.spacing}>
            <Grid item xs={6}>
              <Typography variant="h6" className={classes.title}>
                Hire State
              </Typography>
              <Typography className={classes.description}>
                {hireStateName}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6" className={classes.title}>
                Associated Hours+ Department
              </Typography>
              <Typography className={classes.description}>
                {subDepartment ? subDepartment?.name : 'Not Selected'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.spacing}>
            <Grid item xs={6}>
              <Typography variant="h6" className={classes.title}>
                Hire City
              </Typography>
              <Typography className={classes.description}>
                {hireCityName}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6" className={classes.title}>
                ACA Hiring Status
              </Typography>
              <Typography className={classes.description}>
                {hiringStatus === 'full_time' ? 'Full Time' : hiringStatus}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.spacing}>
            <Grid item xs={6}>
              <Typography variant="h6" className={classes.title}>
                Work State
              </Typography>
              <Typography className={classes.description}>
                {workStateName}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6" className={classes.title}>
                Job Description
              </Typography>
              <Typography className={classes.description}>
                {customOccupationDescription}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.spacing}>
            <Grid item xs={6}>
              <Typography variant="h6" className={classes.title}>
                Primary Work City
              </Typography>
              <Typography className={classes.description}>
                {workCityName}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6" className={classes.title}>
                Employment Classification
              </Typography>
              <Typography className={classes.description}>
                {formatEmploymentClassification}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.spacing}>
            <Grid item xs={6}>
              <Typography variant="h6" className={classes.title}>
                Currency
              </Typography>
              <Typography className={classes.description}>
                {currency}
              </Typography>
            </Grid>
            {trackOffsiteWorkers && (
              <Grid item xs={6}>
                <Typography variant="h6" className={classes.title}>
                  Fully Offsite Worker
                </Typography>
                <Typography className={classes.description}>
                  {isFullyOffsiteWorker}
                </Typography>
              </Grid>
            )}
          </Grid>
        </UsaOnly>
        <CanadaOnly>
          <Grid container className={classes.spacing}>
            <Grid item xs={6}>
              {!(season === null) && (
                <>
                  <Typography variant="h6" className={classes.title}>
                    Season
                  </Typography>
                  <Typography className={classes.description}>
                    {season}
                  </Typography>
                </>
              )}
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6" className={classes.title}>
                Work Schedule
              </Typography>
              <Typography className={classes.description}>
                {workScheduleDescription}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.spacing}>
            <Grid item xs={6}>
              <Typography variant="h6" className={classes.title}>
                Hire Province
              </Typography>
              <Typography className={classes.description}>
                {hireStateName}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6" className={classes.title}>
                Associated Hours+ Department
              </Typography>
              <Typography className={classes.description}>
                {subDepartment ? subDepartment?.name : 'Not Selected'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.spacing}>
            <Grid item xs={6}>
              <Typography variant="h6" className={classes.title}>
                Work Province
              </Typography>
              <Typography className={classes.description}>
                {workStateName}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6" className={classes.title}>
                Job Description
              </Typography>
              <Typography className={classes.description}>
                {customOccupationDescription}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.spacing}>
            <Grid item xs={6}>
              <Typography variant="h6" className={classes.title}>
                Employment Classification
              </Typography>
              <Typography className={classes.description}>
                {formatEmploymentClassificationCAN}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6" className={classes.title}>
                Associated Hours+ Department
              </Typography>
              <Typography className={classes.description}>
                {subDepartment ? subDepartment?.name : 'Not Selected'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.spacing}>
            <Grid item xs={6}>
              <Typography variant="h6" className={classes.title}>
                Currency
              </Typography>
              <Typography className={classes.description}>
                {currency}
              </Typography>
            </Grid>
          </Grid>
        </CanadaOnly>
      </Collapse>
      <Grid container className={classes.spacing}>
        {!isUnionScheduleA && !isUnionWeeklyOnCall && !isUnionDailyOnCall && (
          <MixedEmployeeTerms offer={offer} countryCode={countryCode} />
        )}
        {isUnionScheduleA && !isUnionDailyOnCall && (
          <UnionEmployeeTerms offer={offer} />
        )}
        {isUnionWeeklyOnCall && <UnionWeeklyOnCallTerms offer={offer} />}
        {isUnionDailyOnCall && <UnionDailyOnCallTerms offer={offer} />}
      </Grid>
    </div>
  );
};

export default EmployeeInfo;

import React from 'react';
import { createOfferCurrency as currency } from '../../utils/format';

import NonUnionTable from './RateScaleRow/NonUnionTable';
import UnionTable from './RateScaleRow/UnionTable';
import USUnionTable from 'studio/components/OfferPage/TermsOfEmployment/USTermsOfEmployment/RateTable';
import USUnionWeeklyTable from 'studio/components/OfferPage/TermsOfEmployment/USUnionWeeklyTermsOfEmployment/WeeklyRateTable';
import USWeeklyOnCall from 'studio/components/OfferPage/TermsOfEmployment/USWeeklyOnCall/WeeklyRateTable';
import USDailyOnCall from 'studio/components/OfferPage/TermsOfEmployment/USDailyOnCall/DailyRateTable';
import ExpandableTableCell from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableCell';
import ExpandableTableRow from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRow';
import ExpandableTableRowHeader from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRowHeader';
import ExpandableTableRowInfo from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRowInfo';
import NonUnionRateTable from 'studio/components/OfferPage/TermsOfEmployment/NonUnionRateTable';
import useFeatureFlags from 'common/hooks/useFeatureFlags';
import isNonUnionV2Active from 'common/utilities/isNonUnionActive';
import CanadaRateTable from 'studio/components/OfferPage/TermsOfEmployment/CanadaTermsOfEmployment/RateTable';
import useOfferCountry from 'common/components/SpecifiedCountryOnly/useOfferCountry';
import useOffers from 'studio/components/OfferPage/hooks/useOffers';
import withRouteHelpers from 'common/hoc/withRouteHelpers';
import useTermsOfEmploymentSettings from 'studio/hooks/useTermsOfEmploymentSettings';
import moment from 'moment';
import Loader from 'common/components/Loader';
import SAGTermsOfEmploymentTable from 'studio/components/OfferPage/TermsOfEmployment/SAGTermsOfEmployment';

const NON_UNION_EXEMPT_TABLE_HEADER = [
  'Daily Rate',
  'Weekly Rate',
  'Number of Days',
];
const NON_UNION_HOURLY_TABLE_HEADER = [
  'Hourly Rate',
  'Overtime Rate',
  'Guaranteed Hours',
];

const RateScaleRow = props => {
  const { offer, colSpan, getRouterParam } = props;
  const terms =
    offer?.terms_of_employment ||
    offer?.termsOfEmploymentV2 ||
    offer?.termsOfEmployment ||
    offer;
  const projectId = getRouterParam('projectId');
  const flags = useFeatureFlags();
  const isUnionWeeklyOnCallFlagActive = flags.includes('UnionWeeklyOnCall');

  const { data: offerData = [], loading: isOfferLoading } = useOffers(
    projectId,
    [offer?.id],
  );

  const { nodes: offers = [] } = offerData;

  const {
    data: offerCountry,
    loading: isOfferCountryLoading,
  } = useOfferCountry({
    offerId: offer?.id,
  });
  const offerInfo = offers.length > 0 ? offers[0] : [];
  const { startDate, endDate, termsOfHire = {} } = offerInfo;
  const {
    workState,
    workCity,
    hireState,
    hireCity,
    occupation,
    workSchedule: { code: workScheduleCode } = {},
    union: { code: unionCode, isNonUnion } = {},
    currency: offerCurrency,
    season,
  } = termsOfHire;

  const startDateObj = moment(startDate);

  const {
    loading: isTermsSettingsLoading,
    data: termsOfEmploymentSettings = {},
  } = useTermsOfEmploymentSettings({
    offerId: Number(offer?.id),
    projectId,
    nonUnion: isNonUnion,
    union: unionCode,
    occupation: occupation?.code,
    workSchedule: workScheduleCode,
    startDate: startDateObj && startDateObj.format('YYYY-MM-DD'),
    workState: workState?.code,
    workCity: workCity?.code,
    hireState: hireState?.code,
    hireCity: hireCity?.code,
    endDate,
    season,
    currency: offerCurrency,
  });

  const {
    unionHourlyDaily,
    unionHourlyWeekly,
    unionDaily,
    unionWeekly,
    unionSagDaily,
    unionSagWeekly,
    unionSagContract,
  } = termsOfEmploymentSettings?.types || {};

  const isOfferFullyLoaded =
    isOfferLoading || isOfferCountryLoading || isTermsSettingsLoading;
  const isCanada = offerCountry?.code === 'CA';
  const isEmptyPayrollCategories =
    !unionHourlyDaily && !unionHourlyWeekly && !unionDaily && !unionWeekly;
  const isDefaultUnionTable =
    (isEmptyPayrollCategories && !isNonUnion) ||
    (unionWeekly && !isNonUnion && !isUnionWeeklyOnCallFlagActive);

  const isUnionWeeklyOnCall =
    unionWeekly && !isNonUnion && isUnionWeeklyOnCallFlagActive && !isCanada;

  const isUnionDailyOnCall = unionDaily && !isNonUnion && !isCanada;

  const rateMultiplication = payRate => {
    if (!payRate) return 0;
    const days = terms?.guaranteed_days_studio || 0;
    const dailyRate = terms?.rate_per_week_studio / days;
    return Number((payRate / dailyRate).toFixed(1));
  };
  const renderRateTable = () => {
    if (isOfferFullyLoaded) {
      return <Loader />;
    }
    if (isCanada) {
      const { termsOfEmploymentV2 = {} } = offers.length > 0 ? offers[0] : {};
      return (
        <CanadaRateTable
          isReadOnly
          formData={termsOfEmploymentV2}
          onChange={() => {}}
          workScheduleCode={workScheduleCode}
          isNonUnion={isNonUnion}
        />
      );
    }

    const isUnionHourlyDailyScheduleV2 = unionHourlyDaily && !isNonUnion;
    const isUnionWeeklyScheduleV2 = unionHourlyWeekly && !isNonUnion;
    const { termsOfEmploymentV2 = {} } = offers.length > 0 ? offers[0] : {};

    if (isUnionHourlyDailyScheduleV2) {
      return (
        <USUnionTable
          isReadOnly
          formData={termsOfEmploymentV2}
          onChange={() => {}}
          manageOffers={true}
        />
      );
    }
    if (isUnionWeeklyScheduleV2) {
      return (
        <USUnionWeeklyTable
          isReadOnly
          formData={termsOfEmploymentV2}
          onChange={() => {}}
        />
      );
    }

    if (isUnionWeeklyOnCall) {
      return (
        <USWeeklyOnCall
          isReadOnly
          formData={termsOfEmploymentV2}
          onChange={() => {}}
          unionCode={unionCode}
        />
      );
    }

    if (isUnionDailyOnCall) {
      return (
        <USDailyOnCall
          isReadOnly
          formData={termsOfEmploymentV2}
          onChange={() => {}}
          unionCode={unionCode}
        />
      );
    }

    if (unionSagDaily || unionSagWeekly || unionSagContract) {
      // SAG Terms of Employment
      return (
        <SAGTermsOfEmploymentTable
          isReadOnly
          formData={termsOfEmploymentV2}
          onChange={() => {}}
          termsOfEmploymentSettings={termsOfEmploymentSettings?.types || {}}
        />
      );
    }
    if (isDefaultUnionTable) {
      return <UnionTable offer={offer} />;
    }
    const { data, header } = getNonUnionTableInfo();
    const employmentTermsData = {
      rate:
        terms?.rate_per_week_studio ||
        terms?.rate_per_day_studio ||
        terms?.rate_per_hour_studio ||
        terms?.rate,
      sixthDayPayMultiplication:
        terms?.sixthDayMultiplier ||
        rateMultiplication(terms?.pay_6th_day_rate) ||
        terms?.guaranteedHours6thDay,
      seventhDayPayMultiplication:
        terms?.seventhDayMultiplier ||
        rateMultiplication(terms?.pay_7th_day_rate) ||
        terms?.guaranteedHours7thDay,
      rateOvertime: terms?.overtime_rate || terms?.rateOvertime,
      guaranteedHours: terms?.guaranteed_hours_studio || terms?.guaranteedHours,
      guaranteedHours6thDay:
        terms?.pay_6th_day_rate || terms?.guaranteedHours6thDay,
      guaranteedHours7thDay:
        terms?.pay_7th_day_rate || terms?.guaranteedHours7thDay,
    };

    return isNonUnionV2Active(flags, workScheduleCode) ? (
      <NonUnionRateTable
        isReadOnly
        onChange={() => {}}
        formData={employmentTermsData}
        workScheduleCode={workScheduleCode}
      />
    ) : (
      <NonUnionTable tableHeader={header} tableData={data} offer={offer} />
    );
  };
  const getNonUnionTableInfo = () => {
    const terms_of_employment =
      offer?.terms_of_employment ||
      offer?.termsOfEmployment ||
      offer?.termsOfEmploymentV2 ||
      offer;
    const {
      ratePerDayStudio,
      ratePerWeekStudio,
      guaranteedDaysStudio,
      ratePerHour,
      overtimeRate,
      guaranteedHoursStudio,
      ratePerHourStudio,
      overtimeRatePerHour,
    } = terms_of_employment;
    if (ratePerDayStudio || ratePerWeekStudio || guaranteedDaysStudio) {
      return {
        data: [
          'Non-Union',
          currency(ratePerDayStudio),
          currency(ratePerWeekStudio),
          guaranteedDaysStudio,
        ],
        header: NON_UNION_EXEMPT_TABLE_HEADER,
      };
    }
    return {
      data: [
        'Non-Union Hourly',
        currency(ratePerHourStudio || ratePerHour),
        currency(overtimeRate || overtimeRatePerHour),
        guaranteedHoursStudio,
      ],
      header: NON_UNION_HOURLY_TABLE_HEADER,
    };
  };

  return (
    <ExpandableTableRow
      rowExpanded
      data-test-id={`RateScaleRow-root-${offer.id}`}
    >
      <ExpandableTableCell colSpan={colSpan} expanded>
        <ExpandableTableRowHeader>Rate/Scale</ExpandableTableRowHeader>
        <ExpandableTableRowInfo>{renderRateTable()}</ExpandableTableRowInfo>
      </ExpandableTableCell>
    </ExpandableTableRow>
  );
};

export default withRouteHelpers(RateScaleRow);

import React from 'react';

// MATERIAL
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles(() => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadMoreButton: {
    padding: '11px 16px !important',
    margin: '0 0 20px 0',
    background: '#FFFFFF',
    border: '1px solid #0000FF',
    color: '#0000FF',
    boxSizing: 'border-box',
    boxShadow:
      '0px 1px 1px rgba(82, 82, 82, 0.1), 0px 2px 4px rgba(82, 82, 82, 0.1), 0px 1px 5px rgba(82, 82, 82, 0.08)',
    borderRadius: '2px',
  },
  loadMoreMobileButton: {
    padding: '11px 16px !important',
    margin: '20px 0',
    background: '#FFFFFF',
    border: '1px solid #0000FF',
    color: '#0000FF',
    boxSizing: 'border-box',
    boxShadow:
      '0px 1px 1px rgba(82, 82, 82, 0.1), 0px 2px 4px rgba(82, 82, 82, 0.1), 0px 1px 5px rgba(82, 82, 82, 0.08)',
    borderRadius: '2px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  loadingIcon: {
    width: '20px',
    height: '20px',
    marginLeft: '10px',
    color: '#0000FF',
  },
}));

const FetchMoreReviewOffers = props => {
  const {
    loadMoreOffers,
    searchQuery,
    reviewOffersPageInfo,
    networkStatus,
  } = props;

  const StyledButton = styled(Button)({
    padding: '11px 16px',
    margin: '0 0 20px 0',
    background: '#FFFFFF',
    border: '1px solid #0000FF',
    color: '#0000FF',
    boxSizing: 'border-box',
    boxShadow:
      '0px 1px 1px rgba(82, 82, 82, 0.1), 0px 2px 4px rgba(82, 82, 82, 0.1), 0px 1px 5px rgba(82, 82, 82, 0.08)',
    borderRadius: '2px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  });

  const classes = useStyles();

  const loadReviewOffers = () => {
    const { endCursor } = reviewOffersPageInfo;

    loadMoreOffers({
      variables: {
        keyword: searchQuery?.length > 0 ? searchQuery : null,
        after: endCursor,
      },
      updateQuery: (prevResults, { fetchMoreResult }) => {
        const { project: fetchMoreReviewOffers = {} } = fetchMoreResult || {};
        const {
          projectReviews: {
            nodes: fetchMoreNodes = [],
            pageInfo: fetchMorePageInfo = {},
          } = {},
        } = fetchMoreReviewOffers;

        if (!fetchMoreNodes.length) return prevResults;

        return {
          project: {
            ...fetchMoreReviewOffers,
            projectReviews: {
              nodes: [
                ...prevResults.project.projectReviews.nodes,
                ...fetchMoreNodes.filter(
                  fetchMoreReviewsId =>
                    !prevResults.project.projectReviews.nodes.some(
                      prevResultsReviewsId =>
                        prevResultsReviewsId === fetchMoreReviewsId,
                    ),
                ),
              ],
              pageInfo: { ...fetchMorePageInfo },
              __typename: prevResults.project.projectReviews.__typename,
            },
          },
        };
      },
    });
  };

  return (
    <div className={classes.buttonContainer}>
      <StyledButton
        className={classes.loadMoreButton}
        variant="outlined"
        data-test-id={`Projects-load-more-button`}
        onClick={loadReviewOffers}
      >
        Load More{' '}
        {networkStatus === 3 && (
          <CircularProgress size="sm" className={classes.loadingIcon} />
        )}
      </StyledButton>
    </div>
  );
};

export default FetchMoreReviewOffers;

import React, { useState } from 'react';
import AllowancesRow from 'common/oldJavascripts/components/Shared/AllowancesRow';
import Blankslate from 'common/oldJavascripts/components/Base/Blankslate';
import Box from 'common/oldJavascripts/components/Base/Box';
import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';
import Button from 'common/oldJavascripts/components/Base/Button';
import CompletedReviewRow from './Reviews/CompletedRow';
import FailureAlert from 'common/oldJavascripts/components/Shared/FailureAlert';
import Field from 'common/oldJavascripts/components/Base/Field';
import Header from 'common/oldJavascripts/components/Base/Header';
import T from 'common/oldJavascripts/utils/i18n';
import LayoutContent from 'common/oldJavascripts/components/Base/Layout/LayoutContent';
import LayoutMain from 'common/oldJavascripts/components/Base/Layout/LayoutMain';
import Loader from 'common/components/Loader';
import ReviewRow from './Reviews/Row';
import Search from 'common/oldJavascripts/components/Shared/Search';
import object from 'common/oldJavascripts/utils/object.js';
import OfferFullHistory from './Offers/OfferFullHistory';
import history from 'common/constants/config/history';
import qs from 'qs';
import QueryRefresher from 'common/components/QueryRefresher';
import { withStyles } from '@mui/styles';
import withApi from 'common/hoc/withApi';
import { compose } from 'redux';
import withPermissionProtection from 'common/hoc/withPermissionProtection';
import ExpandableTable from 'common/oldJavascripts/components/Base/ExpandableTable';
import ExpandableTableRow from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRow';
import ExpandableTableCell from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableCell';
import useReviewOffers from './Reviews/hooks/useReviewOffers';
import FetchMoreReviewOffers from './Reviews/FetchMoreReviewOffers/index.js';

const Row = ExpandableTableRow;
const Cell = ExpandableTableCell;

const setID = (dict, id, flag) => {
  if (!dict[id] !== !flag) {
    dict = object.clone(dict);
    if (flag) {
      dict[id] = true;
    } else {
      delete dict[id];
    }
  }
  return dict;
};

const toggleId = (dict, id) => {
  dict = object.clone(dict);
  if (dict[id]) {
    delete dict[id];
  } else {
    dict[id] = true;
  }
  return dict;
};

const styles = () => ({
  table: {
    width: '100%',
    tableLayout: 'fixed',
  },
  checkBoxHeader: {
    width: '4%',
  },
  employeeNameHeader: {
    width: '15%',
  },
  departmentHeader: {
    width: '10%',
  },
  jobTitleHeader: {
    width: '10%',
  },
  unionHeader: {
    width: '9%',
  },
  startDateHeader: {
    width: '10%',
  },
  rateHeader: {
    width: '10%',
  },
  allowancesHeader: {
    width: '11%',
  },
  statusHeader: {
    width: '7%',
  },
  deleteHeader: {
    width: '5%',
  },
  loader: {
    backgroundColor: 'white',
    width: '100%',
    height: '200px',
  },
  recordsText: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.16px',
    color: '#8D8D8D',
  },
});

const Reviews = props => {
  const {
    project: projectQuery = {},
    classes = {},
    queryParams = {},
    routerParams = {},
    projectId,
    routerQuery,
  } = props;
  const reviewOffersVariables = {
    id: projectId,
    keyword: routerQuery?.q,
    after: null,
    sort: routerQuery?.sort,
    direction: routerQuery?.direction,
  };

  const {
    data: {
      projectReviews: { nodes: projectReviews = [] } = {},
      projectReviews: { pageInfo: reviewOffersPageInfo } = {},
    } = {},
    loading: isLoading,
    refetch: reloadQuery,
    fetchMore,
    networkStatus,
  } = useReviewOffers(reviewOffersVariables);

  const reviewsLoading = isLoading && networkStatus !== 3;

  // State variables using useState
  const [allowanceExpanded, setAllowanceExpanded] = useState({});
  const [expandedReviews, setExpandedReviews] = useState({});
  const [selectedReviews, setSelectedReviews] = useState({});
  const [statusExpanded, setStatusExpanded] = useState({});

  const areAllSelected = () => {
    return Object.keys(selectedReviews).length === projectReviews?.length;
  };

  const handleSelectReview = (id, selected) => {
    setSelectedReviews(prevSelectedReviews =>
      setID(prevSelectedReviews, id, selected),
    );
  };

  const isCompleted = () => {
    const { status } = queryParams || {};
    return status === 'completed';
  };

  const toggleAllowance = id => {
    setAllowanceExpanded(prevAllowanceExpanded => {
      const newAllowanceExpanded = toggleId(prevAllowanceExpanded, id);
      if (newAllowanceExpanded[id]) {
        setStatusExpanded(prevStatusExpanded =>
          setID(prevStatusExpanded, id, false),
        );
      }
      return newAllowanceExpanded;
    });
  };

  const toggleReview = id => {
    setExpandedReviews(prevExpandedReviews =>
      toggleId(prevExpandedReviews, id),
    );
  };

  const toggleStatus = id => {
    setStatusExpanded(prevStatusExpanded => {
      const newStatusExpanded = toggleId(prevStatusExpanded, id);
      if (newStatusExpanded[id]) {
        setAllowanceExpanded(prevAllowanceExpanded =>
          setID(prevAllowanceExpanded, id, false),
        );
      }
      return newStatusExpanded;
    });
  };

  const renderCompletedHeaderRow = () => {
    const allowEndDate = projectQuery?.data?.allow_end_date;
    return (
      <Row>
        <Cell
          className={classes.employeeNameHeader}
          header={true}
          sort="last_name,first_name,middle_name"
        >
          {T('project.reviews.columns.employee')}
        </Cell>
        <Cell
          className={classes.departmentHeader}
          header={true}
          sort="department"
        >
          {T('project.reviews.columns.department_account')}
        </Cell>
        <Cell className={classes.jobTitleHeader} header={true}>
          {T('project.reviews.columns.occupation')}
        </Cell>
        <Cell className={classes.unionHeader} header={true}>
          {T('project.reviews.columns.union')}
        </Cell>
        <Cell className={classes.startDateHeader} header={true}>
          {T('project.reviews.columns.start_date')}
        </Cell>
        {allowEndDate && (
          <Cell
            className={classes.startDateHeader}
            header={true}
            sort="end_date"
          >
            End Date
          </Cell>
        )}
        <Cell
          className={classes.rateHeader}
          header={true}
          sort="rate_per_hour_studio,rate_per_hour_location,rate_per_day_studio,rate_per_week_studio,rate_per_week_location"
        >
          {T('project.reviews.columns.rate')}
        </Cell>
        <Cell className={classes.allowancesHeader} header={true}>
          Allowances / Reimbursements
        </Cell>
        <Cell
          className={classes.statusHeader}
          header={true}
          sort="status_description"
        >
          {T('project.reviews.columns.status')}
        </Cell>
      </Row>
    );
  };

  const renderCompletedRows = review => {
    const id = review.id;
    const isAllowanceExpanded = allowanceExpanded[id];
    const isStatusExpanded = statusExpanded[id];
    const endDateAllowed = projectQuery?.data?.allow_end_date;

    const rows = [
      <CompletedReviewRow
        allowanceExpanded={isAllowanceExpanded}
        key={id}
        onAllowanceToggle={() => toggleAllowance(id)}
        onStatusToggle={() => toggleStatus(id)}
        params={{ id }}
        statusExpanded={isStatusExpanded}
        endDateAllowed={endDateAllowed}
      />,
    ];

    if (isAllowanceExpanded) {
      rows.push(
        <AllowancesRow colSpan={10} key={`allowance-${id}`} offer={review} />,
      );
    }

    if (isStatusExpanded) {
      rows.push(
        <ExpandableTableRow key={`status-${id}`}>
          <ExpandableTableCell colSpan={11} expanded>
            <Header>
              <Header.Title secondary>Offer Full History</Header.Title>
              <QueryRefresher reloadQuery={reloadQuery} />
            </Header>
            <OfferFullHistory offer={review} />
          </ExpandableTableCell>
        </ExpandableTableRow>,
      );
    }

    return rows;
  };

  const renderHeader = () => {
    return (
      <Header>
        <Header.Title>
          {T(`project.reviews.${isCompleted() ? 'sent_title' : 'title'}`)}
        </Header.Title>
        <QueryRefresher reloadQuery={reloadQuery} />
        <Header.Nav>
          <Search />
          {renderStartReviewButton()}
        </Header.Nav>
      </Header>
    );
  };

  const renderIncompleteHeaderRow = () => {
    const allowEndDate = projectQuery?.data?.allow_end_date;

    return (
      <Row data-test-id={`Reviews-row-header`}>
        <Cell className={classes.checkBoxHeader} header={true}>
          <Field
            inline={true}
            label=""
            onChange={checked => selectAll(checked)}
            type="checkbox"
            value={areAllSelected()}
          />
        </Cell>
        <Cell
          className={classes.employeeNameHeader}
          header={true}
          sort="last_name,first_name,middle_name"
        >
          {T('project.reviews.columns.employee')}
        </Cell>
        <Cell
          className={classes.departmentHeader}
          header={true}
          sort="department"
        >
          {T('project.reviews.columns.department_account')}
        </Cell>
        <Cell className={classes.jobTitleHeader} header={true}>
          {T('project.reviews.columns.occupation')}
        </Cell>
        <Cell className={classes.unionHeader} header={true}>
          {T('project.reviews.columns.union')}
        </Cell>
        <Cell className={classes.startDateHeader} header={true}>
          {T('project.reviews.columns.start_date')}
        </Cell>
        {allowEndDate && (
          <Cell
            className={classes.startDateHeader}
            header={true}
            sort="end_date"
          >
            End Date
          </Cell>
        )}
        <Cell
          className={classes.rateHeader}
          header={true}
          sort="rate_per_hour_studio,rate_per_hour_location,rate_per_day_studio,rate_per_week_studio,rate_per_week_location"
        >
          {T('project.reviews.columns.rate')}
        </Cell>
        <Cell className={classes.allowancesHeader} header={true}>
          Allowances / Reimbursements
        </Cell>
        <Cell
          className={classes.statusHeader}
          header={true}
          sort="status_description"
        >
          {T('project.reviews.columns.status')}
        </Cell>
        <Cell className={classes.deleteHeader} header={true} />
      </Row>
    );
  };

  const renderIncompleteRows = review => {
    const id = review.id;
    const isExpanded = expandedReviews[id];
    const isSelected = selectedReviews[id];
    const endDateAllowed = projectQuery?.data?.allow_end_date;

    const rows = [
      <ReviewRow
        checked={isSelected}
        expanded={isExpanded}
        key={id}
        onAllowanceToggle={() => toggleReview(id)}
        onSelect={(id, selected) => handleSelectReview(id, selected)}
        params={{ id }}
        endDateAllowed={endDateAllowed}
        offer={review}
        data-test-id={`Reviews-row-${id}`}
      />,
    ];

    if (isExpanded) {
      rows.push(
        <AllowancesRow key={`allowance-${id}`} colSpan={11} offer={review} />,
      );
    }

    return rows;
  };

  const renderStartReviewButton = () => {
    if (!isCompleted()) {
      return (
        <Button onClick={startReview}>
          {T('project.reviews.review_bulk')}
        </Button>
      );
    }
    return null;
  };

  const selectAll = selected => {
    if (selected) {
      const newSelectedReviews = {};
      projectReviews.forEach(review => {
        newSelectedReviews[review.id] = true;
      });
      setSelectedReviews(newSelectedReviews);
    } else {
      setSelectedReviews({});
    }
  };

  const startReview = () => {
    const { projectId } = routerParams || {};
    const reviewIds = Object.keys(selectedReviews);
    const [initialOffer] = reviewIds;

    if (reviewIds.length > 0) {
      history.push({
        pathname: `/projects/${projectId}/offers/${initialOffer}/edit`,
        search: qs.stringify({
          ids: reviewIds.join(','),
        }),
      });
    }
  };

  const renderContent = () => {
    const reviewsStatus = projectReviews?.status;

    if (reviewsLoading) {
      return <Loader className={classes.loader} />;
    }
    if (reviewsStatus === 'failed') {
      return <FailureAlert />;
    }
    if (projectReviews.length === 0) {
      return <Blankslate>{T('project.reviews.not_found')}</Blankslate>;
    }

    return (
      <ExpandableTable className={classes.table}>
        {isCompleted()
          ? renderCompletedHeaderRow()
          : renderIncompleteHeaderRow()}
        {projectReviews?.map(
          isCompleted() ? renderCompletedRows : renderIncompleteRows,
        )}
      </ExpandableTable>
    );
  };

  return (
    <LayoutMain>
      <LayoutContent>
        <Box full={true}>
          <BoxItem>{renderHeader()}</BoxItem>
          {renderContent()}
        </Box>
        {!reviewsLoading && (
          <span className={classes.recordsText}>
            Showing {projectReviews.length || 0} of{' '}
            {reviewOffersPageInfo?.totalCount} offers
          </span>
        )}
        {reviewOffersPageInfo?.hasNextPage && !reviewsLoading && (
          <FetchMoreReviewOffers
            loadMoreOffers={fetchMore}
            reviewOffersPageInfo={reviewOffersPageInfo}
            searchQuery={''}
            networkStatus={networkStatus}
          />
        )}
      </LayoutContent>
    </LayoutMain>
  );
};

export default compose(
  withPermissionProtection('can_review_offers'),
  withApi,
  withStyles(styles),
)(Reviews);

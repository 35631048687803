import React from 'react';
import Relay from 'common/oldJavascripts/utils/react/Relay.js';
import _ from 'lodash';
import T from 'common/oldJavascripts/utils/i18n';

import Text from 'common/oldJavascripts/components/Base/Text';
import { createOfferCurrency as formatCurrency } from 'common/oldJavascripts/utils/format.js';

import ExpandableTableCellTitle from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableCellTitle';
import ExpandableTableCell from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableCell';
import { withStyles } from '@mui/styles';

var ExpandableCell = ExpandableTableCell;
var ETableCellTitle = ExpandableTableCellTitle;

const styles = () => ({
  alert: {
    color: '#ED1C23',
  },
});

const RateCell = Relay.createClass({
  render: function() {
    var emptyText = T('shared.empty_text');
    const { classes } = this.props;
    var offer = this.props.offer;
    var terms =
      offer?.terms_of_employment ||
      offer?.termsOfEmploymentV2 ||
      offer?.termsOfEmployment ||
      offer;
    var scaleRate = offer.scaleRate;
    var locationScaleRate = offer?.locationScaleRate;
    var mainContent = null;
    var secondaryContent = null;
    var rate = null;
    var scale = null;
    var unionCode = offer?.union?.code || offer.union_code;
    const isExcludedUnions = unionCode === '817MPI' || unionCode === '399CAS';
    rate =
      terms?.rate_per_hour_studio ||
      terms?.ratePerHourStudio ||
      terms?.rate_per_hour_location ||
      terms?.ratePerHourLocation ||
      terms?.rate_per_day_studio ||
      terms?.ratePerDayStudio ||
      terms?.rate_per_week_studio ||
      terms?.ratePerWeekStudio ||
      terms?.rate_per_week_location ||
      terms?.ratePerWeekLocation ||
      terms?.rate ||
      terms?.rateDistant ||
      terms?.studioRatePerHour ||
      terms?.studioRatePerWeek ||
      terms?.sagRate;

    scale =
      offer.scale_rate_studio ||
      offer.scale_rate_location ||
      offer.scale_rate_per_hour_studio ||
      offer.scale_rate_per_hour_location ||
      offer.scale_rate_per_week_studio ||
      offer.scale_rate_per_week_location ||
      scaleRate ||
      locationScaleRate;

    rate = _.isString(rate) ? _.toNumber(rate) : rate ? rate : scale;

    if (rate && scale && rate !== scale && !isExcludedUnions) {
      mainContent = (
        <Text className={classes.alert}>{formatCurrency(rate, 9)}</Text>
      );
      if (!this.props.noShowScale) {
        secondaryContent = formatCurrency(scale, 9);
      }
    } else {
      mainContent = formatCurrency(rate, 9) || emptyText;
    }

    return (
      <ExpandableCell
        expanded={this.props.expanded}
        onClick={this.props.onClick}
        data-test-id={this.props['data-test-id']}
      >
        <span data-test-id={`RateCell-content-${offer.id}`}>
          <ETableCellTitle>{mainContent}</ETableCellTitle>
          {secondaryContent}
        </span>
      </ExpandableCell>
    );
  },
});

export default withStyles(styles)(RateCell);

import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const QUERY = gql`
  mutation inviteUser(
    $projectId: Int!
    $firstName: String!
    $lastName: String!
    $email: String!
    $phone: String
    $title: String!
    $privileges: [String!]!
    $sendDate: String!
  ) {
    projectAddNonEmployee(
      projectId: $projectId
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
      title: $title
      privileges: $privileges
      sendDate: $sendDate
    )
  }
`;

const useInviteUser = () => {
  const [inviteUser, { loading, error }] = useMutation(QUERY);
  return [inviteUser, loading, error];
};

export default useInviteUser;

import React from 'react';

// COMPONENTS
import Allowances from 'studio/components/ManageOffers/Allowances/Allowances';

import RateCell from 'studio/oldJavascripts/components/Pages/Project/Offers/RateCell';
import OfferI9Status from 'studio/components/OfferI9Status';
import OfferStatus from 'common/oldJavascripts/components/Shared/OfferStatus';

import ExpandedStatus from 'studio/components/ManageOffers/ExpandedRows/Status';
import ExapandedPacketFlow from 'studio/components/ManageOffers/ExpandedRows/PacketFlow';
import ExpandedDetails from 'studio/components/ManageOffers/ExpandedRows/Details';

// HELPERS
import nonUnionTermsOfEmployment from 'studio/components/ManageOffers/utilities/nonUnionTermsOfEmployment/nonUnionTermsOfEmployment';
import Feature from 'common/components/Feature';

// LEGACY LAYOUT COMPONENTS
import ExpandableTableRow from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRow';
import ExpandableTableCellTitle from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableCellTitle';
import ExpandableTableCell from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableCell';

// HELPER && HELPER COMPONENTS
import format from 'common/oldJavascripts/utils/format.js';
import UsaOnly from 'common/components/UsaOnly';
import Badges from 'common/components/Badges';

// HOC
import withApi from 'common/hoc/withApi';

// REDUX
import { compose } from 'redux';

// MUI
import { makeStyles } from '@mui/styles';
import { Tooltip, IconButton, Checkbox } from '@mui/material';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';

const useStyles = makeStyles(theme => ({
  actionContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridTemplateRows: '100%',
    gap: '6px',
  },
  icon: {
    color: '#0000FF',
  },
  overflowText: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  badgesContainer: {
    marginTop: 6,
  },
  noHorizontalPaddin: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  checkbox: {
    paddingLeft: 0,
    paddingTop: 0,
  },
  employeeCell: {
    display: 'flex',
  },
}));

const ManageOffersTableRow = props => {
  // STYLES
  const classes = useStyles();

  const {
    offer,
    endDateAllowed,
    onToggleDetails,
    onToggleStatus,
    isStatusExpanded = false,
    isDetailsExpanded = false,
    isPacketExpanded = false,
    expandedPacketFlow,
    onTogglePacketFlow,
    openAccountCodeModal,
    openActionMenu,
    onCheckBoxClick,
    handleStatusChange,
    hirerCanSendDocuments = false,
    selected = false,
    disabled = false,
    projectData,
  } = props;

  // OFFERS GRAPHQL STRUCTURE; DESTRUCTURING HERE
  const {
    badges,
    id,
    status,
    jobTitle,
    accountCode,
    endDate,
    sendDate,
    startDate,
    i9Verified,
    i9Document,
    i9CreatedDate,
    keepI9 = false,
    approvedAt,
    rejectedAt,
    rescindedOrArchivedAt,
    rescindedOrArchivedComments,
    termsOfEmployment: terms = {},
    version,
    projectUser: {
      profile: { email, firstName, lastName, middleName } = {},
    } = {},
    termsOfHire: {
      department: { name: departmentName } = {},
      union: { description: unionDescription, isNonUnion } = {},
    } = {},
    rejectionComments,
  } = offer;

  const isSAGUnion =
    offer?.termsOfEmploymentV2?.__typename === 'TermsOfEmploymentUnionSag';
  // OfferI9Status requires these properties to be formatted like this;
  const i9Info = {
    id: id,
    status: status,
    keepI9: keepI9,
    i9_verified: i9Verified,
    i9_document: i9Document,
    i9_created_date: i9CreatedDate,
    start_date: startDate,
    i9Correction: badges?.includes('i9_correction_requested'),
  };

  // statusList is used to determine if the offer is in a state that not allows the checkboxes to be checked
  const statusList = [
    'draft_review',
    'archived',
    'rescinded',
    'pending_email_delivery',
    'rejected',
    'rejected_employee',
    'complete_on_paper',
  ];

  // Rate cell needs different properties depending on what type of offer it is

  const rateCellInfo = isNonUnion
    ? nonUnionTermsOfEmployment(offer)
    : isSAGUnion
    ? {
        id,
        terms_of_employment: {
          sagRate: offer?.termsOfEmploymentV2?.rate,
        },
      }
    : {
        id,
        terms_of_employment: {
          rate_per_hour_studio: terms?.studioRatePerHour || terms?.ratePerHour,
          rate_per_hour_location: terms?.locationRatePerHour,
          rate_per_day_studio: terms?.studioRatePerDay || terms?.dailyRate,
          rate_per_day_location: terms?.locationRatePerDay,
          rate_per_week_studio: terms?.studioRatePerWeek || terms?.weeklyRate,
          rate_per_week_location: terms?.locationRatePerWeek,
        },
        scaleRate: offer?.scaleRate,
        locationScaleRate: offer?.locationScaleRate,
        union_code: offer?.termsOfHire?.union?.code,
      };

  const offerHistoryFallback = {
    id: id,
    sent_at: sendDate,
    approved_at: approvedAt,
    status: status,
    rejected_at: rejectedAt,
    rejection_comments: rejectionComments,
    rescinded_or_archived_at: rescindedOrArchivedAt,
    rescinded_or_archived_comments: rescindedOrArchivedComments,
    version: version,
  };

  // MOST OF THE LOGIC STAYS THE SAME AS THE OLD LEGACY COMPONENT

  const renderEndDateCell = () => {
    if (endDateAllowed) {
      return (
        <ExpandableTableCell
          date={true}
          data-test-id={`TableRow-endDate-${offer.id}`}
        >
          {format.date(endDate)}
        </ExpandableTableCell>
      );
    }
  };

  const handleCheckBoxClick = (event, offer) => {
    event.stopPropagation();
    onCheckBoxClick(offer);
  };

  // This is the cell that contains the checkboxes
  const renderCheckBoxCell = () => {
    const isAdditionalDocumentsBadge = badges?.find(
      badge => badge === 'additional_documents',
    );
    if (
      !statusList.includes(offer.status) &&
      hirerCanSendDocuments &&
      !isAdditionalDocumentsBadge
    ) {
      return (
        <Feature name="SendAdditionalDocuments">
          <Checkbox
            className={classes.checkbox}
            checked={selected}
            data-test-id={`offer-list-checkbox-${offer.id}`}
            onClick={event => handleCheckBoxClick(event, offer)}
            disabled={!selected && disabled} // if the checkbox is not selected, it should be disabled
          />
        </Feature>
      );
    } else {
      return ' ';
    }
  };

  const renderAction = () => {
    return (
      <IconButton
        onClick={e => {
          e.stopPropagation();
          openActionMenu(e, offer);
        }}
        data-test-id={`offer-action-menu-${id}`}
      >
        <MoreVertIcon />
      </IconButton>
    );
  };

  const toggleDetails = () => {
    onToggleDetails(id);
  };

  const toggleStatus = () => {
    onToggleStatus(id);
  };

  return (
    <>
      <ExpandableTableRow
        onClick={toggleDetails}
        expanded={isDetailsExpanded}
        data-test-id={props['data-test-id']}
      >
        <ExpandableTableCell>
          <div className={classes.employeeCell}>
            {renderCheckBoxCell()}
            <div>
              <ExpandableTableCellTitle
                data-test-id={`TableRow-employeeName-${id}`}
              >
                {format.fullName(firstName, middleName, lastName)}
              </ExpandableTableCellTitle>
              <Tooltip disableInteractive title={email} enterDelay={100}>
                <div
                  className={classes.overflowText}
                  data-test-id={`TableRow-email-${id}`}
                >
                  {email}
                </div>
              </Tooltip>
            </div>
          </div>
          <div
            className={classes.badgesContainer}
            data-test-id={`TableRow-badges-${id}`}
          >
            <Badges badges={badges} id={id} />
          </div>
        </ExpandableTableCell>
        <ExpandableTableCell>
          <ExpandableTableCellTitle data-test-id={`TableRow-department-${id}`}>
            {departmentName}
          </ExpandableTableCellTitle>
        </ExpandableTableCell>
        <ExpandableTableCell data-test-id={`TableRow-occupation-${id}`}>
          {jobTitle}
          <br />
          {accountCode}
        </ExpandableTableCell>
        <ExpandableTableCell data-test-id={`TableRow-union-${id}`}>
          {unionDescription}
        </ExpandableTableCell>
        <ExpandableTableCell
          date={true}
          data-test-id={`TableRow-startDate-${id}`}
        >
          {format.date(startDate)}
        </ExpandableTableCell>
        {renderEndDateCell()}

        {/* FIX THIS */}
        <RateCell offer={rateCellInfo} data-test-id={`TableRow-rate-${id}`} />
        <ExpandableTableCell
          align="center"
          data-test-id={`TableRow-allowances-${id}`}
        >
          <Allowances offer={offer} renderIconComponent={true} />
        </ExpandableTableCell>
        <UsaOnly>
          <ExpandableTableCell
            align="center"
            data-test-id={`TableRow-i9Status-${id}`}
            className={classes.noHorizontalPaddin}
          >
            <OfferI9Status props={{ ...i9Info }} />
          </ExpandableTableCell>
        </UsaOnly>
        <ExpandableTableCell
          onClick={toggleStatus}
          data-test-id={`TableRow-status-${id}`}
        >
          <OfferStatus
            status={status}
            startDate={startDate}
            sendDate={sendDate}
          />
        </ExpandableTableCell>
        <ExpandableTableCell
          className={classes.noHorizontalPaddin}
          data-test-id={`TableRow-actions-${id}`}
        >
          {renderAction()}
        </ExpandableTableCell>
      </ExpandableTableRow>
      {isStatusExpanded && (
        <ExpandedStatus
          offer={offerHistoryFallback}
          onToggleStatus={onToggleStatus}
        />
      )}
      {isDetailsExpanded && (
        <ExpandedDetails
          params={{
            offerId: id,
          }}
          projectData={projectData}
          offer={offer}
          handleStatusChange={handleStatusChange}
          openAccountCodeModal={openAccountCodeModal}
          expandedWorkflow={expandedPacketFlow}
          togglePacketFlow={onTogglePacketFlow}
        />
      )}
      {isPacketExpanded && (
        <ExapandedPacketFlow offer={offer} packetId={isPacketExpanded} />
      )}
    </>
  );
};

export default compose(withApi)(ManageOffersTableRow);

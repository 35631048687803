import moment from 'moment';
import omit from 'lodash.omit';

const termsOfEmploymentKeys = [
  'rate',
  'rateDistant',
  'guaranteedHours6thDay',
  'guaranteedHours7thDay',
  'guaranteedHours6thDayDistant',
  'guaranteedHours7thDayDistant',
  'payIdleDaysDistant',
  'payGoldAt',
  'payGoldAtDistant',
  'payAtScale',
  'payAtScaleDistant',
  'idleAtScaleDistant',
  'goldAtScale',
  'goldAtScaleDistant',
  'idleLocationSeventhDayRatePerHour',
  'idleLocationSixthDayRatePerHour',
  'locationRatePerHour',
  'locationRatePerWeek',
  'studioGuaranteedHours',
  'studioRatePerHour',
  'studioRatePerWeek',
  'dailyRate',
  'weeklyRate',
  'ratePerHour',
  'overtimeRatePerHour',
  'locationGuaranteedHours',
  'guaranteedHours',
  'numberOfDaysPerWorkWeek',
];

const allowancesKeys = [
  'boxRentalAllowance',
  'carAllowance',
  'computerRentalAllowance',
  'housingAllowance',
  'mobilePhoneAllowance',
  'perDiemAllowance',
];

const FORM_DATA_TO_ACCOUNT_CODE_KEY_MAPPING = {
  location: {
    formDataKey: 'termsOfEmployment',
    formDataSubKeys: [
      'idleLocationSeventhDayRatePerHour',
      'idleLocationSixthDayRatePerHour',
      'locationRatePerHour',
      'locationRatePerWeek',
      'rateDistant',
    ],
  },
  studio: {
    formDataKey: 'termsOfEmployment',
    formDataSubKeys: [
      'studioGuaranteedHours',
      'studioRatePerHour',
      'studioRatePerWeek',
      'rate',
    ],
  },
  daily: {
    formDataKey: 'termsOfEmployment',
    formDataSubKeys: ['dailyRate', 'rate'],
  },
  weekly: {
    formDataKey: 'termsOfEmployment',
    formDataSubKeys: ['weeklyRate', 'rate'],
  },
  hourly: {
    formDataKey: 'termsOfEmployment',
    formDataSubKeys: ['ratePerHour', 'rate'],
  },
  box: {
    formDataKey: 'allowances',
    formDataSubKeys: ['boxRentalAllowance'],
  },
  car: {
    formDataKey: 'allowances',
    formDataSubKeys: ['carAllowance'],
  },
  computer: {
    formDataKey: 'allowances',
    formDataSubKeys: ['computerRentalAllowance'],
  },
  housing: {
    formDataKey: 'allowances',
    formDataSubKeys: ['housingAllowance'],
  },
  mobile: {
    formDataKey: 'allowances',
    formDataSubKeys: ['mobilePhoneAllowance'],
  },
  perDiem: {
    formDataKey: 'allowances',
    formDataSubKeys: ['perDiemAllowance'],
  },
};

const getIsAccountCodeFilledIn = (formData, { value, lineItemKey }) => {
  if (!value) return false;
  const {
    [lineItemKey]: { formDataKey, formDataSubKeys },
  } = FORM_DATA_TO_ACCOUNT_CODE_KEY_MAPPING || {};
  const { [formDataKey]: formDataSubSet } = formData || {};
  return formDataSubKeys.some(subKey => {
    if (formDataKey === 'allowances') {
      const { [subKey]: { amount } = {} } = formDataSubSet || {};
      if (!amount) return false;
      return true;
    } else {
      const { [subKey]: value } = formDataSubSet || {};
      if (!value) return false;
      return true;
    }
  });
};

const formatAmount = value => (!!value && parseFloat(value)) || null;

const formatAllowances = allowance => ({
  ...allowance,
  amount: formatAmount(allowance.amount),
  duration: formatAmount(allowance.duration),
  cap: formatAmount(allowance.cap),
});

const formatTerms = terms => {
  const updatedTerms = { ...terms };
  Object.keys(terms).forEach(key => {
    if (termsOfEmploymentKeys.includes(key)) {
      updatedTerms[key] = formatAmount(terms[key]);
      delete updatedTerms['isPrcanRateTable'];
      delete updatedTerms['scaleRates'];
    }
    if (allowancesKeys.includes(key)) {
      updatedTerms[key] = formatAllowances(terms[key]);
    }
  });
  return updatedTerms;
};

// for Non Union schedules
const formatTermsV2 = (terms, termsOfEmploymentSettingType) => {
  const {
    rate,
    rateOvertime,
    ratePerHour,
    guaranteedHours,
    overtimeRatePerHour,
    guaranteedHours6thDay,
    guaranteedHours7thDay,
    sixthDayPayMultiplication,
    seventhDayPayMultiplication,
    guaranteedHoursFrequency,
    reason,
    override,
    guaranteedDays,
  } = terms;

  const hourlyRate = formatAmount(ratePerHour);

  return {
    rate: hourlyRate || formatAmount(rate),
    rateOvertime:
      formatAmount(overtimeRatePerHour) || formatAmount(rateOvertime),
    guaranteedHours: parseFloat(guaranteedHours),
    guaranteedHours6thDay,
    guaranteedHours7thDay,
    sixthDayPayMultiplication,
    seventhDayPayMultiplication,
    type: termsOfEmploymentSettingType,
    negotiatedContract: terms?.negotiatedContract,
    guaranteedHoursFrequency,
    reason,
    override,
    guaranteedDays,
  };
};

// for union schedule A
const formatTermsUnionHourlyV2 = (terms, termsOfEmploymentSettingType) => {
  const { ratePerHour, overtimeRatePerHour, rate, guaranteedHours } = terms;
  const result = omit(terms, ['isTermsOfEmploymentV2']);
  const unionScheduleData = {
    rateDistant: formatAmount(terms?.rateDistant),
    payGoldAt: formatAmount(terms?.payGoldAt),
    payGoldAtDistant: formatAmount(terms?.payGoldAtDistant),
    payIdleDaysDistant: formatAmount(terms?.payIdleDaysDistant),
    rate: formatAmount(ratePerHour) || formatAmount(rate),
    rateOvertime: formatAmount(overtimeRatePerHour),
    guaranteedHours: parseFloat(guaranteedHours),
    negotiatedContract: terms?.negotiatedContract,
  };

  return {
    ...result,
    ...unionScheduleData,
    type: termsOfEmploymentSettingType,
  };
};

// for union weekly schedule
const formatTermsUnionWeeklyV2 = (terms, termsOfEmploymentSettingType) => {
  const unionScheduleData = {
    rate: formatAmount(terms?.rate) || formatAmount(terms?.ratePerHour),
    rateDistant: formatAmount(terms?.rateDistant),
    weeklyRate: formatAmount(terms?.weeklyRate),
    weeklyRateDistant: formatAmount(terms?.weeklyRateDistant),
    guaranteedHours: parseFloat(terms?.guaranteedHours),
    guaranteedHoursDistant: parseFloat(terms?.guaranteedHoursDistant),
    payGoldAt: formatAmount(terms?.payGoldAt),
    payGoldAtDistant: formatAmount(terms?.payGoldAtDistant),
    payIdleDaysDistant: formatAmount(terms?.payIdleDaysDistant),
    goldAtScale: terms?.goldAtScale,
    goldAtScaleDistant: terms?.goldAtScaleDistant,
    idleAtScaleDistant: terms?.idleAtScaleDistant,
    payAtScale: terms?.payAtScale,
    payAtScaleDistant: terms?.payAtScaleDistant,
    negotiatedContract: terms?.negotiatedContract,
  };
  return {
    ...unionScheduleData,
    type: termsOfEmploymentSettingType,
  };
};

const formatTermsUnionWeeklyOnCall = (terms, termsOfEmploymentSettingType) => {
  const unionScheduleData = {
    rate: formatAmount(terms?.rate),
    rateDistant: formatAmount(terms?.rateDistant),
    guaranteedHours: parseFloat(terms?.guaranteedHours),
    guaranteedHoursDistant: parseFloat(terms?.guaranteedHoursDistant),
    payAtScale: terms?.payAtScale,
    payAtScaleDistant: terms?.payAtScaleDistant,
    payIdleDaysDistantAt10th: terms?.payIdleDaysDistantAt10th,
    payIdleDaysDistantAt12th: terms?.payIdleDaysDistantAt12th,
    payIdleDaysDistantAtScale: terms?.payIdleDaysDistantAtScale,
    payIdleDaysDistantAtNegotiated: terms?.payIdleDaysDistantAtNegotiated,
    negotiatedContract: terms?.negotiatedContract,
  };
  return {
    ...unionScheduleData,
    type: termsOfEmploymentSettingType,
  };
};

const formatTermsUnionDailyOnCall = (terms, termsOfEmploymentSettingType) => {
  const unionScheduleData = {
    rate: formatAmount(terms?.rate),
    rateDistant: formatAmount(terms?.rateDistant),
    guaranteedHours: parseFloat(terms?.guaranteedHours),
    guaranteedHoursDistant: parseFloat(terms?.guaranteedHoursDistant),
    payAtScale: terms?.payAtScale,
    payAtScaleDistant: terms?.payAtScaleDistant,
    payIdleDaysDistantAt10th: terms?.payIdleDaysDistantAt10th,
    payIdleDaysDistantAt12th: terms?.payIdleDaysDistantAt12th,
    payIdleDaysDistantAtScale: terms?.payIdleDaysDistantAtScale,
    payIdleDaysDistantAtNegotiated: terms?.payIdleDaysDistantAtNegotiated,
    negotiatedContract: terms?.negotiatedContract,
  };
  return {
    ...unionScheduleData,
    type: termsOfEmploymentSettingType,
  };
};

// for SAG union schedule
const formatTermsV2SAG = (terms, termsOfEmploymentSettingType) => {
  const {
    rate,
    guaranteedHours,
    guaranteedHoursFrequency,
    reason,
    override,
    guaranteedDays,
  } = terms;

  return {
    rate: formatAmount(rate),
    guaranteedHours: parseFloat(guaranteedHours),
    type: termsOfEmploymentSettingType,
    guaranteedHoursFrequency,
    reason,
    override,
    guaranteedDays,
  };
};

const getNormalizedMutationPayload = formData => {
  const {
    offerDate = {},
    termsOfHire = {},
    termsOfEmployment: formTermsOfEmployment = {},
    allowances = {},
    accountCodes = [],
    dealNotes = [],
    documents = [],
    documentFields = [],
    negotiatedContract: negotiatedContractDetails,
    termsOfEmploymentSettingType,
    draftId = null,
  } = formData;

  const { startDateObject, sendDateObject, endDateObject } = offerDate || {};
  const { union, occupation, workSchedule, subDepartment } = termsOfHire || {};
  const { value: unionValue, label: unionLabel, isNonUnion } = union || {};
  const { value: occupationValue, label: occupationLabel, workerType } =
    occupation || {};
  const { value: workScheduleValue, label: workScheduleLabel } =
    workSchedule || {};
  const {
    isTermsOfEmploymentV2 = false,
    isPrcanRateTable = false,
    scaleRates = {},
    negotiatedContract,
  } = formTermsOfEmployment;
  const contractDetails = negotiatedContractDetails || negotiatedContract;
  const { contractId, isNegotiated } = contractDetails || {};

  const sagTypes = ['UNION_SAG_WEEKLY', 'UNION_SAG_DAILY', 'UNION_SAG_HOURLY'];
  const isSAGUnion = sagTypes.includes(termsOfEmploymentSettingType);
  const omittedToe = omit({ ...formTermsOfEmployment }, [
    '__typename',
    'tableErrors',
    'scaleRateHash',
  ]);
  const termsOfEmployment = {
    ...omittedToe,
    negotiatedContract: { contractId, isNegotiated },
  };
  const isUnionWeeklySchedule =
    termsOfEmploymentSettingType === 'UNION_HOURLY_WEEKLY' &&
    !isNonUnion &&
    isTermsOfEmploymentV2;
  const isUnionWeeklyOnCallSchedule =
    termsOfEmploymentSettingType === 'UNION_WEEKLY' &&
    !isNonUnion &&
    isTermsOfEmploymentV2;
  const isUnionDailyOnCallSchedule =
    termsOfEmploymentSettingType === 'UNION_DAILY' &&
    !isNonUnion &&
    isTermsOfEmploymentV2;
  const isUnionHourlyDailySchedule =
    termsOfEmploymentSettingType === 'UNION_HOURLY_DAILY' &&
    !isNonUnion &&
    !isPrcanRateTable;
  const sanitizedTermsOfEmployment = formatTerms({ ...termsOfEmployment });

  let sanitizedTermsOfEmploymentV2;
  if (isUnionHourlyDailySchedule) {
    sanitizedTermsOfEmploymentV2 = formatTermsUnionHourlyV2(
      {
        ...termsOfEmployment,
      },
      termsOfEmploymentSettingType,
    );
  } else if (isUnionWeeklySchedule) {
    sanitizedTermsOfEmploymentV2 = formatTermsUnionWeeklyV2(
      {
        ...termsOfEmployment,
      },
      termsOfEmploymentSettingType,
    );
  } else if (isUnionWeeklyOnCallSchedule) {
    sanitizedTermsOfEmploymentV2 = formatTermsUnionWeeklyOnCall(
      {
        ...termsOfEmployment,
      },
      termsOfEmploymentSettingType,
    );
  } else if (isUnionDailyOnCallSchedule) {
    sanitizedTermsOfEmploymentV2 = formatTermsUnionDailyOnCall(
      {
        ...termsOfEmployment,
      },
      termsOfEmploymentSettingType,
    );
  } else if (isSAGUnion) {
    sanitizedTermsOfEmploymentV2 = formatTermsV2SAG(
      { ...termsOfEmployment },
      termsOfEmploymentSettingType,
    );
  } else {
    sanitizedTermsOfEmploymentV2 = formatTermsV2(
      { ...termsOfEmployment },
      termsOfEmploymentSettingType,
    );
  }

  const sanitizedAllowances = formatTerms({ ...allowances });
  const allowanceKeys = Object.keys(sanitizedAllowances);
  allowanceKeys.forEach(key => delete sanitizedAllowances[key].__typename);

  const sanitizedAccountCodes = [...accountCodes]
    .filter(accountCode => getIsAccountCodeFilledIn(formData, accountCode))
    .map(ac => {
      const updatedAccountCode = { ...ac, id: ac.accountCodeId };
      delete updatedAccountCode?.accountCodeId;
      delete updatedAccountCode?.__typename;
      return updatedAccountCode;
    });

  const currentDealNote = dealNotes.find(({ current }) => !!current);
  const newDealNote = currentDealNote && {
    value: currentDealNote.notes,
    timeStamp: moment().format('M/D/YYYY h:mm a'),
  };

  const formattedSubDepartment = omit(subDepartment, ['__typename']);

  const formattedTermsOfHire = {
    ...termsOfHire,
    occupation: {
      code: occupationValue,
      description: occupationLabel,
      workerType,
    },
    union: {
      code: unionValue,
      description: unionLabel,
      isNonUnion: isNonUnion,
    },
    workSchedule: {
      code: workScheduleValue,
      description: workScheduleLabel,
    },
    subDepartment: formattedSubDepartment?.id ? formattedSubDepartment : null,
  };
  delete formattedTermsOfHire.workStateId;
  delete formattedTermsOfHire.hireStateId;
  delete formattedTermsOfHire.workCityId;
  delete formattedTermsOfHire.hireCityId;

  // Coerce all document fields to string and remove __typenames
  const formattedDocumentFields = documentFields.map(({ id, value }) => ({
    id,
    value: `${value}`,
  }));

  const isUnionV2Terms =
    isTermsOfEmploymentV2 ||
    isUnionHourlyDailySchedule ||
    isUnionWeeklySchedule ||
    isUnionWeeklyOnCallSchedule ||
    isUnionDailyOnCallSchedule;

  const termsOfEmploymentTitle = isUnionV2Terms
    ? 'termsOfEmploymentV2'
    : 'termsOfEmployment';
  let toe = isUnionV2Terms
    ? { ...sanitizedTermsOfEmploymentV2, scaleRates }
    : { ...sanitizedTermsOfEmployment, scaleRates };

  if (isNonUnion || isPrcanRateTable) {
    delete toe?.scaleRates;
  }
  const mutationPayload = {
    offerDate: {
      startDate: startDateObject.format('YYYY-MM-DD'),
      sendDate: (sendDateObject && sendDateObject.format('YYYY-MM-DD')) || null,
      endDate: (!!endDateObject && endDateObject.format('YYYY-MM-DD')) || null,
    },
    termsOfHire: formattedTermsOfHire,
    [termsOfEmploymentTitle]: toe,
    allowances: sanitizedAllowances,
    accountCodes: sanitizedAccountCodes,
    dealNote: newDealNote,
    documents,
    documentFields: formattedDocumentFields,
    draftId: draftId,
  };
  return mutationPayload;
};

export default getNormalizedMutationPayload;

import React from 'react';
import LayoutContent from 'common/oldJavascripts/components/Base/Layout/LayoutContent';
import Box from 'common/oldJavascripts/components/Base/Box';
import Button from 'common/oldJavascripts/components/Base/Button';
import Header from 'common/oldJavascripts/components/Base/Header';
import Table from 'common/oldJavascripts/components/Base/Table';
import Blankslate from 'common/oldJavascripts/components/Base/Blankslate';
import Loader from 'common/components/Loader';
import UserPrivileges from 'common/oldJavascripts/utils/UserPrivileges.js';
import useInvitations from './hooks/useInvitations';
import withRouteHelpers from 'common/hoc/withRouteHelpers';
import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';
import { compose } from 'redux';
import FetchMoreInvitations from './FetchMoreInvitations';
import { styled } from '@mui/styles';

const { Row, Cell } = Table;

const TotalCount = styled('span')({
  fontWeight: '500',
  fontSize: '14px',
  lineHeight: '20px',
  letterSpacing: '0.16px',
  color: '#8D8D8D',
});

const InvitationRow = ({ invitation, id: key }) => {
  const { projectUser } = invitation;
  const { profile, privileges: privilegesData } = projectUser;
  const { firstName, lastName, email, phone } = profile;
  const privileges = new UserPrivileges(privilegesData || []);

  return (
    <Row key={key}>
      <Cell rowHeader={true} doubleBorderBottom={true}>
        <span data-test-id={`Invitations-name-${key}`}>
          {firstName} {lastName}
        </span>
      </Cell>
      <Cell doubleBorderBottom={true}>
        <span data-test-id={`Invitations-phone-${key}`}>{phone}</span>
      </Cell>
      <Cell doubleBorderBottom={true}>
        <span data-test-id={`Invitations-email-${key}`}>{email}</span>
      </Cell>
      <Cell doubleBorderBottom={true}>
        <span data-test-id={`Invitations-hirer-${key}`}>
          {privileges.isHirer() ? 'true' : 'false'}
        </span>
      </Cell>
      <Cell doubleBorderBottom={true}>
        <span data-test-id={`Invitations-reviewer-${key}`}>
          {privileges.isReviewer() ? 'true' : 'false'}
        </span>
      </Cell>
      <Cell doubleBorderBottom={true}>
        <span data-test-id={`Invitations-approver-${key}`}>
          {privileges.isApprover() ? 'true' : 'false'}
        </span>
      </Cell>
      <Cell doubleBorderBottom={true}>
        <span data-test-id={`Invitations-verifier-${key}`}>
          {privileges.isVerifier() ? 'true' : 'false'}
        </span>
      </Cell>
      <Cell doubleBorderBottom={true}>
        <span data-test-id={`Invitations-archives-${key}`}>
          {privileges.isArchiver() ? 'true' : 'false'}
        </span>
      </Cell>
      <Cell doubleBorderBottom={true}>
        <span data-test-id={`Invitations-viewer-${key}`}>
          {privileges.isDocumentsViewer() ? 'true' : 'false'}
        </span>
      </Cell>
      <Cell doubleBorderBottom={true}>
        <span data-test-id={`Invitations-role-${key}`}>Non-employee</span>
      </Cell>
    </Row>
  );
};

const Invitations = props => {
  const { routerParams: params } = props;
  const {
    data: {
      projectInvitations: { nodes: invitations = [], pageInfo = {} } = {},
    } = {},
    loading,
    networkStatus,
    fetchMore,
  } = useInvitations({ projectId: params?.projectId, after: null });

  const isLoading = loading && networkStatus !== 3;

  const rows = invitations.map(invitation => (
    <InvitationRow invitation={invitation} id={invitation.id} />
  ));

  const content = () => (
    <Table fixed={false}>
      <Table.Header>
        <Cell header={true}>Name</Cell>
        <Cell header={true}>Phone</Cell>
        <Cell header={true} width={'320px'}>
          Email
        </Cell>
        <Cell header={true}>Hirer</Cell>
        <Cell header={true}>Reviewer</Cell>
        <Cell header={true}>Approver</Cell>
        <Cell header={true}>I-9 Verifier</Cell>
        <Cell header={true}>Archives</Cell>
        <Cell header={true}>Document Viewer</Cell>
        <Cell header={true}>Role</Cell>
      </Table.Header>
      {rows}
    </Table>
  );

  const FetchMore = () => {
    if (pageInfo?.hasNextPage && !isLoading) {
      return (
        <FetchMoreInvitations
          loadMoreInvitations={fetchMore}
          pageInfo={pageInfo}
          networkStatus={networkStatus}
        />
      );
    } else {
      return null;
    }
  };

  if (isLoading) return <Loader />;

  return (
    <LayoutContent>
      <Box>
        <BoxItem>
          <Header>
            <Header.Title>
              <span data-test-id="Invitations-title">Invitations</span>
            </Header.Title>
            <Header.Nav>
              <Button
                to={`/projects/${params.projectId}/invitations/new`}
                params={{ projectId: params.projectId }}
                data-test-id="Invitations-inviteButton"
              >
                Invite/Add Non-production employee
              </Button>
            </Header.Nav>
          </Header>
        </BoxItem>
        {invitations.length > 0 && content()}
        {invitations.length === 0 && (
          <Blankslate>No invitations found.</Blankslate>
        )}
      </Box>
      {!isLoading && (
        <TotalCount>
          Showing {invitations.length || 0} of {pageInfo?.totalCount} offers
        </TotalCount>
      )}
      {FetchMore()}
    </LayoutContent>
  );
};

export default compose(withRouteHelpers)(Invitations);

import merge from 'lodash.merge';
import defaultsDeep from 'lodash.defaultsdeep';
import get from 'lodash.get';
import { compose } from 'redux';
import React, { useEffect, useState, useCallback } from 'react';
import { withStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import withApi from 'common/hoc/withApi';
import Loader from 'common/components/Loader';
import ScrollDestination from 'common/components/ScrollDestination';
import offerValidationChecks from './helpers/offerValidationChecks';
import OfferHeader from './OfferHeader';
import OfferDate from './OfferDate';
import TermsOfHire from './TermsOfHire';
import TermsOfEmployment from './TermsOfEmployment';
import Allowances from './Allowances';
import AccountCodeModalButton from './AccountCodeModalButton';
import DealNotes from './DealNotes';
import OfferDocuments from './OfferDocuments';
import SubmitOffer from './SubmitOffer';
import LoanOutRestriction from './LoanOutRestriction';
import AccountCodeModal from './AccountCodeModal';
import OfferConfirmation from './OfferConfirmation';
import useCities from 'common/hooks/useCities';
import useOfferDefaults from './hooks/useOfferDefaults';
import toggleInList from 'common/utilities/toggleInList';
import useScaleRates from 'studio/hooks/useScaleRates';
import useContracts from 'studio/hooks/useContracts';
import useDepartments from 'common/hooks/useDepartments';
import useAccountCodeConfigurations from './hooks/useAccountCodeConfigurations';
import useSeasons from 'studio/hooks/useSeasons';
import useProjectTemplates from 'studio/hooks/useProjectTemplates';
import useFeatureFlags from 'common/hooks/useFeatureFlags';
import useTermsOfEmploymentSettings from 'studio/hooks/useTermsOfEmploymentSettings';
import usePayrollValidation from 'studio/components/OfferPage/hooks/usePayrollValidation';
import PayrollValidationModal from 'studio/components/OfferPage/PayrollModal';
import {
  OMITTED_SCHEDULEA_UNIONS,
  DEFAULT_HOURS,
  DEFAULT_DAILY_HOURS,
  NOSCALE_UNIONWEEKLY_DEFAULTHOURS,
} from 'common/utilities/constants/noScaleUnions';
import { allowanceTypes } from 'common/utilities/constants/allowanceTypes';
import {
  Select as OfferDraftsSelect,
  Save as SaveOfferDraft,
} from './OfferDrafts';
import useOfferDrafts from './OfferDrafts/hooks/useOfferDrafts';
import moment from 'moment';

const styles = _theme => ({
  root: {
    gridArea: 'content',
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: 'auto auto auto auto auto auto auto auto auto auto',
    height: 'max-content',
    margin: '20px',
    padding: 30,
    paddingBottom: 50,
    boxSizing: 'content-box',
    gap: '45px',
    overflow: 'auto',
    position: 'relative',
  },
  offerHeader: {
    gridRow: 1,
  },
  offerDate: {
    gridRow: 2,
  },
  termsOfHire: {
    gridRow: 3,
  },
  termsOfEmployment: {
    gridRow: 4,
  },
  allowances: {
    gridRow: 5,
  },
  accountCodeModalButton: {
    gridRow: 6,
  },
  dealNotes: {
    gridRow: 7,
  },
  documents: {
    gridRow: 8,
  },
  documentFields: {
    gridRow: 9,
  },
  formActions: {
    height: 60,
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    padding: 12,
    display: 'flex',
    justifyContent: 'end',
    boxShadow: '0 -1px 3px rgba(0,0,0,0.35)',
    borderRadius: 0,
    zIndex: 1,
    '&> *': {
      marginLeft: 6,
    },
  },
});

const OfferForm = props => {
  // Props processing

  const {
    classes,
    crew,
    formData,
    headerContent,
    headerTitle,
    isReviewOffer,
    isTermsOfHireDisabled,
    onSubmit,
    performAdditionalValidation,
    projectId,
    projectWithPrivilegesQuery,
    setFormData,
    submitInProgress = false,
    isCopyOffer = false,
    showOfferDrafts = false,
    onLoadCrew = () => {},
  } = props;
  const {
    status: privilegesStatus,
    data: { privileges } = {},
  } = projectWithPrivilegesQuery;
  const {
    offerDate: offerDateFormData = {},
    termsOfHire: termsOfHireFormData = {},
    termsOfEmployment: termsOfEmploymentFormData = {},
    termsOfEmploymentV2: termsOfEmploymentV2FormData = {},
    allowances: allowancesFormData = {},
    dealNotes: dealNotesFormData = [],
    documents: documentsFormData = [],
    documentFields: documentFieldsFormData = [],
    accountCodes: accountCodeFormData = [],
    keepI9,
    i9DocumentTemplateId,
  } = formData;
  const isTermsOfEmploymentEmpty =
    Object.keys(termsOfEmploymentFormData).length === 0;
  const { startDateObject } = offerDateFormData;
  const {
    workState,
    hireState,
    workCity,
    hireCity,
    currency,
    union,
    workSchedule,
    occupation,
    season,
    department,
    employmentClassification,
    payAtRollback,
  } = termsOfHireFormData;
  const { value: unionCode = null, isNonUnion = false } = union || {};
  const { value: workScheduleCode = null } = workSchedule || {};
  const { value: occupationCode } = occupation || {};
  const [allowances, setAllowances] = useState([]);

  useEffect(() => {
    const allowanceArr = [];
    const { allowances: formDataAllowances } = formData;
    for (const allowanceType in formDataAllowances) {
      if (formDataAllowances[allowanceType]?.amount > 0)
        allowanceArr.push(allowanceTypes[allowanceType]);
    }
    setAllowances(allowanceArr);
  }, [formData?.allowances]); // eslint-disable-line react-hooks/exhaustive-deps

  const projectTemplatesQueryVariables = {
    projectId,
    workState,
    hireState,
    workCity,
    hireCity,
    union: unionCode,
    occupation: occupationCode,
    workSchedule: workScheduleCode,
    department,
    employmentClassification,
    allowances,
  };

  const reloadDrafts = () => refetchDrafts().catch(() => {});

  // refetch drafts on mount
  useEffect(() => {
    reloadDrafts();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Hooks
  const [
    isLoanOutRestrictionModalOpen,
    setIsLoanOutRestrictionModalOpen,
  ] = useState(false);
  const [isAccountCodeModalOpen, setIsAccountCodeModalOpen] = useState(false);
  const [
    isOfferConfirmationModalOpen,
    setIsOfferConfirmationModalOpen,
  ] = useState(false);
  const flags = useFeatureFlags();
  const isUnionScheduleAFlagActive = flags.includes('UnionScheduleA');
  const isUnionWeeklyOnCallFlagActive = flags.includes('UnionWeeklyOnCall');
  const isCanadianNonUnionFringesOn = flags.includes('CanadianNonUnionFringes');
  const isOfferDraftsFlagOn = flags.includes('OfferDrafts');
  const isSAGUnionFeatureOn = flags.includes('GuildManagement');

  const [offerDraft, setOfferDraft] = useState(null);
  const { refetch: refetchDrafts, ...restDrafts } = useOfferDrafts(
    isOfferDraftsFlagOn ? projectId : false,
  );

  const [scrollDestinationKey, setScrollDestinationKey] = useState();
  const [isEditingDealNotes, setIsEditingDealNotes] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const { data: offerDefaults } = useOfferDefaults(projectId);
  const { countryId } = offerDefaults;
  const { workStateId, hireStateId } = termsOfHireFormData;
  const [startDateValidationModal, setStartDateValidationModalState] = useState(
    false,
  );
  // This is a workaround because useLazyQuery does not return a promise
  // Need this function to fire when start changes
  const { refetch: payrollValidation } = usePayrollValidation({});

  const {
    loading: isTermsSettingsLoading,
    data: termsOfEmploymentSettings = {},
  } = useTermsOfEmploymentSettings({
    projectId,
    nonUnion: isNonUnion,
    union: unionCode,
    occupation: occupationCode,
    workSchedule: workScheduleCode,
    startDate: startDateObject && startDateObject.format('YYYY-MM-DD'),
    workState,
    workCity,
    hireState,
    hireCity,
    endDate: null,
    season,
    currency,
  });
  const { data: workStateCities = [] } = useCities({
    countryId,
    stateId: workStateId,
  });
  const { data: hireStateCities = [] } = useCities({
    countryId,
    stateId: hireStateId,
  });

  const {
    types: termsSettingsType = {},
    type: termsOfEmploymentSettingType,
    scaleRatesSelect: canSelectScaleRates = null,
  } = termsOfEmploymentSettings;

  const {
    unionHourlyDaily,
    unionHourlyWeekly,
    unionWeekly,
    unionDaily,
    nonUnionDaily,
    nonUnionHourlyDaily,
    nonUnionHourlyWeekly,
    nonUnionWeekly,
    unionSagDaily,
    unionSagWeekly,
    unionSagContract,
  } = termsSettingsType;
  const isCanada = offerDefaults?.countryCode === 'CA';
  const isUnionScheduleAV2 =
    !isNonUnion && unionHourlyDaily && isUnionScheduleAFlagActive && !isCanada;
  const isUnionWeeklyOnCall =
    !isNonUnion && unionWeekly && !isCanada && isUnionWeeklyOnCallFlagActive;
  const isUnionDailyOnCall = !isNonUnion && unionDaily && !isCanada;

  const isSAGUnion = unionCode === 'SAG' && isSAGUnionFeatureOn;

  const scaleRateQueryVariables = {
    projectId,
    countryCode: offerDefaults?.countryCode,
    startDate: startDateObject && startDateObject.format('YYYY-MM-DD'),
    workState,
    hireState,
    workCity,
    hireCity,
    currency,
    union: unionCode,
    isNonUnion,
    occupation: occupationCode,
    workSchedule: workScheduleCode,
    season,
    canSelectScaleRates,
    isSAGUnion,
  };

  const otherScaleVariables = {
    isUnionScheduleAV2,
    unionHourlyWeekly,
    isUnionWeeklyOnCall,
    isUnionDailyOnCall,
    payAtRollback,
    isTermsSettingsLoading,
  };
  const { loading: isScaleRatesLoading, data: scaleRates = {} } = useScaleRates(
    scaleRateQueryVariables,
    otherScaleVariables,
  );

  const { loading: isContractsLoading, data: contracts = [] } = useContracts(
    scaleRateQueryVariables,
  );
  const { loading: isTemplatesLoading, data: templates } = useProjectTemplates(
    projectTemplatesQueryVariables,
  );
  const { loading: isDepartmentsLoading, data: departments } = useDepartments({
    projectId,
    occupation: occupationCode,
    union: unionCode,
    workState,
    currency,
  });
  const { data: accountCodeConfigurations } = useAccountCodeConfigurations(
    projectId,
  );
  const { data: seasons = [] } = useSeasons(projectId);

  const isOmittedUnions = OMITTED_SCHEDULEA_UNIONS.includes(unionCode);
  const noScaleTerms =
    isUnionWeeklyOnCall ||
    isUnionDailyOnCall ||
    isUnionScheduleAV2 ||
    unionHourlyWeekly ||
    unionWeekly ||
    unionDaily;
  const isNoScaleUnions = isOmittedUnions && noScaleTerms;
  const isGuaranteedHours =
    !isScaleRatesLoading && !isNoScaleUnions && scaleRates?.guaranteedHours > 0;
  const isGuaranteedDistantHours =
    !isScaleRatesLoading &&
    !isNoScaleUnions &&
    scaleRates?.guaranteedHoursDistant > 0;

  useEffect(() => {
    setFormData(formData => ({
      ...formData,
      termsSettingsType: {
        unionHourlyDaily,
        unionHourlyWeekly,
        nonUnionDaily,
        nonUnionHourlyDaily,
        nonUnionHourlyWeekly,
        nonUnionWeekly,
        unionWeekly,
        unionDaily,
        unionSagDaily,
        unionSagWeekly,
        unionSagContract,
      },
      termsOfEmploymentSettingType,
      isCanadianNonUnionFringesOn,
    }));
  }, [
    setFormData,
    termsOfEmploymentSettingType,
    unionHourlyDaily,
    unionHourlyWeekly,
    nonUnionDaily,
    nonUnionHourlyDaily,
    nonUnionHourlyWeekly,
    nonUnionWeekly,
    unionWeekly,
    unionDaily,
    unionSagDaily,
    unionSagWeekly,
    unionSagContract,
    isCanadianNonUnionFringesOn,
  ]);

  // When new scale rates are returned from server, if the rates section is
  // empty populates it with the returned scale rates.
  useEffect(() => {
    if (!isScaleRatesLoading && !isCanada && !isNoScaleUnions) {
      const { newScaleRates = {} } = formData;
      // Set new Scale Rates in form data.
      if (scaleRates) {
        if (Object.keys(newScaleRates).length === 0) {
          setFormData(formData => ({ ...formData, newScaleRates: scaleRates }));
        }
        // Set New Scale rates to termsOfEmployment if there are none.
        if (isTermsOfEmploymentEmpty) {
          const items = {
            payIdleDaysDistant: scaleRates?.rateDistant,
            payGoldAt: scaleRates?.rate,
            payGoldAtDistant: scaleRates?.rateDistant,
            payAtScale: scaleRates?.rate > 0,
            payAtScaleDistant: scaleRates?.rateDistant > 0,
            idleAtScaleDistant: scaleRates?.rateDistant > 0,
            goldAtScale: scaleRates?.rate > 0,
            goldAtScaleDistant: scaleRates?.rateDistant > 0,
          };

          const scalesUnionScheduleA = isUnionScheduleAV2
            ? {
                ...items,
              }
            : {};
          const scalesUnionWeeklySchedule = unionHourlyWeekly
            ? {
                ...items,
                isTermsOfEmploymentV2: true,
              }
            : {};
          const scalesUnionWeeklyOnCallSchedule =
            unionWeekly && isUnionWeeklyOnCallFlagActive
              ? {
                  payAtScale: scaleRates?.rate > 0,
                  payAtScaleDistant: scaleRates?.rateDistant > 0,
                  payIdleDaysDistantAt12th: scaleRates?.rateDistant > 0,
                  payIdleDaysDistantAtScale: scaleRates?.rateDistant > 0,
                  guaranteedHours:
                    scaleRates?.guaranteedHours > 0
                      ? scaleRates?.guaranteedHours
                      : DEFAULT_HOURS,
                  guaranteedHoursDistant:
                    scaleRates?.guaranteedHoursDistant > 0
                      ? scaleRates?.guaranteedHoursDistant
                      : DEFAULT_HOURS,
                  isTermsOfEmploymentV2: true,
                }
              : {};
          const scalesUnionDailyOnCallSchedule =
            unionDaily && isUnionDailyOnCall
              ? {
                  payAtScale: scaleRates?.rate > 0,
                  payAtScaleDistant: scaleRates?.rateDistant > 0,
                  payIdleDaysDistantAt12th: scaleRates?.rateDistant > 0,
                  payIdleDaysDistantAtScale: scaleRates?.rateDistant > 0,
                  guaranteedHours:
                    scaleRates?.guaranteedHours > 0
                      ? scaleRates?.guaranteedHours
                      : DEFAULT_DAILY_HOURS,
                  guaranteedHoursDistant:
                    scaleRates?.guaranteedHoursDistant > 0
                      ? scaleRates?.guaranteedHoursDistant
                      : DEFAULT_DAILY_HOURS,
                  isTermsOfEmploymentV2: true,
                }
              : {};
          const guaranteedHoursFrequency = formData?.termsOfEmploymentSettingType?.includes(
            'DAILY',
            '',
          )
            ? 'daily'
            : 'weekly';

          setFormData(formData => ({
            ...formData,
            termsOfEmployment: {
              ...scaleRates,
              ...scalesUnionScheduleA,
              ...scalesUnionWeeklySchedule,
              ...scalesUnionWeeklyOnCallSchedule,
              ...scalesUnionDailyOnCallSchedule,
              guaranteedHoursFrequency,
            },
          }));
        }
      } else if (Object.keys(newScaleRates).length > 0) {
        // Clear Scale Rates.
        setFormData(formData => ({ ...formData, newScaleRates: {} }));
      }
    }
  });

  const formatDates = (date, isEndDate) =>
    date ? moment(date) : isEndDate ? date : moment();

  // Load Offer draft
  useEffect(() => {
    if (offerDraft) {
      const data = offerDraft.data;
      if (data?.offerDate) {
        data.offerDate.startDateObject = formatDates(data.offerDate.startDate);
        data.offerDate.sendDateObject = formatDates(data.offerDate.sendDate);
        data.offerDate.endDateObject = formatDates(
          data.offerDate.endDate,
          true,
        );
        if (offerDraft?.isTemplate) {
          const today = moment();
          data.offerDate.startDate = today.format('LL');
        }
      }
      if (!data?.dealNotes) {
        data.dealNotes = [];
      }
      if (data?.newScaleRates) delete data.newScaleRates;
      setFormData(formData => ({ ...formData, ...data }));
    }
  }, [offerDraft, setFormData]);

  // callback that runs when new scale rates are returned from server for CAN
  const memoizedCallback = useCallback(() => {
    //Updating termsOFEmployment with the Props data when there is negotiated data else scaleRates are assigning.
    setFormData(formData => ({
      ...formData,
      newScaleRates: { ...scaleRates },
      termsOfEmployment: {
        ...(formData?.termsOfEmployment?.negotiatedContract
          ? formData?.termsOfEmployment
          : scaleRates),
        isPrcanRateTable: true,
      },
    }));
  }, [scaleRates, setFormData]);

  useEffect(() => {
    if (isCanada && scaleRates && isTermsOfEmploymentEmpty) {
      setFormData(formData => ({
        ...formData,
        termsOfEmployment: { ...scaleRates, isPrcanRateTable: true },
      }));
    }
  }, [isTermsOfEmploymentEmpty, scaleRates, formData, setFormData, isCanada]);

  // callback within hook that runs when start date changes when creating an offer or editing an offer
  // updates terms of employment with scale rates based off of start date
  const memoizedCallbackForStartDateScaleRatesChange = useCallback(() => {
    // populates terms of employment if it happens to be empty
    if (isTermsOfEmploymentEmpty) {
      const items = {
        payIdleDaysDistant: scaleRates?.rateDistant,
        payGoldAt: scaleRates?.rate,
        payGoldAtDistant: scaleRates?.rateDistant,
        payAtScale: scaleRates?.rate > 0,
        payAtScaleDistant: scaleRates?.rateDistant > 0,
        idleAtScaleDistant: scaleRates?.rateDistant > 0,
        goldAtScale: scaleRates?.rate > 0,
        goldAtScaleDistant: scaleRates?.rateDistant > 0,
      };

      const scalesUnionScheduleA = isUnionScheduleAV2
        ? {
            ...items,
          }
        : {};
      const scalesUnionWeeklySchedule = unionHourlyWeekly
        ? {
            ...items,
            isTermsOfEmploymentV2: true,
          }
        : {};
      const scalesUnionWeeklyOnCallSchedule =
        unionWeekly && isUnionWeeklyOnCallFlagActive
          ? {
              payAtScale: scaleRates?.rate > 0,
              payAtScaleDistant: scaleRates?.rateDistant > 0,
              payIdleDaysDistantAt12th: scaleRates?.rateDistant > 0,
              payIdleDaysDistantAtScale: scaleRates?.rateDistant > 0,
              guaranteedHours:
                scaleRates?.guaranteedHours > 0
                  ? scaleRates?.guaranteedHours
                  : DEFAULT_HOURS,
              guaranteedHoursDistant:
                scaleRates?.guaranteedHoursDistant > 0
                  ? scaleRates?.guaranteedHoursDistant
                  : DEFAULT_HOURS,
              isTermsOfEmploymentV2: true,
            }
          : {};
      const scalesUnionDailyOnCallSchedule =
        unionDaily && isUnionDailyOnCall
          ? {
              payAtScale: scaleRates?.rate > 0,
              payAtScaleDistant: scaleRates?.rateDistant > 0,
              payIdleDaysDistantAt12th: scaleRates?.rateDistant > 0,
              payIdleDaysDistantAtScale: scaleRates?.rateDistant > 0,
              guaranteedHours:
                scaleRates?.guaranteedHours > 0
                  ? scaleRates?.guaranteedHours
                  : DEFAULT_DAILY_HOURS,
              guaranteedHoursDistant:
                scaleRates?.guaranteedHoursDistant > 0
                  ? scaleRates?.guaranteedHoursDistant
                  : DEFAULT_DAILY_HOURS,
              isTermsOfEmploymentV2: true,
            }
          : {};

      setFormData(formData => ({
        ...formData,
        termsOfEmployment: {
          ...scaleRates,
          ...scalesUnionScheduleA,
          ...scalesUnionWeeklySchedule,
          ...scalesUnionWeeklyOnCallSchedule,
          ...scalesUnionDailyOnCallSchedule,
        },
      }));
    }

    if (!isTermsOfEmploymentEmpty) {
      const { termsOfEmployment: terms = {} } = formData || {};

      const isUnionWeeklyWithOnCallFlagOn =
        unionWeekly && isUnionWeeklyOnCallFlagActive;
      const isUnionDailyOnCallPresent = unionDaily && isUnionDailyOnCall;

      const shouldFormatScaleRates =
        isUnionWeeklyWithOnCallFlagOn ||
        isUnionDailyOnCallPresent ||
        isUnionScheduleAV2 ||
        unionHourlyWeekly;

      const modifiedScaleRates = shouldFormatScaleRates
        ? defaultsDeep({}, terms, scaleRates)
        : defaultsDeep({}, scaleRates, terms);

      // If these are true in the terms of employment table then
      // we should perserve the negotiated rate when trying to update the scale rates
      // based off of start date
      const payAtScale = terms?.payAtScale;
      const payAtScaleDistant = terms?.payAtScaleDistant;

      if (shouldFormatScaleRates) {
        modifiedScaleRates.rate = payAtScale ? scaleRates?.rate : terms?.rate;

        modifiedScaleRates.rateDistant = payAtScaleDistant
          ? scaleRates?.rateDistant
          : terms?.rateDistant;
      }

      if (unionHourlyWeekly) {
        modifiedScaleRates.weeklyRate = payAtScale
          ? scaleRates?.weeklyRate
          : terms?.weeklyRate;
        modifiedScaleRates.weeklyRateDistant = payAtScaleDistant
          ? scaleRates?.weeklyRateDistant
          : terms?.weeklyRateDistant;
      }

      const items = {
        payIdleDaysDistant: modifiedScaleRates?.rateDistant,
        payGoldAt: modifiedScaleRates?.rate,
        payGoldAtDistant: modifiedScaleRates?.rateDistant,
      };

      const unionSchedAV2Toe = isUnionScheduleAV2
        ? {
            ...items,
          }
        : {};

      const unionHourlyWeeklyToe = unionHourlyWeekly
        ? {
            ...items,
            isTermsOfEmploymentV2: true,
          }
        : {};
      const unionWeeklyHourlyOnCallToe = isUnionWeeklyWithOnCallFlagOn
        ? {
            ...items,
            isTermsOfEmploymentV2: true,
          }
        : {};

      const unionDailyOnCallToe = isUnionDailyOnCallPresent
        ? {
            ...items,
            isTermsOfEmploymentV2: true,
          }
        : {};

      setFormData(formData => ({
        ...formData,
        termsOfEmployment: {
          ...modifiedScaleRates,
          ...unionSchedAV2Toe,
          ...unionHourlyWeeklyToe,
          ...unionWeeklyHourlyOnCallToe,
          ...unionDailyOnCallToe,
        },
      }));
    }
  }, [
    scaleRates,
    setFormData,
    isUnionScheduleAV2,
    formData,
    isTermsOfEmploymentEmpty,
    isUnionWeeklyOnCallFlagActive,
    unionHourlyWeekly,
    unionWeekly,
    unionDaily,
    isUnionDailyOnCall,
  ]);

  // When new scale rates are returned from server, if the rates section is
  // empty populates it with the returned scale rates.
  useEffect(() => {
    if (!isScaleRatesLoading && isCanada && !offerDraft) {
      const { newScaleRates = {} } = formData;
      // Set new Scale Rates in form data.
      if (Object.keys(scaleRates).length > 0) {
        if (Object.keys(newScaleRates).length === 0) {
          memoizedCallback();
        }
      } else if (Object.keys(newScaleRates).length > 0) {
        // Clear Scale Rates.
        setFormData(formData => ({
          ...formData,
          newScaleRates: {},
        }));
      }
    }
  });

  // This hook runs when we're validating terms of hire based off of start date
  // this causes scale rate to be fetched and updates the terms of employment tables with
  // new scale rates from the server based off of the start date
  useEffect(() => {
    if (
      !isScaleRatesLoading &&
      !isCanada &&
      !isNoScaleUnions &&
      startDateValidationModal
    ) {
      const { newScaleRates = {} } = formData;
      // Set new Scale Rates in form data.
      if (Object.keys(scaleRates).length > 0) {
        // callback for create/edit offer scale rate update when start date changes
        // i.e if new start date has updated scale rates, update the terms of employment table
        if (Object.keys(newScaleRates).length === 0) {
          memoizedCallbackForStartDateScaleRatesChange();
        }
      } else if (Object.keys(newScaleRates).length > 0) {
        // Clear Scale Rates.
        setFormData(formData => ({
          ...formData,
          newScaleRates: {},
        }));
      }
    }
  });

  // Gets and renders the correct scale rates based on start date when copying an offer
  useEffect(() => {
    if (
      !isScaleRatesLoading &&
      !isCanada &&
      !isNoScaleUnions &&
      !startDateValidationModal &&
      isCopyOffer
    ) {
      const { newScaleRates = {} } = formData;
      // Set new Scale Rates in form data.
      if (Object.keys(scaleRates).length > 0) {
        // callback for create/edit offer scale rate update when start date changes
        // i.e if new start date has updated scale rates, update the terms of employment table
        if (Object.keys(newScaleRates).length === 0) {
          memoizedCallbackForStartDateScaleRatesChange();
        }
      } else if (Object.keys(newScaleRates).length > 0) {
        // Clear Scale Rates.
        setFormData(formData => ({
          ...formData,
          newScaleRates: {},
        }));
      }
    }
  });

  // When there is a pre-selected department for the current union & occupation,
  // make sure it is selected in terms of hire.
  useEffect(() => {
    if (isDepartmentsLoading) return;
    const preSelectedDepartmentId = departments?.find(d => d.preSelected)?.id;
    if (
      preSelectedDepartmentId &&
      preSelectedDepartmentId !== formData.termsOfHire.department
    ) {
      updateDeep({ termsOfHire: { department: preSelectedDepartmentId } });
    }
  });

  // When templates are loaded & document selection has not been made, select
  // the recommended & required documents.
  useEffect(() => {
    let documents;
    if (!templates) {
      return;
    }
    if (!documentsFormData) {
      documents = templates
        ?.filter(({ required, preSelected }) => required || preSelected)
        .map(({ id }) => id);
    } else {
      // in case of edit offer, copy offer, draft offer or template offer
      // If the offer has already selected documents, make sure they are within the templates list
      // before submitting the form.
      documents = (documentsFormData || []).filter(docId =>
        (templates || []).find(({ id }) => id === docId),
      );
    }

    if (!isTemplatesLoading) {
      update({ documents });
    }
  }, [isTemplatesLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  // Hooks processing

  const {
    offerDate: offerDateFormErrors = {},
    termsOfHire: termsOfHireFormErrors = {},
    termsOfEmployment: termsOfEmploymentFormErrors = {},
    allowances: allowancesFormErrors = {},
    accountCodes: accountCodeFormErrors = [],
    dealNotes: dealNotesFormError = '',
    documents: documentsFormErrors = '',
    documentFields: documentFieldsFormErrors = [],
  } = formErrors || {};
  // Methods

  const performStandardValidation = () => {
    let results = offerValidationChecks.map(test =>
      test(formData, offerDefaults, {
        departments,
        seasons,
        privileges,
        workStateCities,
        hireStateCities,
        accountCodeConfigurations,
        templates,
        // Pass in isEditingOffer as true to skip
        // the crew member validation check
        isEditingOffer: isReviewOffer,
        contracts,
      }),
    );
    if (isEditingDealNotes)
      results = [
        ...results,
        { dealNotes: 'Please save or remove additional deal notes' },
      ];
    if (results.every(result => result === true)) return true;

    const newFormErrors = merge(
      {},
      ...results.filter(result => result !== true),
    );
    setFormErrors(newFormErrors);

    const firstError =
      results.find(
        result =>
          typeof result !== 'boolean' && Object.keys(result)[0] !== 'crew',
      ) || {};
    const sectionKey = Object.keys(firstError)[0];
    if (sectionKey) setScrollDestinationKey(sectionKey);
  };

  const onClickSubmit = () => {
    setScrollDestinationKey('');
    const isStandardValidationOk = performStandardValidation();
    const isAdditionalValidationOk = performAdditionalValidation
      ? performAdditionalValidation()
      : true;
    if (!(isStandardValidationOk && isAdditionalValidationOk)) return;
    setIsOfferConfirmationModalOpen(true);
  };

  const getAccountCodesWithUpdatedDetailSub = (accountCodes, departmentId) => {
    const { id: detailSubId } =
      accountCodeConfigurations.find(({ code }) => code === 'detail/sub') || {};
    if (!detailSubId) return accountCodes;
    const departmentCode = departments.find(({ id }) => id === departmentId)
      .code;
    const newAccountCodes = accountCodes.map(accountCode =>
      accountCode.accountCodeId === detailSubId
        ? { ...accountCode, value: departmentCode }
        : accountCode,
    );
    return newAccountCodes;
  };

  const validateStartDate = async variables => {
    const data = await payrollValidation(variables);
    return data?.data;
  };

  // Takes a `patch` object which is merged into the current formData state. The
  // merge can be shallow or deep depending on the `deep` argument.
  const update = (patch, deep = false) => {
    setFormData(formData => {
      const patchData = typeof patch === 'function' ? patch(formData) : patch;
      const newFormData = deep
        ? defaultsDeep({}, patchData, formData)
        : { ...formData, ...patchData };

      const {
        offerDate: { startDateObject: newStartDateObject = {} } = {},
      } = newFormData;

      // If start date has changed, clear union, occupation & schedule
      // TODO: Generalise this so changing any ToH field clears all the fields
      // after it. At the moment this logic is scattered about.
      const hasStartDateChanged =
        formData.offerDate.startDate !== newFormData.offerDate.startDate;

      const unionCodeOrValue =
        newFormData?.termsOfHire?.union?.code ||
        newFormData?.termsOfHire?.union?.value;

      const validateIfFieldsHaveValue =
        unionCodeOrValue &&
        newFormData?.termsOfHire?.occupation?.value &&
        newFormData?.termsOfHire?.workSchedule?.value;

      if (hasStartDateChanged && validateIfFieldsHaveValue) {
        const validatePayrollVariables = {
          projectId,
          union: unionCodeOrValue,
          occupation: newFormData?.termsOfHire?.occupation?.value,
          workSchedule: newFormData?.termsOfHire?.workSchedule?.value,
          scaleRate: {},
          startDate:
            newStartDateObject && newStartDateObject.format('YYYY-MM-DD'),
          workState: newFormData?.termsOfHire?.workState,
          workCity: newFormData?.termsOfHire?.workCity,
          hireState: newFormData?.termsOfHire?.hireState,
          hireCity: newFormData?.termsOfHire?.hireCity,
          endDate: newFormData?.offerDate?.endDate,
          season: newFormData?.termsOfHire?.season,
          currency: newFormData?.termsOfHire?.currency,
          rollback: newFormData?.termsOfHire?.payAtRollback,
        };

        setStartDateValidationModalState(true);
        // reset new scale rates if date changed so startDate hook runs
        newFormData.newScaleRates = {};

        validateStartDate(validatePayrollVariables)
          .then(data => {
            const {
              validatePayrollData: { occupation, union, workSchedule } = {},
            } = data;

            const isNotValid = !occupation && !union && !workSchedule;

            if (isNotValid || !occupation) {
              newFormData.termsOfHire.union = null;
              newFormData.termsOfHire.occupation = null;
              newFormData.termsOfHire.workSchedule = null;
            }

            // If union is false, reset these terms of hire fields because
            if (!union) {
              newFormData.termsOfHire.union = null;
              newFormData.termsOfHire.workSchedule = null;
            }

            // If work schedule is false, reset these terms of hire fields
            if (!workSchedule) {
              newFormData.termsOfHire.workSchedule = null;
            }

            return setStartDateValidationModalState(false);
          })
          .catch(err => {
            setStartDateValidationModalState(false);
            newFormData.termsOfHire.union = null;
            newFormData.termsOfHire.occupation = null;
            newFormData.termsOfHire.workSchedule = null;
          });
      }

      const getHaveTermsOfHireFieldsChanged = fields =>
        fields.some(field => {
          field = `termsOfHire.${field}`;
          return get(formData, field) !== get(newFormData, field);
        });

      const getHaveAllowancesFieldsChanged = fields =>
        fields.some(field => {
          field = `allowances.${field}`;
          return (
            (!get(formData, field) && get(newFormData, field)) ||
            (get(formData, field) && !get(newFormData, field))
          );
        });
      const haveAllowancesFieldsChanged = getHaveAllowancesFieldsChanged([
        'boxRentalAllowance.amount',
        'carAllowance.amount',
        'computerRentalAllowance.amount',
        'mobilePhoneAllowance.amount',
        'perDiemAllowance.amount',
        'housingAllowance.amount',
      ]);

      // If scale rate parameters have changed, clear terms of employment.
      const haveScaleRateParamsChanged = getHaveTermsOfHireFieldsChanged([
        'season',
        'hireState',
        'hireCity',
        'workState',
        'workCity',
        'currency',
        'union.code',
        'occupation.value',
        'workSchedule.value',
        'payAtRollback',
      ]);
      if (haveScaleRateParamsChanged) {
        newFormData.termsOfEmployment = {};
      }

      // If document auto-assignment params have changed, clear selected documents.
      const haveDocumentParamsChanged = getHaveTermsOfHireFieldsChanged([
        'hireState',
        'hireCity',
        'workState',
        'workCity',
        'union.code',
        'occupation.value',
        'workSchedule.value',
        'department',
        'employmentClassification',
      ]);
      if (haveDocumentParamsChanged || haveAllowancesFieldsChanged) {
        newFormData.documents = null;
      }

      // If department has changed, update account code detail/sub to dept code
      const hasDepartmentChanged = getHaveTermsOfHireFieldsChanged([
        'department',
      ]);
      if (hasDepartmentChanged) {
        newFormData.accountCodes = getAccountCodesWithUpdatedDetailSub(
          newFormData.accountCodes,
          newFormData.termsOfHire.department,
        );
      }

      // TODO
      // If an allowance is filled in, we should ideally set the corresponding
      // account codes to their default values.

      return newFormData;
    });
  };

  const updateDeep = patch => update(patch, true);

  const toggleDocumentSelection = id =>
    update(formData => ({
      documents: toggleInList(formData.documents ?? [], id),
    }));

  // Render

  if (
    privilegesStatus === 'loading' ||
    privilegesStatus === 'error' ||
    !offerDefaults
  )
    return <Loader />;

  const noScaleDefaultHours =
    isNoScaleUnions && isUnionWeeklyOnCall
      ? { ...NOSCALE_UNIONWEEKLY_DEFAULTHOURS }
      : {};

  const refinedScaleRates =
    isNoScaleUnions && isUnionWeeklyOnCall
      ? {
          ...NOSCALE_UNIONWEEKLY_DEFAULTHOURS,
        }
      : isNoScaleUnions
      ? {}
      : scaleRates;

  const changeOfferDraft = draft => {
    setOfferDraft(draft);
    update({ draftId: draft?.id });
    onLoadCrew(draft?.data?.crew || []);
  };

  return (
    <Paper className={classes.root}>
      <OfferHeader classes={{ root: classes.offerHeader }} title={headerTitle}>
        {headerContent}
      </OfferHeader>
      {isOfferDraftsFlagOn && showOfferDrafts && (
        <OfferDraftsSelect
          draft={offerDraft}
          onChange={changeOfferDraft}
          onDelete={draft => {
            if (offerDraft && draft?.id === offerDraft.id) {
              changeOfferDraft(null);
            }
            reloadDrafts();
          }}
          {...restDrafts}
        />
      )}
      <ScrollDestination
        isActive={scrollDestinationKey === 'offerDate'}
        behavior="smooth"
        block="center"
      >
        <OfferDate
          classes={{ root: classes.offerDate }}
          offerDefaults={offerDefaults}
          onChange={patch => updateDeep({ offerDate: patch })}
          formData={offerDateFormData}
          formErrors={offerDateFormErrors}
        />
      </ScrollDestination>
      <ScrollDestination
        isActive={scrollDestinationKey === 'termsOfHire'}
        behavior="smooth"
        block="center"
      >
        <TermsOfHire
          classes={{ root: classes.termsOfHire }}
          offerDefaults={offerDefaults}
          onChange={patch => updateDeep({ termsOfHire: patch })}
          projectId={projectId}
          formData={{
            ...offerDateFormData,
            ...termsOfHireFormData,
          }}
          formErrors={termsOfHireFormErrors}
          toggleAccountCodeModal={() => setIsAccountCodeModalOpen(true)}
          toggleLoanOutRestrictionModal={() =>
            setIsLoanOutRestrictionModalOpen(true)
          }
          isReviewOffer={isReviewOffer}
          disabled={isTermsOfHireDisabled}
          canSelectScaleRates={canSelectScaleRates}
          isTermsSettingsLoading={isTermsSettingsLoading}
          contracts={contracts}
          fringeLoading={isContractsLoading}
          isNonUnion={isNonUnion}
          isCopyOffer={isCopyOffer}
        />
      </ScrollDestination>
      <ScrollDestination
        isActive={scrollDestinationKey === 'termsOfEmployment'}
        behavior="smooth"
        block="center"
      >
        <TermsOfEmployment
          classes={{ root: classes.termsOfEmployment }}
          onChange={patch => updateDeep({ termsOfEmployment: patch })}
          formData={{
            ...offerDateFormData,
            ...termsOfHireFormData,
            ...termsOfEmploymentFormData,
            ...termsOfEmploymentV2FormData,
            ...noScaleDefaultHours,
            termsOfEmploymentSettingType,
          }}
          isTermsSettingsLoading={isTermsSettingsLoading}
          formErrors={termsOfEmploymentFormErrors}
          isReviewOffer={isReviewOffer}
          isScaleRatesLoading={isScaleRatesLoading}
          isContractsLoading={isContractsLoading}
          scaleRates={refinedScaleRates}
          termsOfEmploymentSettings={termsSettingsType}
          canSelectScaleRates={canSelectScaleRates}
          contracts={contracts}
          isCanada={isCanada}
          isGuaranteedHours={isGuaranteedHours}
          isGuaranteedDistantHours={isGuaranteedDistantHours}
        />
      </ScrollDestination>
      <ScrollDestination
        isActive={scrollDestinationKey === 'allowances'}
        behavior="smooth"
        block="center"
      >
        <Allowances
          classes={{ root: classes.allowances }}
          onChange={subSection => patch =>
            updateDeep({ allowances: { [subSection]: patch } })}
          formData={allowancesFormData}
          formErrors={allowancesFormErrors}
        />
      </ScrollDestination>
      <ScrollDestination
        isActive={scrollDestinationKey === 'accountCodes'}
        behavior="smooth"
        block="center"
      >
        <AccountCodeModalButton
          formErrors={accountCodeFormErrors}
          classes={{ root: classes.accountCodeModalButton }}
          onClick={() => setIsAccountCodeModalOpen(true)}
        />
      </ScrollDestination>
      <ScrollDestination
        isActive={scrollDestinationKey === 'dealNotes'}
        behavior="smooth"
        block="center"
      >
        <DealNotes
          classes={{ root: classes.dealNotes }}
          onChange={dealNotes => update({ dealNotes })}
          dealNotesFormData={formData}
          formData={dealNotesFormData}
          isEditingDealNotes={isEditingDealNotes}
          updateEditingDealNoteStatus={setIsEditingDealNotes}
          formError={dealNotesFormError}
        />
      </ScrollDestination>
      <ScrollDestination
        isActive={scrollDestinationKey === 'documents'}
        behavior="smooth"
        block="center"
      >
        <OfferDocuments
          classes={{
            documentFields: classes.documentFields,
            documents: classes.documents,
          }}
          formData={{
            ...offerDateFormData,
            ...termsOfHireFormData,
            documentFields: documentFieldsFormData,
            documents: documentsFormData || [],
          }}
          keepI9={keepI9 && !isCopyOffer}
          i9DocumentTemplateId={i9DocumentTemplateId}
          formErrors={{
            documentFieldsFormErrors,
            documentsFormErrors,
          }}
          onChangeDocument={toggleDocumentSelection}
          onChangeDocumentFields={documentFields => update({ documentFields })}
          scrollToDocumentFields={scrollDestinationKey === 'documentFields'}
          templates={templates}
          loading={isTemplatesLoading}
        />
      </ScrollDestination>
      <Paper className={classes.formActions}>
        {isOfferDraftsFlagOn && showOfferDrafts && (
          <SaveOfferDraft
            draft={offerDraft}
            projectId={projectId}
            data={{ ...formData, crew }}
            onChange={() => reloadDrafts()}
          />
        )}
        <SubmitOffer onClick={onClickSubmit} />
      </Paper>
      <LoanOutRestriction
        open={isLoanOutRestrictionModalOpen}
        onClose={() => setIsLoanOutRestrictionModalOpen(false)}
      />
      {isAccountCodeModalOpen && (
        <AccountCodeModal
          formData={{
            ...termsOfEmploymentFormData,
            ...allowancesFormData,
            accountCodes: accountCodeFormData,
            workScheduleCode,
            termsSettingsType,
          }}
          formErrors={accountCodeFormErrors}
          offerDefaults={offerDefaults}
          open={isAccountCodeModalOpen}
          accountCodeConfigurations={accountCodeConfigurations}
          onClose={() => setIsAccountCodeModalOpen(false)}
          onSave={accountCodes => update({ accountCodes })}
          privileges={privileges}
        />
      )}
      {isOfferConfirmationModalOpen && (
        <OfferConfirmation
          formData={formData}
          crew={crew}
          open={isOfferConfirmationModalOpen}
          onClose={() => setIsOfferConfirmationModalOpen(false)}
          onSubmit={onSubmit}
          scaleRates={scaleRates}
          scrollDestinationHandler={setScrollDestinationKey}
          isUnionWeeklySchedule={unionHourlyWeekly}
          isUnionWeeklyOnCall={unionWeekly}
          isUnionDailyOnCall={unionDaily}
          isUnionHourlyDaily={unionHourlyDaily}
          submitInProgress={submitInProgress}
          isCanada={isCanada}
        />
      )}
      <PayrollValidationModal open={startDateValidationModal} />
    </Paper>
  );
};

OfferForm.queries = {
  projectWithPrivilegesQuery: {
    info: (__, related) => {
      const routerParams = related['/router/params'];
      return {
        id: `/projects/${routerParams.projectId}?with_privileges=true`,
      };
    },
  },
};

export default compose(withApi, withStyles(styles))(OfferForm);

import React, { useState } from 'react';

// MATERIAL
import {
  Typography,
  Grid,
  Paper,
  TextField,
  InputAdornment,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';
import { makeStyles, ThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/material/styles';

// HOC
import withRouteHelpers from 'common/hoc/withApi';

// COMPONENTS
import SearchIcon from '@mui/icons-material/Search';

// HELPERS
import history from 'common/constants/config/history';
import T from 'common/oldJavascripts/utils/i18n';
import countryFlag from 'common/utilities/renderCountryFlag.js';
import FetchMoreProjects from 'studio/components/ProjectsList/helpers/FetchMoreProjects';

// HOOKS
import useProjects from 'studio/hooks/useProjects';

const projectsStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: '#f2f5f7',
    paddingTop: '20px',
    minHeight: '100vh',
    marginTop: '60px',
  },
  container: {
    marginTop: theme.spacing(3),
  },
  paperContainer: {
    paddingTop: '20px',
    paddingBottom: '20px',
    paddingLeft: '10px',
    boxShadow: '0px 2px 1px -1px #0000FF',
    margin: '1px 0 1px 0',
  },
  contentArea: {
    marginTop: '15px',
  },
  moreIcon: {
    color: '#0000FF',
    height: '24px',
    width: '24px',
  },
  pageTitle: {
    marginLeft: '20px',
    fontFamily: 18,
    color: '#646464',
    fontWeight: 500,
  },
  headerRow: {
    backgroundColor: '#f7fcfc',
    color: '#0000FF',
    fontSize: '14px',
    fontWeight: 500,
    padding: '0 20px !important',
  },
  projectRow: {
    borderBottom: '1px solid #d8d8d8',
  },
  projectName: {
    fontWeight: 'bold',
    color: '#646464',
    fontSize: 14,
    padding: '15px 24px 15px 0px',
  },
  projectListCardsColorText: {
    marginLeft: '12px',
    marginBottom: '5px',
    width: '96px',
    height: '18px',
    color: '#0000FF',
    fontSize: '14px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    letterSpacing: '0 16px',
    lineHeight: '18px',
    textAlign: 'left',
  },
  projecListCardsNonColorText: {
    height: '18px',
    color: '#525252',
    fontSize: '14px',
    fontWeight: 500,
    fontStyle: 'normal',
    letterSpacing: '0 16px',
    lineHeight: '18px',
    textAlign: 'left',
  },
  countryFlagImageSize: {
    width: '22px',
    height: '22px',
  },
  countryAlignFix: {
    verticalAlign: 'top',
    marginLeft: '7px',
    color: '#646464',
    fontSize: 12,
    fontWeight: '700',
  },
  titleText: {
    color: '#262626',
    letterSpacing: 'normal !important',
    lineHeight: '26px !important',
    marginLeft: '14px !important',
  },
  projectSearchInput: {
    background: '#fff !important',
    marginRight: '5px',
    height: '46px',
    paddingBottom: '15px',
    fontSize: '16px !important',
  },
  projectDropdownSort: {
    background: '#fff !important',
    maxWidth: '100%',
    minWidth: '30px !important',
    borderTopRightRadius: '3px !important',
    borderTopLeftRadius: '3px !important',
  },
  selectFormControlContainer: {
    width: '100%',
  },
  searchIcon: {
    color: '#a8a8a8',
    fontSize: '24px',
  },
  inputsStyles: {
    paddingLeft: '8px',
    paddingTop: '20px',
    paddingRight: '8px',
  },
}));

const theme = createTheme({
  components: {
    MuiInput: {
      underline: {
        borderBottom: `1px solid #8D8D8D`,
        '&:focused': {
          borderBottom: `1px solid #0000FF`,
        },
      },
    },
  },
});

const ProjectsList = props => {
  const classes = projectsStyles();
  const {
    routerQuery: { q: searchTerm },
  } = props;
  const [inputVal, setInputVal] = useState(searchTerm);
  const onChange = ({ target: { value } = {} } = {}) => {
    setInputVal(value);
  };
  // HOOKS
  const {
    data: projects = {},
    loading: projectsLoading,
    fetchMore: fetchMoreQuery,
  } =
    useProjects({
      keyword: inputVal || '',
      after: null,
    }) || {};

  // DESTRUCTURING PROPERTIES FROM PROJECTS QUERY
  const { nodes: projectsList = [], pageInfo: projectsPageInfo = {} } =
    projects || {};

  // CHECKS
  const isLoading = projectsLoading;
  const hasProjects = projectsList.length > 0;
  const hasNextPage = projectsPageInfo.hasNextPage;

  const renderProjectRow = (project = {}) => {
    const { privileges } = project || {};
    // Render the correct country flag depending on country code
    const renderCountryFlag = countryFlag(project?.country?.code);
    let to;
    if (privileges) {
      // This user is a studio admin so we figure out where they should go
      // based of the privileges in the projects query and the Privileges to map array
      to = privileges.includes('can_approve_offers')
        ? `/projects/${project.id}/offers`
        : '/projects';
    } else {
      // If a user arrived here then there is a problem with this users permission configurations
      // Or they have been revoked access
      to = '/projects';
    }
    return (
      <Paper className={classes.paperContainer}>
        <Grid
          container
          onClick={() => history.push({ pathname: to })}
          key={project.id}
          data-test-id={`Projects-row-${project.id}`}
        >
          <Grid item sm={3} xs={4}>
            <span className={classes.projectListCardsColorText}>
              {T('Payroll Region')}
            </span>
          </Grid>
          <Grid item sm={9} xs={8}>
            <img
              src={renderCountryFlag}
              alt="region flag"
              className={classes.countryFlagImageSize}
            />
            <span
              data-test-id={`Projects-region-${project.id}`}
              className={classes.countryAlignFix}
            >
              {renderCountryFlag.includes('us-flag') ? 'USA' : 'CAN'}
            </span>
          </Grid>

          <Grid item sm={3} xs={4}>
            <span className={classes.projectListCardsColorText}>
              {T('Project')}
            </span>
          </Grid>
          <Grid item sm={9} xs={8} data-test-id={`Projects-name-${project.id}`}>
            <span className={classes.projectName}>{project.name}</span>
          </Grid>

          <Grid item sm={3} xs={4}>
            <span className={classes.projectListCardsColorText}>
              {T('Production Co.')}
            </span>
          </Grid>
          <Grid item sm={9} xs={8}>
            <span
              className={classes.projecListCardsNonColorText}
              data-test-id={`Projects-productionCompany-${project.id}`}
            >
              {project.productionCompany.name}
            </span>
          </Grid>

          <Grid item sm={3} xs={4}>
            <span className={classes.projectListCardsColorText}>
              {T('projects.columns.producer')}
            </span>
          </Grid>
          <Grid
            item
            sm={9}
            xs={8}
            data-test-id={`Projects-producer-${project.id}`}
          >
            <span className={classes.projecListCardsNonColorText}>
              {project.productionCompany.producer.name}
            </span>
          </Grid>
        </Grid>
      </Paper>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <Typography
          data-test-id="Projects-title"
          variant="h4"
          className={classes.titleText}
          fontSize={26}
          fontWeight={400}
        >
          Projects{' '}
        </Typography>
        <div className={classes.inputsStyles}>
          <Grid
            container
            alignItems={'center'}
            spacing={1}
            justifyContent={'center'}
          >
            <Grid item xs={9}>
              <TextField
                id="Project-search"
                placeholder="Search"
                variant="filled"
                value={inputVal}
                onChange={onChange}
                fullWidth
                InputProps={{
                  className: classes.projectSearchInput,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon className={classes.searchIcon} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <FormControl
                variant="filled"
                size="small"
                className={classes.selectFormControlContainer}
              >
                <InputLabel htmlFor="Project-select-dropdown">Sort</InputLabel>
                <Select
                  id="Project-select-dropdown"
                  displayEmpty
                  inputProps={{
                    className: classes.projectDropdownSort,
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </div>
        {!isLoading && hasProjects && (
          <div className={classes.container}>
            <div className={classes.contentArea}>
              {projectsList?.map(renderProjectRow)}
            </div>

            {hasNextPage && !isLoading && (
              <FetchMoreProjects
                isMobile
                loadMoreProjects={fetchMoreQuery}
                searchQuery={searchTerm}
                projectsPageInfo={projectsPageInfo}
              />
            )}
          </div>
        )}
        {!isLoading && !hasProjects && (
          <div className={classes.contentArea}>
            <Typography variant="h4">Project not found</Typography>
          </div>
        )}
      </div>
    </ThemeProvider>
  );
};

export default withRouteHelpers(ProjectsList);

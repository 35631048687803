import React from 'react';
import TOOLTIP_ICON from 'common/images/tooltip.svg';
import Tooltip from '@mui/material/Tooltip';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { createOfferCurrency as currency } from 'common/oldJavascripts/utils/format';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';

const formatAmount = amount => {
  return !Number.isInteger(amount) ? currency(amount) : `$${amount}`;
};

const useStyles = makeStyles(theme => ({
  terms: {
    marginTop: '40px',
  },
  title: {
    color: '#646464',
    fontSize: '24px !important',
    fontWeight: '400 !important',
    marginBottom: '25px !important',
  },
  termsTableFooter: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))',
  },
  rowTitle: {
    color: '#5b5b5b',
    fontSize: '16px',
    fontWeight: 700,
    textTableRowansform: 'uppercase',
    backgroundColor: '#E2F1FF',
    padding: '10px',
  },
  panel: {
    backgroundColor: '#E2F1FF',
    padding: '10px',
  },
  amount: {
    color: '#0000FF !important',
    fontSize: '30px !important',
    fontWeight: '400 !important',
    padding: '18px !important',
    textAlign: 'center !important',
    border: '0.1px solid #d3f0ee',
    wordWrap: 'break-word',
    [theme.breakpoints.up('md')]: {
      padding: 11,
    },
  },
  smallerAmount: {
    color: '#0000FF',
    fontSize: '27px !important',
    fontWeight: 400,
    padding: '18px !important',
    textAlign: 'center',
    border: '0.1px solid #d3f0ee',
    wordWrap: 'break-word',
    [theme.breakpoints.up('md')]: {
      padding: 11,
    },
  },
  grey: {
    color: '#939393',
  },
  scaleTip: {
    fontSize: 10,
    color: '#939393',
  },
  tooltip: {
    marginLeft: '2px',
    position: 'absolute',
  },
  idlePayRate: {
    color: '#939393',
    fontSize: 16,
    fontWeight: 400,
    textAlign: 'center',
  },
  greenBox: {
    backgroundColor: '#E2F1FF',
    color: '#5b5b5b !important',
    fontSize: '14px !important',
    fontWeight: '700 !important',
    borderBottom: '0.1px solid #D9E3FE !important',
    textAlign: 'center !important',
    padding: '11px !important',
  },
  greenBoxNoContent: {
    borderBottom: 'none',
  },
  regularBox: {
    minHeight: 50,
    minWidth: 155,
    color: '#5b5b5b',
    fontSize: 14,
    fontWeight: 700,
    textAlign: 'center',
    borderBottom: '0 solid #D9E3FE',
  },
  distant: {
    color: '#5b5b5b !important',
    fontSize: '16px !important',
    fontWeight: '700 !important',
    textTransform: 'uppercase',
    textAlign: 'center !important',
    border: '0 solid #D9E3FE',
  },
  tableContainer: {
    marginTop: 50,
    marginBottom: 20,
  },
}));

const UnionDailyOnCallTermsDesktop = ({ terms = {} }) => {
  const classes = useStyles();

  const {
    rate_per_day_studio: dailyRate,
    rate_per_day_location: dailyRateDistant,
    guaranteed_hours_studio: guaranteedHours,
    guaranteed_hours_location: guaranteedHoursDistant,
    pay_at_scale: payAtScale,
    pay_at_scale_distant: payAtScaleDistant,
    pay_idle_days_distant_at_12th: payIdleDaysDistantAt12th,
    pay_idle_days_distant_at_scale: payIdleDaysDistantAtScale,
  } = terms;

  const isStudio = dailyRate || guaranteedHours;
  const isDistant = dailyRateDistant || guaranteedHoursDistant;
  const isLargeAmount = amount => Number(amount) >= 1000;
  const pay10th12thScaleValue = payIdleDaysDistantAtScale
    ? 'Scale Rate'
    : 'Negotiated Rate';
  const payAt10thOr12th = payIdleDaysDistantAt12th
    ? `1/12th of ${pay10th12thScaleValue}`
    : `1/10th of ${pay10th12thScaleValue}`;

  return (
    <div className={classes.terms}>
      <Typography
        className={classes.title}
        data-test-id="UnionDailyOnCallDesktop--title"
      >
        Terms of Employment
      </Typography>
      {isStudio && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                className={[classes.greenBoxNoContent, classes.greenBox].join(
                  ' ',
                )}
              />
              <TableCell
                className={classes.greenBox}
                data-test-id="UnionDailyOnCallDesktop-dailyStudioRate-title"
              >
                Daily Rate
              </TableCell>
              <TableCell
                className={classes.greenBox}
                data-test-id="UnionDailyOnCallDesktop-studioDaysHours-title"
              >
                Pension Hours Per Day
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                data-test-id="UnionDailyOnCallDesktop-studio-title"
                className={[classes.greenBox, classes.distant].join(' ')}
              >
                STUDIO{' '}
                <Tooltip
                  disableInteractive
                  title="Studio refers to a local distance within the studio zone."
                  placement="top-start"
                  className={classes.tooltip}
                >
                  <img src={TOOLTIP_ICON} alt="schedule  tooltip" />
                </Tooltip>
              </TableCell>
              <TableCell
                data-test-id="UnionDailyOnCallDesktop-hourlyStudioRate-amount"
                className={
                  isLargeAmount(dailyRate)
                    ? classes.smallerAmount
                    : classes.amount
                }
              >
                {formatAmount(dailyRate)}
                {payAtScale && (
                  <Typography className={classes.scaleTip}>
                    *Scale Rate
                  </Typography>
                )}
              </TableCell>
              {guaranteedHours && (
                <TableCell
                  data-test-id="UnionDailyOnCallDesktop-studioDaysHours"
                  className={classes.amount}
                >
                  {guaranteedHours}
                  {payAtScale && (
                    <Typography className={classes.scaleTip}>
                      *Scale Rate
                    </Typography>
                  )}
                </TableCell>
              )}
            </TableRow>
          </TableBody>
        </Table>
      )}
      {isDistant && (
        <Table className={classes.tableContainer}>
          <TableHead>
            <TableRow>
              <TableCell
                className={[classes.greenBoxNoContent, classes.greenBox].join(
                  ' ',
                )}
              />
              <TableCell className={classes.greenBox}>Daily Rate</TableCell>
              {guaranteedHoursDistant && (
                <TableCell className={classes.greenBox}>
                  Pension Hours per Day
                </TableCell>
              )}
              <TableCell className={classes.greenBox}>Idle Days pay</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                className={[classes.greenBox, classes.distant].join(' ')}
              >
                DISTANT{' '}
                <Tooltip
                  disableInteractive
                  title="Distant refers to the location outside the studio zone."
                  placement="top-start"
                  className={classes.tooltip}
                >
                  <img src={TOOLTIP_ICON} alt="schedule  tooltip" />
                </Tooltip>
              </TableCell>
              {dailyRateDistant && (
                <TableCell
                  data-test-id="UnionDailyOnCallDesktop-hourlyDistantRate-amount"
                  className={
                    isLargeAmount(dailyRateDistant)
                      ? classes.smallerAmount
                      : classes.amount
                  }
                >
                  {formatAmount(dailyRateDistant)}
                  {payAtScaleDistant && (
                    <Typography className={classes.scaleTip}>
                      *Scale Rate
                    </Typography>
                  )}
                </TableCell>
              )}
              {guaranteedHoursDistant && (
                <TableCell className={classes.amount}>
                  {guaranteedHoursDistant}
                  {payAtScaleDistant && (
                    <Typography className={classes.scaleTip}>
                      *Scale Rate
                    </Typography>
                  )}
                </TableCell>
              )}
              <TableCell className={classes.amount}>
                {payAt10thOr12th}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
      <Typography className={classes.scaleTip}>
        *Scale rate shown is as of offer creation date. Scale rate is subject to
        change upon union negotiated increases.
      </Typography>
    </div>
  );
};

export default UnionDailyOnCallTermsDesktop;

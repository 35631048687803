import React from 'react';

// HoC
import { withStyles } from '@mui/styles';
import { compose } from 'redux';
import withApi from 'common/hoc/withApi';

// Hooks
import useMe from 'common/hooks/useMe';

// Components
import { Redirect } from 'react-router';
import Loader from 'common/components/Loader';

// Utilities

const styles = _theme => ({
  root: {
    width: '100vw',
    height: '100vh',
    backgroundColor: '#e3e5eb',
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: '100%',
    gridTemplateAreas: `
    "content"
    `,
  },
  appBar: {
    gridArea: 'header',
  },
  content: {
    gridArea: 'content',
    placeSelf: 'center',
  },
});

const Home = props => {
  const { classes } = props;
  const { data: meData = {}, loading: meLoading } = useMe();

  const redirect = () => {
    const { canAccessOffers, canAccessProjects } = meData;
    if (canAccessProjects) return <Redirect push exact to="/projects" />;
    if (canAccessOffers) return <Redirect push exact to="/offers" />;
  };

  return (
    <div className={classes.root}>
      <Loader className={classes.content} fullScreen />
      {!meLoading && meData && redirect()}
    </div>
  );
};

export default compose(withApi, withStyles(styles))(Home);

import React from 'react';

// MATERIAL
import { withStyles } from '@mui/styles';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const styles = () => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingIcon: {
    width: '20px',
    height: '20px',
    marginLeft: '10px',
    color: '#0000FF',
  },
});

const FetchMoreInvitations = props => {
  const { loadMoreInvitations, pageInfo, classes, networkStatus } = props;

  const StyledButton = styled(Button)({
    padding: '11px 16px',
    margin: '0 0 20px 0',
    background: '#FFFFFF',
    border: '1px solid #0000FF',
    color: '#0000FF',
    boxSizing: 'border-box',
    boxShadow:
      '0px 1px 1px rgba(82, 82, 82, 0.1), 0px 2px 4px rgba(82, 82, 82, 0.1), 0px 1px 5px rgba(82, 82, 82, 0.08)',
    borderRadius: '2px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  });

  return (
    <div className={classes.buttonContainer}>
      <StyledButton
        variant="outlined"
        data-test-id={`invitations-load-more-button`}
        onClick={() => {
          loadMoreInvitations({
            variables: {
              after: pageInfo.endCursor,
            },
            updateQuery: (prevResults, { fetchMoreResult }) => {
              const { projectInvitations: fetchMoreInvitations = {} } =
                fetchMoreResult || {};
              const {
                nodes: fetchMoreNodes = [],
                pageInfo: invitationsPageInfo = {},
              } = fetchMoreInvitations || {};

              if (!fetchMoreNodes.length) return prevResults;

              return {
                projectInvitations: {
                  __typename: prevResults.projectInvitations.__typename,
                  nodes: [
                    ...prevResults.projectInvitations.nodes,
                    ...fetchMoreNodes.filter(
                      fetchMoreInvitationId =>
                        !prevResults.projectInvitations.nodes.some(
                          prevResultsInvitationId =>
                            prevResultsInvitationId === fetchMoreInvitationId,
                        ),
                    ),
                  ],
                  pageInfo: { ...invitationsPageInfo },
                },
              };
            },
          });
        }}
      >
        Load More{' '}
        {networkStatus === 3 && (
          <CircularProgress size="sm" className={classes.loadingIcon} />
        )}
      </StyledButton>
    </div>
  );
};

export default withStyles(styles)(FetchMoreInvitations);

import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const QUERY = gql`
  query libraryTemplates(
    $search: String
    $page: Int
    $perPage: Int
    $hidden: Boolean
    $visible: Boolean
    $mapped: Boolean
    $unmapped: Boolean
    $direction: String
    $sort: String
  ) {
    libraryTemplates(
      search: $search
      page: $page
      perPage: $perPage
      hidden: $hidden
      visible: $visible
      mapped: $mapped
      unmapped: $unmapped
      direction: $direction
      sort: $sort
    ) {
      items {
        id
        name
        visible
        status
        ready
        fieldsMapped
        readyVersion
        newerVersion
        inDraft
        createdAt
        updatedAt
        updatedBy {
          id
          extra {
            email
            fullName
          }
        }
        uploadedBy {
          id
          extra {
            email
            fullName
          }
        }
        expireOn
        expired
        categories {
          id
          name
        }
      }
      perPage
      totalPages
      totalItems
      page
    }
  }
`;

const useLibraryTemplates = variables => {
  const { error, loading, data, refetch } = useQuery(QUERY, {
    variables: variables,
    fetchPolicy: 'no-cache',
  });
  return { error, loading, data, refetch };
};

export default useLibraryTemplates;

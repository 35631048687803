import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const QUERY = gql`
  query invitations($projectId: Int!, $after: String) {
    projectInvitations(projectId: $projectId, after: $after) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
        totalCount
      }
      nodes {
        id
        projectUser {
          id
          active
          privileges
          title
          profile {
            id
            firstName
            lastName
            email
            phone
          }
        }
      }
    }
  }
`;

const useInvitations = ({ projectId, after }) => {
  const { data, ...rest } = useQuery(QUERY, {
    skip: !projectId,
    variables: { projectId: parseInt(projectId), after },
    fetchPolicy: 'network-only',
  });
  return { ...rest, data };
};

export default useInvitations;

import React, { useState } from 'react';
import { ThemeProvider, Table, TableBody } from '@mui/material';
import { withStyles } from '@mui/styles';
import { documentSignerTheme } from 'common/shared/oldDocumentSignerUI/theme';

import Toolbar from './Toolbar';
import TableHead from './TableHead';
import TableRow from './TableRow';
import RowMenu from './RowMenu';
import TableFooter from './TableFooter';

const styles = theme => ({
  header: {
    marginBottom: theme.spacing(2.6),
  },
});

const DocumentManagement = props => {
  const {
    classes,
    data,
    handleActiveFlag,
    handleEdit,
    handleOpenDocViewer,
    handleReplace,
    handleDownloadDocument,
    length,
    handleClone,
  } = props;

  const [contextMenuAnchor, setContextMenuAnchor] = useState(null);
  const [contextualMenuDoc, setContextualMenuDoc] = useState(null);

  const closeContextMenu = () => setContextMenuAnchor(null);

  const handleOpenMenu = (ev, newContextualMenuDoc) => {
    setContextMenuAnchor(ev.currentTarget);
    setContextualMenuDoc(newContextualMenuDoc);
  };

  return (
    <ThemeProvider theme={documentSignerTheme}>
      <div className={classes.root}>
        <Toolbar />
        <Table>
          <TableHead />
          <TableBody>
            {data?.map(item => (
              <TableRow item={item} handleOpenMenu={handleOpenMenu} />
            ))}
          </TableBody>
          <TableFooter length={length} />
        </Table>
        <RowMenu
          anchorEl={contextMenuAnchor}
          handleOpenDocViewer={id => {
            handleOpenDocViewer(id);
            closeContextMenu();
          }}
          handleCloseMenu={closeContextMenu}
          handleActiveFlag={id =>
            Promise.resolve(handleActiveFlag(id)).then(closeContextMenu)
          }
          handleEdit={id => {
            handleEdit(id);
            closeContextMenu();
          }}
          handleReplace={id => {
            handleReplace(id);
            closeContextMenu();
          }}
          handleClone={id => {
            handleClone(id);
            closeContextMenu();
          }}
          handleDownloadDocument={id => {
            closeContextMenu();
            handleDownloadDocument(id);
          }}
          contextualMenuDoc={contextualMenuDoc}
        />
      </div>
    </ThemeProvider>
  );
};

export default withStyles(styles)(DocumentManagement);

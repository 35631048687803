import React, { useEffect, useState } from 'react';
import { TableCell, TableRow } from '@mui/material';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import { guaranteedHoursMask } from '../../helpers/textFieldNumberMasks';
import MaskedInput from 'react-text-mask';
import Typography from '@mui/material/Typography';
import WarningIcon from '@mui/icons-material/Warning';
import formatCurrency from 'studio/components/OfferPage/helpers/formatCurrency';
import classNames from 'class-names';
import {
  OMITTED_SCHEDULEA_UNIONS,
  SCHEDULE_LIMIT_HOURS,
} from 'common/utilities/constants/noScaleUnions';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: 40,
    marginBottom: 40,
  },
  terms: {
    marginTop: 10,
  },
  innerCell: {
    borderTop: 'none',
  },
  cellTitle: {
    backgroundColor: '#f9fdff',
    color: '#0000FF',
    fontSize: 14,
    fontWeight: 'bold',
    border: '2px solid #BECFFE',
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 8,
  },
  itemContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  radioContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  radioGroupCell: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    borderBottom: 'none',
  },
  dollar: {
    width: '10%',
    fontSize: 14,
    marginTop: 7,
    color: '#696969',
    paddingLeft: 14,
    fontFamily: 'Roboto, Helvetica, Helvetica Neue, san-serif',
  },
  tableSpacing: {
    marginTop: 16,
    marginBottom: 20,
    border: '1px solid #0000FF',
  },
  topSpacing: {
    marginTop: 20,
  },
  hoursCopy: {
    marginTop: 7,
    paddingRight: 12,
    color: '#525252',
    fontSize: 16,
    fontWeight: 'normal',
  },
  errorIcon: {
    color: 'red',
    position: 'relative',
    top: 4,
    right: 8,
  },
  errorLabel: {
    color: 'red',
    fontSize: 14,
    textAlign: 'center',
  },
  warningIcon: {
    color: '#FFA800',
    position: 'relative',
    top: 4,
    right: 8,
  },
  warningLabel: {
    color: '#FFA800',
    fontSize: 14,
    textAlign: 'center',
    maxWidth: '65%',
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  hoursPerDay: {
    backgroundColor: '#f9fdff',
    color: '#0000FF',
    fontSize: 14,
    fontWeight: 'bold',
  },
  rightCellArea: {
    width: '30%',
    backgroundColor: '#FFFFFF',
    color: '#0000FF',
    fontSize: 14,
    fontWeight: 'bold',
    border: '2px solid #BECFFE',
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 8,
  },
  fieldArea: {
    width: '40%',
    backgroundColor: '#FFFFFF!important',
  },
  noTop: {
    borderTop: 'none',
  },
  inputSpacing: {
    marginLeft: '5%',
    marginRight: '10%',
  },
  label: {
    color: '#262626',
    fontSize: 16,
  },
  disabledSection: {
    backgroundColor: '#F4F4F4',
  },
  errorUnderline: {
    width: 196,
    color: '#696969',
    '&:before': {
      borderBottom: `1px solid red`,
    },
    '&$focused:not($disabled):before': {
      borderBottom: `2px solid red`,
    },
    '&:after': {
      borderBottom: 'none',
    },
    '&:hover:not($disabled):before': {
      borderBottom: `2px solid red`,
    },
    '&$error:not($disabled):before': {
      borderBottom: `1px solid red`,
    },
  },
}));
const LOW_DAILY_HOURS = 'Is this correct for Daily hours?';
const HIGH_DAILY_HOURS = 'Is this correct for Dailly hours?';
const BELOW_SCALE = 'Hours Per Day below scale';
const { onDailyMaxHours, onDailyMinHours } = SCHEDULE_LIMIT_HOURS;

const getHoursWarningMessage = (scalesInfo, isReadOnly) => {
  if (isReadOnly) return;
  let warningMessage;
  if (scalesInfo?.isAboveEightyFourHours) {
    warningMessage = HIGH_DAILY_HOURS;
  } else if (scalesInfo?.isBelowFourtyHours) {
    warningMessage = LOW_DAILY_HOURS;
  } else if (scalesInfo?.isBelowScale) {
    warningMessage = BELOW_SCALE;
  }
  return warningMessage;
};

const scaleStates = (
  guaranteedHours,
  guaranteedHoursDistant,
  guaranteedHoursScaleStudio,
  guaranteedHoursScaleDistant,
) => {
  return {
    isAboveEightyFourHours:
      !guaranteedHoursScaleStudio &&
      (guaranteedHours > onDailyMaxHours ||
        guaranteedHoursDistant > onDailyMaxHours),
    isBelowFourtyHours:
      !guaranteedHoursScaleDistant &&
      (guaranteedHours < onDailyMinHours ||
        guaranteedHoursDistant < onDailyMinHours),
    isBelowScale:
      guaranteedHoursScaleStudio > guaranteedHours ||
      guaranteedHoursScaleDistant > guaranteedHoursDistant,
  };
};

const InputField = props => {
  const classes = useStyles();
  const {
    guaranteedHours,
    guaranteedHoursDistant,
    guaranteedHoursScaleStudio,
    guaranteedHoursScaleDistant,
    payAtScale,
    payAtScaleDistant,
    onChange: upstreamOnChange,
    formErrors,
    formData,
    isReadOnly,
    unionCode,
  } = props;

  const isOmittedUnions = OMITTED_SCHEDULEA_UNIONS.includes(
    formData?.union?.code || unionCode,
  );
  const formattedGuaranteedHours = guaranteedHours
    ? formatCurrency(guaranteedHours)
    : null;
  const formattedGuaranteedHoursDistant = guaranteedHoursDistant
    ? formatCurrency(guaranteedHoursDistant)
    : null;

  const [studioHourly, setStudioHourly] = useState(formattedGuaranteedHours);
  const [distantHourly, setDistantHourly] = useState(
    formattedGuaranteedHoursDistant,
  );

  useEffect(() => {
    setStudioHourly(guaranteedHours);
    setDistantHourly(guaranteedHoursDistant);
  }, [guaranteedHours, guaranteedHoursDistant]);

  const scaleStatesInfo = scaleStates(
    guaranteedHours,
    guaranteedHoursDistant,
    guaranteedHoursScaleStudio,
    guaranteedHoursScaleDistant,
  );
  const warningMessage = getHoursWarningMessage(scaleStatesInfo, isReadOnly);

  const getTypedValue = (ev = {}) => {
    const { target: { value: rawValue } = {} } = ev;
    let value = rawValue;
    if (rawValue === '' || rawValue === '0') value = '';
    return parseFloat(value, 10);
  };

  const onChangeStudio = ev => {
    const typedValue = getTypedValue(ev);
    let value = typedValue > 0 ? formatCurrency(typedValue, true) : null;
    setStudioHourly(value);
    handleAutoScaleUpdate(value, 'studio');
  };

  const onChangeDistant = ev => {
    const typedValue = getTypedValue(ev);
    let value = typedValue > 0 ? formatCurrency(typedValue, true) : null;
    setDistantHourly(value);
    handleAutoScaleUpdate(value, 'distant');
  };

  const onBlurStudio = ev => {
    let blurValue;
    const typedValue = getTypedValue(ev);

    if (studioHourly) {
      blurValue = studioHourly;
      upstreamOnChange({
        guaranteedHours: blurValue,
        guaranteedHoursDistant: blurValue,
        payAtScaleDistant: false,
        idleAtScaleDistant: false,
      });
    }

    if (studioHourly && !distantHourly) {
      blurValue = studioHourly;
      upstreamOnChange({
        guaranteedHoursDistant: blurValue,
      });
    }

    if ((!typedValue && distantHourly) || (!studioHourly && distantHourly)) {
      blurValue = distantHourly;
      upstreamOnChange({
        guaranteedHours: blurValue,
      });
    }
    setStudioHourly(blurValue);
  };

  const onBlurDistant = ev => {
    let blurValue;
    const typedValue = getTypedValue(ev);
    if (distantHourly) {
      blurValue = distantHourly;
      upstreamOnChange({
        guaranteedHoursDistant: blurValue,
      });
    }

    if (distantHourly && !studioHourly) {
      blurValue = distantHourly;
      upstreamOnChange({
        guaranteedHours: blurValue,
      });
    }

    if ((!typedValue && studioHourly) || (!distantHourly && studioHourly)) {
      blurValue = studioHourly;
      upstreamOnChange({
        guaranteedHoursDistant: blurValue,
      });
    }

    setDistantHourly(blurValue);
  };

  const handleAutoScaleUpdate = (value, type) => {
    if (type === 'studio') {
      const studioValue =
        guaranteedHoursScaleStudio > value
          ? false
          : guaranteedHoursScaleStudio > value;
      upstreamOnChange({
        payAtScale: studioValue,
      });
    } else {
      const distantValue =
        guaranteedHoursScaleDistant > value
          ? false
          : guaranteedHoursScaleDistant > value;
      upstreamOnChange({
        payAtScaleDistant: distantValue,
      });
    }
    upstreamOnChange({
      isTermsOfEmploymentV2: true,
    });
  };

  const isStudioWarning =
    warningMessage &&
    (studioHourly > onDailyMaxHours ||
      studioHourly < onDailyMinHours ||
      studioHourly < guaranteedHoursScaleStudio);
  const isDistantWarning =
    warningMessage &&
    (distantHourly > onDailyMaxHours ||
      distantHourly < onDailyMinHours ||
      distantHourly < guaranteedHoursScaleDistant);
  const isWarning = isStudioWarning || isDistantWarning;

  const isStudioDisabled =
    isOmittedUnions ||
    !guaranteedHoursScaleStudio ||
    payAtScale ||
    isReadOnly ||
    guaranteedHours;
  const isDistantDisabled =
    isOmittedUnions ||
    !guaranteedHoursScaleDistant ||
    payAtScaleDistant ||
    isReadOnly ||
    guaranteedHoursDistant;

  return (
    <TableRow>
      <TableCell className={classes.cellTitle}>
        <div className={classes.errorContainer}>
          <Typography
            className={classes.hoursPerDay}
            data-test-id="oncall-row-title"
          >
            Pension Hours Per Day
          </Typography>
          {isWarning && (
            <Typography
              color="warning"
              variant="caption"
              className={classes.warningLabel}
              data-test-id="oncall-InputField-warning-message"
            >
              <WarningIcon className={classes.warningIcon} />
              {warningMessage}
            </Typography>
          )}
          {formErrors?.guaranteedHours && (
            <Typography
              color="warning"
              variant="caption"
              className={classes.errorLabel}
              data-test-id="oncall-InputField-error-message"
            >
              <WarningIcon className={classes.errorIcon} />
              {formErrors?.guaranteedHours}
            </Typography>
          )}
        </div>
      </TableCell>
      <TableCell
        className={classNames(classes.rightCellArea, {
          [classes.disabledSection]: isStudioDisabled,
        })}
      >
        <div className={classes.itemContainer}>
          <Typography className={classes.dollar}></Typography>
          <MaskedInput
            mask={guaranteedHoursMask}
            value={studioHourly || ''}
            onChange={onChangeStudio}
            onBlur={onBlurStudio}
            className={classes.inputSpacing}
            error={isStudioWarning}
            render={(ref, props) => (
              <TextField
                inputRef={ref}
                {...props}
                variant="standard"
                disabled={isStudioDisabled}
                InputProps={{
                  className: formErrors?.guaranteedHours
                    ? classes.errorUnderline
                    : '',
                  inputProps: {
                    'data-test-id': `union-hours-per-day-Studio-input`,
                  },
                }}
              />
            )}
          />
          <Typography className={classes.hoursCopy}>
            <span>hours</span>
          </Typography>
        </div>
      </TableCell>
      <TableCell
        className={classNames(classes.rightCellArea, {
          [classes.disabledSection]: isDistantDisabled,
        })}
      >
        <div className={classes.itemContainer}>
          <Typography className={classes.dollar}></Typography>
          <MaskedInput
            mask={guaranteedHoursMask}
            value={distantHourly || ''}
            onChange={onChangeDistant}
            onBlur={onBlurDistant}
            className={classes.inputSpacing}
            error={isDistantWarning}
            render={(ref, props) => (
              <TextField
                inputRef={ref}
                {...props}
                variant="standard"
                disabled={isDistantDisabled}
                InputProps={{
                  className: formErrors?.guaranteedHoursDistant
                    ? classes.errorUnderline
                    : '',
                  inputProps: {
                    'data-test-id': `union-hours-per-day-Distant-input`,
                  },
                }}
              />
            )}
          />
          <Typography className={classes.hoursCopy}>
            <span>hours</span>
          </Typography>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default InputField;

import AllowancesRow from 'common/oldJavascripts/components/Shared/AllowancesRow';
import Badge from 'common/oldJavascripts/components/Base/Badge';
import Field from 'common/oldJavascripts/components/Base/Field';
import T from 'common/oldJavascripts/utils/i18n';
import Link from 'common/oldJavascripts/components/Base/Link';
import RateCell from '../Offers/RateCell';

import React from 'react';
import Relay from 'common/oldJavascripts/utils/react/Relay.js';
import format from 'common/oldJavascripts/utils/format.js';
import OfferStatus from 'common/oldJavascripts/components/Shared/OfferStatus';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@mui/styles';
import ExpandableTableRow from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRow';
import ExpandableTableCell from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableCell';
import ExpandableTableCellTitle from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableCellTitle';
import nonUnionTermsOfEmployment from 'studio/components/ManageOffers/utilities/nonUnionTermsOfEmployment/nonUnionTermsOfEmployment';

const Cell = ExpandableTableCell;
const CellTitle = ExpandableTableCellTitle;
const Row = ExpandableTableRow;

const styles = () => ({
  overflowText: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
});

const ReviewRow = Relay.createClass({
  render() {
    const {
      offer = {},
      checked,
      expanded,
      classes = {},
      'data-test-id': dataTestId,
    } = this.props;
    const profile = offer?.projectUser?.profile;
    const { firstName = '', middleName = '', lastName = '', email = '' } =
      profile || {};
    const fullName = format.fullName(firstName, middleName, lastName);
    const terms = offer?.termsOfEmployment;
    const isSAGUnion =
      offer?.termsOfEmploymentV2?.__typename === 'TermsOfEmploymentUnionSag';

    const rateCellInfo = offer?.isNonUnion
      ? nonUnionTermsOfEmployment(offer)
      : isSAGUnion
      ? {
          id: offer?.id,
          terms_of_employment: {
            sagRate: offer?.termsOfEmploymentV2?.rate,
          },
        }
      : {
          id: offer?.id,
          terms_of_employment: {
            rate_per_hour_studio:
              terms?.studioRatePerHour || terms?.ratePerHour,
            rate_per_hour_location: terms?.locationRatePerHour,
            rate_per_day_studio: terms?.studioRatePerDay || terms?.dailyRate,
            rate_per_day_location: terms?.locationRatePerDay,
            rate_per_week_studio: terms?.studioRatePerWeek || terms?.weeklyRate,
            rate_per_week_location: terms?.locationRatePerWeek,
          },
          scaleRate: offer?.scaleRate,
          locationScaleRate: offer?.locationScaleRate,
          union_code: offer?.termsOfHire?.union?.code,
        };

    return (
      <Row
        key={offer.id}
        onClick={this._toggleSelect}
        data-test-id={dataTestId}
      >
        <Cell>
          <Field
            hideLabel={true}
            inline={true}
            label={T('project.reviews.select_offer', { user: fullName })}
            onChange={this._handleSelect}
            type="checkbox"
            value={checked}
          />
        </Cell>
        <Cell>
          <CellTitle>{fullName}</CellTitle>
          <Tooltip disableInteractive title={email} enterDelay={100}>
            <div className={classes.overflowText}>{email}</div>
          </Tooltip>
        </Cell>
        <Cell>
          <CellTitle>{offer?.termsOfHire?.department?.name}</CellTitle>
        </Cell>
        <Cell>
          {offer?.termsOfHire?.occupation?.description}
          <br />
          {offer?.accountCode}
        </Cell>
        <Cell>{offer?.termsOfHire?.union?.description}</Cell>
        <Cell>{format.date(offer?.startDate)}</Cell>
        {this._renderEndDateCell(offer)}
        <RateCell offer={rateCellInfo} />
        <Cell expanded={expanded} align="center">
          <AllowancesRow.Icon
            offer={offer}
            onClick={() => this._toggleAllowances(offer)}
          />
        </Cell>
        <Cell>{this._renderStatusBadge(offer)}</Cell>
        <Cell>{this._renderDeleteButton()}</Cell>
      </Row>
    );
  },

  _handleSelect: function(value) {
    const { onSelect, params: { id } = {} } = this.props;
    onSelect(id, value);
  },

  _renderDeleteButton() {
    const { routerParams = {}, offer = {} } = this.props;
    const reviewId = offer?.id;
    const reviewIsSent = offer?.status === 'draft_review';
    const { projectId } = routerParams || {};
    if (!reviewIsSent) return null;
    return (
      <Link
        icon={true}
        to={`/projects/${projectId}/reviews/${reviewId}/delete`}
      >
        <DeleteIcon />
      </Link>
    );
  },

  _renderEndDateCell(item) {
    if (item?.endDate) {
      return <Cell>{format.date(item?.endDate)}</Cell>;
    }
  },

  _renderStatusBadge(offer) {
    if (offer?.status) {
      return <OfferStatus status={offer?.status} />;
    }

    if (offer?.reviewedAt) {
      return <Badge success={true}>Reviewed</Badge>;
    }

    return <Badge warning={true}>Not Reviewed</Badge>;
  },

  _toggleAllowance() {
    this.setState({
      allowanceExpanded: !this.state.allowanceExpanded,
    });
  },

  _toggleAllowances(offer) {
    this.props.onAllowanceToggle(offer?.id);
  },

  _toggleSelect() {
    this._handleSelect(!this.props.checked);
  },
});

export default withStyles(styles)(ReviewRow);

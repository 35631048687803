import React, { useState } from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { withStyles } from '@mui/styles';
import STARTPLUS_LOGO from 'common/images/start-white-logo-01.svg';
import { ChevronRight } from '@mui/icons-material';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ThumbsUpImg from 'common/images/thumbsUp.svg';
import MobileHomeIcon from 'common/images/MobileHomeIcon.svg';
import ClackerIcon from 'common/images/Clacker.svg';
import MenuIcon from 'common/images/menuIcon.svg';
import AppBar from '@mui/material/AppBar';
import { MyCnCHeader } from '@castandcrew/global-navbar';
import MyCnCHeaderConfig from 'common/constants/config/MyCnCHeaderConfig';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { logout } from 'common/store/actions/authenticate';
import withApi from 'common/hoc/withApi';
import { withCookies } from 'react-cookie';
import { Button, Divider, Typography } from '@mui/material';
import history from 'common/constants/config/history';
import { styled } from '@mui/system';

const styles = {
  root: {
    width: '100%',
    paddingBottom: '50px',
  },
  appBarRoot: {
    zIndex: '1000 !important',
  },
  logo: {
    marginLeft: 'auto',
    height: '14%',
    width: '14%',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    padding: 0,
  },
  list: {
    width: '70vw',
    padding: '15px',
  },
  fullList: {
    width: 'auto',
  },
  drawer: {
    width: '60%',
  },
  drawerPaper: {
    background: '#ffffff ',
    width: '80%',
    padding: '15px 8px',
    justifyContent: 'space-between',
  },
  dashBoardItemContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '42%',
  },
  resourceContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '61%',
  },
  contactsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '36%',
  },
  myOffersContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '33%',
  },
  menuItemContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '40%',
  },
  MenuItemTitle: {
    fontWeight: 'bold !important',
    textTransform: 'none',
  },
  backTitle: {
    width: 30,
    height: 17,
    color: 'white',
    fontSize: '12px',
    fontWeight: 400,
    bottom: '16px',
    position: 'relative',
  },
  profile: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '20px',
    marginBottom: '20px',
  },
  personIcon: {
    width: 50,
    height: 50,
    color: 'white',
  },
  profileName: {
    color: 'white',
    fontSize: 14,
    fontWeight: 500,
  },
  profileEmail: {
    color: 'white',
    fontSize: 12,
    fontWeight: 300,
  },
  logout: {
    color: 'white',
    fontSize: 14,
    fontWeight: 500,
    margin: '20px',
  },
  link: {
    width: '90%',
  },
  chevron: {
    float: 'right',
    color: '#344054',
  },
  menuIcon: {
    width: '25px',
    height: '25px',
    float: 'right',
  },
  menuList: {
    '& li:last-child': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
  },
  childrenContainer: {
    flexGrow: 1,
  },
};

const DashboardMenuItem = styled('a')({
  color: '#344054',
  backgroundColor: 'white',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: 14,
  height: 50,
  borderRadius: '6px',
  padding: '8px',
  textDecoration: 'none',
});

const StartPlusTitleContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '8px',
  width: '100%',
  color: '#344054',
  backgroundColor: 'white',
  margin: '5px 0px',
  textTransform: 'none',
});

const MenuItem = styled(Button)(({ active }) => ({
  color: '#344054 !important',
  backgroundColor: active ? '#F0F9FF !important' : 'white !important',
  width: '97%',
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: 14,
  height: 45,
  borderRadius: '6px',
  padding: '8px',
  marginLeft: '5px',
  '& a': {
    textDecoration: 'none',
  },
}));

const ProjectTitleContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: '8px',
  width: '100%',
  color: '#344054',
  backgroundColor: 'white',
  borderTop: '1px solid rgba(0, 0, 0, 0.12)',
});

const MenuItemContainer = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const HamburgerMenu = props => {
  const {
    children,
    classes,
    nonEmployee,
    projectTitle,
    projectId,
    location: { pathname = '' },
    routerParams: { offerId } = {},
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={classes.root}>
      <AppBar position="fixed" classes={{ root: classes.appBarRoot }}>
        <MyCnCHeader configs={MyCnCHeaderConfig} />
        <Toolbar
          sx={{
            background: 'linear-gradient(180deg, #0000b3 0%, #00004c 100%)',
          }}
        >
          <IconButton
            className={classes.menuButton}
            color="inherit"
            data-test-id="hamburger-menu-icon"
            onClick={() => setIsOpen(true)}
            aria-label="Open drawer"
          >
            <img src={MenuIcon} alt="Menu Icon" />
          </IconButton>
          <img src={STARTPLUS_LOGO} className={classes.logo} alt="Start+" />
        </Toolbar>
        <div className={classes.childrenContainer}>{children}</div>
      </AppBar>

      <SwipeableDrawer
        className={classes.drawer}
        anchor="left"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        classes={{ paper: classes.drawerPaper }}
        data-test-id="hamburger-menu-drawer"
      >
        <div>
          <DashboardMenuItem href={process.env.REACT_APP_SSO_URL}>
            <div className={classes.dashBoardItemContainer}>
              <img src={MobileHomeIcon} alt="Home Icon" />
              <Typography className={classes.MenuItemTitle}>
                My Dashboard
              </Typography>
            </div>
          </DashboardMenuItem>
          <Divider />
          <StartPlusTitleContainer>
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
              Start+
            </Typography>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography>Onboarding</Typography>
              <KeyboardArrowDownIcon />
            </div>
          </StartPlusTitleContainer>
          {!nonEmployee && (
            <MenuItem
              active={!pathname.includes('help')}
              onClick={() => {
                history.push('/onboarding');
                setIsOpen(false);
              }}
            >
              <MenuItemContainer>
                <div className={classes.myOffersContent}>
                  <img src={ThumbsUpImg} alt="Home Icon" />
                  <Typography className={classes.MenuItemTitle}>
                    My Offers
                  </Typography>
                </div>
              </MenuItemContainer>
            </MenuItem>
          )}
          {nonEmployee && (
            <>
              <Divider />
              <MenuItem
                active={pathname === '/projects'}
                onClick={() => {
                  history.push('/projects');
                  setIsOpen(false);
                }}
                data-test-id="hamburger-menu-projects"
              >
                <MenuItemContainer>
                  <div className={classes.menuItemContent}>
                    <img src={ClackerIcon} alt="Clacker Icon" />
                    <Typography className={classes.MenuItemTitle}>
                      All Projects
                    </Typography>
                  </div>
                </MenuItemContainer>
              </MenuItem>
              {projectTitle && (
                <>
                  <ProjectTitleContainer>
                    <Typography
                      sx={{ marginLeft: '10px', fontWeight: 'bold' }}
                      className={classes.MenuItemTitle}
                    >
                      {projectTitle}
                    </Typography>
                  </ProjectTitleContainer>
                  <MenuItem
                    active={pathname.includes('offers')}
                    onClick={() => {
                      history.push(`/projects/${projectId}/offers`);
                      setIsOpen(false);
                    }}
                  >
                    <MenuItemContainer>
                      <Typography
                        sx={{ marginLeft: '20px', fontWeight: 'bold' }}
                        className={classes.MenuItemTitle}
                      >
                        Approvals
                      </Typography>
                      <ChevronRight className={classes.chevron} />
                    </MenuItemContainer>
                  </MenuItem>
                </>
              )}
            </>
          )}
        </div>
        <div>
          {offerId && (
            <MenuItem
              active={pathname.includes('help')}
              onClick={() => {
                history.push(`/onboarding/offers/${offerId}/help`);
                setIsOpen(false);
              }}
            >
              <MenuItemContainer>
                <div className={classes.menuItemContent}>
                  <MailOutlineOutlinedIcon sx={{ fontSize: '26px' }} />
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '16px',
                    }}
                    className={classes.MenuItemTitle}
                  >
                    Contacts
                  </Typography>
                </div>
                <ChevronRight className={classes.chevron} />
              </MenuItemContainer>
            </MenuItem>
          )}
          <MenuItem>
            <MenuItemContainer id="Resource-center">
              <div className={classes.resourceContainer}>
                <HelpOutlineOutlinedIcon sx={{ fontSize: '26px' }} />
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '16px',
                  }}
                  className={classes.MenuItemTitle}
                >
                  Resource Center
                </Typography>
              </div>
              <ChevronRight className={classes.chevron} />
            </MenuItemContainer>
          </MenuItem>
        </div>
      </SwipeableDrawer>
    </div>
  );
};

const mapDispatchToProps = {
  logout,
};
export default compose(
  withApi,
  withCookies,
  withStyles(styles),
  connect(null, mapDispatchToProps),
)(HamburgerMenu);

import React, { useState } from 'react';
import { compose } from 'redux';
import { graphql } from 'react-apollo';
import { withStyles, ThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material';
import {
  Close as CloseIcon,
  Done as SelectedIcon,
  PersonAdd as UnselectedIcon,
} from '@mui/icons-material';
import * as Queries from 'common/apollo/queries';
import withRouteHelpers from 'common/hoc/withRouteHelpers';
import toggleInList from 'common/utilities/toggleInList';
import * as colors from 'common/components/CnCUi/theme/colors';
import { styled } from '@mui/system';

const styles = {
  btn: {
    height: 35,
    borderRadius: '2px',
  },
  userList: {
    height: 400,
    overflowY: 'scroll',
  },
  listItem: {
    cursor: 'pointer',
  },
  tick: {
    color: colors.GREEN,
  },
  tooltip: {
    fontSize: '1rem',
  },
};

const theme = createTheme({
  palette: {
    primary: {
      main: '#0000FF',
    },
    action: {
      disabledBackground: '#9A9FAF',
    },
  },
  overrides: {
    MuiCheckbox: {
      colorSecondary: {
        '&$checked': {
          color: '#FFA800',
        },
      },
    },
  },
});

const StyledCloseButton = styled(IconButton)({
  height: 35,
  borderRadius: '2px',
  position: 'absolute',
  right: 8,
  top: 8,
});

const ProjectTemplateWorkflowApproversSelector = props => {
  const {
    classes,
    loading,
    onClose,
    onSubmit,
    open,
    projectApprovers,
    defaultSelectedApproverIds = [],
    sortList,
  } = props;

  const [selectedApproverIds, setSelectedApproverIds] = useState(
    defaultSelectedApproverIds,
  );

  const isAllOptionsSelected =
    selectedApproverIds.length === projectApprovers.length;

  const approverOption = approver => {
    const {
      id,
      profile: { firstName, lastName, email },
    } = approver;
    const name = `${firstName} ${lastName}`;
    const isSelected = selectedApproverIds.includes(id);
    return { id, email, name, isSelected };
  };

  const toggleSelectAll = () =>
    setSelectedApproverIds(
      isAllOptionsSelected
        ? []
        : sortList(projectApprovers).map(({ id }) => id),
    );

  const submit = () => {
    const selectedApprovers = selectedApproverIds.map(id =>
      projectApprovers.find(u => u.id === id),
    );
    onSubmit(selectedApprovers);
    onClose();
  };

  if (loading) return null;

  return (
    <ThemeProvider theme={theme}>
      <Dialog onClose={onClose} open={open}>
        <DialogTitle data-test-id="ProjectTemplateWorkflowApproversSelector-title">
          {defaultSelectedApproverIds.length ? 'Edit' : 'Add'} Approvers
          <StyledCloseButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </StyledCloseButton>
        </DialogTitle>
        <DialogContent>
          <FormControlLabel
            control={
              <Checkbox
                checked={isAllOptionsSelected}
                onChange={toggleSelectAll}
                data-test-id="ProjectTemplateWorkflowApproversSelector-selectAll"
                color="secondary"
              />
            }
            label="Select all"
          />
          <List className={classes.userList}>
            {sortList(projectApprovers)
              .map(approverOption)
              .map(({ id, email, name, isSelected }) => (
                <Tooltip
                  disableInteractive
                  title={email}
                  classes={{ tooltip: classes.tooltip }}
                  placement="bottom"
                  arrow
                >
                  <ListItem
                    onClick={() =>
                      setSelectedApproverIds(
                        toggleInList(selectedApproverIds, id),
                      )
                    }
                    key={id}
                    className={classes.listItem}
                  >
                    <ListItemIcon>
                      {isSelected ? (
                        <SelectedIcon
                          className={classes.tick}
                          data-test-id={`ProjectTemplateWorkflowApproversSelector-selectedUser-${id}`}
                        />
                      ) : (
                        <UnselectedIcon
                          data-test-id={`ProjectTemplateWorkflowApproversSelector-unselectedUser-${id}`}
                        />
                      )}
                    </ListItemIcon>
                    <ListItemText primary={name} />
                  </ListItem>
                </Tooltip>
              ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} className={classes.btn} variant="outlined">
            Cancel
          </Button>
          <Button
            disabled={!selectedApproverIds.length}
            className={classes.btn}
            onClick={submit}
            color="primary"
            variant="contained"
            data-test-id="ProjectTemplateWorkflowApproversSelector-submit"
          >
            {defaultSelectedApproverIds.length ? 'Update' : 'Add'} Approvers
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

const withProjectApprovers = graphql(Queries.projectUsers, {
  options: ({ isTemplateI9, projectId }) => ({
    variables: {
      id: projectId,
      userRole: isTemplateI9 ? 'i9_verifier' : 'approver',
    },
    // Always go to the network
    // TODO Once invitation page is graphql we can replace this with
    // refetchQueries on the mutator.
    fetchPolicy: 'cache-and-network',
  }),
  props: ({
    data: { loading, project: { users: projectApprovers = [] } = {} },
  }) => ({
    loading,
    projectApprovers,
  }),
});

export default compose(
  withRouteHelpers,
  withProjectApprovers,
  withStyles(styles),
)(ProjectTemplateWorkflowApproversSelector);

import React from 'react';
import { withStyles } from '@mui/styles';

// Utitlities
import getSSOUrl from 'common/constants/config/ssoUrl';

// HoC
import { compose } from 'redux';
import { connect } from 'react-redux';
import withApi from 'common/hoc/withApi';

// Actions
import { logout } from 'common/store/actions/authenticate';

// Components
import Dropdown from '../../../Base/Dropdown';

// Hooks
import useMe from 'common/hooks/useMe';

const URL_TRAINING = 'https://support.castandcrew.com/';
const URL_UNIVERSITY = 'https://edge.castandcrew.com/learning/cc';

const styles = _theme => ({
  dropDownLink: {
    textDecoration: 'none',
    display: 'block',
  },
});

const DropdownLink = withStyles(styles)(props => {
  const { href, label, classes, external = false } = props;
  const otherProps = {};

  if (external) {
    otherProps.target = '_blank';
    otherProps.rel = 'noopener noreferrer';
  }

  return (
    <a href={href} className={classes.dropDownLink} {...otherProps}>
      {label}
    </a>
  );
});

const DropdownSession = props => {
  const { logout } = props;

  const { data: me = {}, loading: meLoading } = useMe();
  if (meLoading) return null;

  const {
    email,
    firstName,
    isEmployee,
    isPasswordExpired,
    isUpdatePasswordRequired,
    lastName,
  } = me;

  const profileUrl = isEmployee ? `${getSSOUrl()}/profile` : '/profile';

  return (
    <Dropdown
      title={[firstName, lastName].join(' ')}
      avatarText={[firstName, lastName].join('')}
      header={true}
      data-test-id="DropdownSession-root"
    >
      <Dropdown.Item key="profile-container" container={true}>
        <Dropdown.Title secondary={true}>{email}</Dropdown.Title>
      </Dropdown.Item>
      {!isUpdatePasswordRequired && !isPasswordExpired && (
        <Dropdown.Item key="profile">
          <DropdownLink href={profileUrl} label="Profile" />
        </Dropdown.Item>
      )}
      {isEmployee && (
        <Dropdown.Item key="notifications">
          <DropdownLink href="/notifications" label="Notifications" />
        </Dropdown.Item>
      )}
      <Dropdown.Item key="mycnc">
        <DropdownLink href={getSSOUrl()} label="MyCast&amp;Crew" />
      </Dropdown.Item>
      <Dropdown.Item key="help-center">
        <DropdownLink label="Help Center" href={URL_TRAINING} external />
      </Dropdown.Item>
      <Dropdown.Item key="university-training">
        <DropdownLink
          href={URL_UNIVERSITY}
          label="C&amp;C Edge Learning"
          external
        />
      </Dropdown.Item>
      <Dropdown.Item key="sign-out" onClick={() => logout()}>
        Sign Out
      </Dropdown.Item>
    </Dropdown>
  );
};

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  logout,
};

export default compose(
  withApi,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(DropdownSession);

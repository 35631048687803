import React from 'react';
import withApi from 'common/hoc/withApi';
import MainHeader from '../../../Base/MainHeader';
import DOMPurify from 'dompurify';

import useMe from 'common/hooks/useMe';

const MainNav = () => {
  const { data, loading: meIsLoading } = useMe();
  const meData = data || {};
  DOMPurify.sanitize(meData);
  const {
    isAdmin,
    isSuperAdmin,
    isProdcoAdmin = false,
    canAccessProjects = false,
    canAccessOffers = false,
  } = meData || {};

  if (meIsLoading) return null;

  const libraryLinkTo = `library${isProdcoAdmin ? '-fields' : ''}`;

  return (
    <MainHeader.Nav>
      {canAccessProjects && (
        <MainHeader.Item
          key="projects"
          to="projects"
          data-test-id="MainNav-projects"
        >
          Projects
        </MainHeader.Item>
      )}
      {isAdmin && (
        <MainHeader.Item
          key="library"
          to={libraryLinkTo}
          data-test-id="MainNav-library"
        >
          Library
        </MainHeader.Item>
      )}
      {isSuperAdmin && (
        <MainHeader.Item
          key="global-permissions"
          to="global-permissions"
          data-test-id="MainNav-adminUsers"
        >
          Admin Users
        </MainHeader.Item>
      )}
      {canAccessOffers && (
        <MainHeader.Item
          key="offers"
          to="offers"
          data-test-id="MainNav-myOffers"
        >
          My Offers
        </MainHeader.Item>
      )}
      {(isSuperAdmin || isAdmin) && (
        <MainHeader.Item key="tools" to="tools" data-test-id="MainNav-tools">
          Tools
        </MainHeader.Item>
      )}
    </MainHeader.Nav>
  );
};

export default withApi(MainNav);

export const OMITTED_SCHEDULEA_UNIONS = ['399CAS', '817MPI'];
export const DEFAULT_HOURS = 65;
export const DEFAULT_DAILY_HOURS = 13;
export const NOSCALE_UNIONWEEKLY_DEFAULTHOURS = {
  guaranteedHours: DEFAULT_HOURS,
  guaranteedHoursDistant: DEFAULT_HOURS,
};
const ON_WEEKLY_MAX_HOURS = 84;
const ON_WEEKLY_MIN_HOURS = 40;
const WEEKLY_MAX_HOURS = 80;
const DAILY_MAX_HOURS = 16;
export const SCHEDULE_LIMIT_HOURS = {
  onWeeklyMaxHours: ON_WEEKLY_MAX_HOURS,
  onWeeklyMinHours: ON_WEEKLY_MIN_HOURS,
  weeklyMaxHours: WEEKLY_MAX_HOURS,
  dailyMaxHours: DAILY_MAX_HOURS,
};

import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const QUERY = gql`
  query projectReviews(
    $id: Int!
    $after: String
    $sort: String
    $direction: String
    $keyword: String
  ) {
    project(id: $id) {
      id
      privileges
      accountMask
      country {
        id
        code
        name
      }
      offerDefaults {
        enforceMask
        allowEndDate
      }
      projectReviews(
        after: $after
        sort: $sort
        direction: $direction
        keyword: $keyword
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
          totalCount
        }
        nodes {
          id
          status
          isTermsOfEmploymentV2
          badges
          reimbursementsUpdated
          jobTitle
          projectUser {
            id
            profile {
              id
              firstName
              middleName
              lastName
              email
              phone
              fullName
            }
          }
          startDate
          sendDate
          sentAt
          endDate
          scaleRate
          keepI9
          locationScaleRate
          termsOfHire {
            subDepartment {
              id
              name
            }
            season
            hireState {
              code
              id
              name
            }
            hireCity {
              code
              id
              name
            }
            workState {
              code
              id
              name
            }
            workCity {
              code
              id
              name
            }
            union {
              code
              description
              isNonUnion
            }
            occupation {
              code
              description
            }
            workSchedule {
              code
              description
            }
            fringeContract {
              code
              description
            }
            department {
              code
              id
              loanOutEnabled
              masterDepartmentId
              name
              preSelected
            }
            jobDescription
            employmentClassification
            hiringStatus
            currency
            fullyOffsiteWorker
            trackOffsiteWorkers
          }
          termsOfEmployment {
            dailyRate
            guaranteedHours
            guaranteedHoursFrequency
            idleLocationSeventhDayRatePerHour
            idleLocationSixthDayRatePerHour
            locationGuaranteedHours
            locationRatePerHour
            locationRatePerWeek
            numberOfDaysPerWorkWeek
            overtimeRatePerHour
            ratePerHour
            studioGuaranteedHours
            studioRatePerHour
            studioRatePerWeek
            studioRatePerDay
            locationRatePerDay
            weeklyRate
          }
          termsOfEmploymentV2 {
            rate
            ... on TermsOfEmploymentNonUnionHourly {
              guaranteedHours
              guaranteedHours6thDay
              guaranteedHours7thDay
              guaranteedHoursFrequency
              guaranteedHoursLimit
              rate
              rateMinimumWage
              rateOvertime
            }
            ... on TermsOfEmploymentUnionHourly {
              rateDistant
              guaranteedHours
              guaranteedHoursDistant
              guaranteedHours6thDay
              guaranteedHours7thDay
              guaranteedHours6thDayDistant
              guaranteedHours7thDayDistant
              payIdleDaysDistant
              payGoldAt
              payGoldAtDistant
              payAtScale
              payAtScaleDistant
              idleAtScaleDistant
              goldAtScale
              goldAtScaleDistant
            }
            ... on TermsOfEmploymentNonUnionWeekly {
              rate
              sixthDayPayMultiplication
              seventhDayPayMultiplication
            }
            ... on TermsOfEmploymentCA {
              ratePerHour
              guaranteedHours
              dailyRate
              weeklyRate
              overtimeRatePerHour
              guaranteedHoursFrequency
            }
            ... on TermsOfEmploymentUnionSag {
              rate
              override
              reason
            }
          }
          allowances {
            boxRentalAllowance {
              amount
              cap
              duration
              per
            }
            computerRentalAllowance {
              amount
              cap
              duration
              per
            }
            carAllowance {
              amount
              cap
              duration
              per
            }
            mobilePhoneAllowance {
              amount
              cap
              duration
              per
            }
            perDiemAllowance {
              amount
              cap
              duration
              per
            }
            housingAllowance {
              amount
              cap
              duration
              per
            }
          }
          accountCodes {
            id
            accountCodeId
            lineItemKey
            value
          }
          accountCode
          customOccupationDescription
          hasPackets
          i9Document
          i9Verified
          i9CreatedDate
          documents
          rejectedAt
          version
          rejectionComments
          rescindedOrArchivedAt
          rescindedOrArchivedComments
          notes
          previousStatus
          additionalNotes {
            notes
            notesUpdatedAt
            notesUpdatedBy
          }
        }
      }
    }
  }
`;

const useReviewOffers = variables => {
  const { keyword } = variables;
  const { data, ...rest } = useQuery(QUERY, {
    variables: {
      ...variables,
      keyword: (keyword || '').length > 0 ? keyword : null,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  return {
    ...rest,
    data: data?.project || {},
  };
};

export default useReviewOffers;
